0
<template>
  <nav
      id="navbar"
      class="navbar navbar-expand navbar-light topbar mb-4 static-top shadow d-flex justify-content-between"
  >
    <div class="logo-connectus">
      <img
          src="~@/assets/images/logo_connectus.png"
          alt="'logo ConnectUs'"
      >
    </div>
    <ul class="navbar-nav ml-auto">
      <!--       link google drive-->
      <!--      <router-link class="navbar-button-links" :to="{name: 'google-drive'}">-->
      <a
          class="navbar-button-links"
          style="color:#474ce6">
        <button
            type="submit"
            class="btn btn_white_ens btn-navbar"
            @click="openLinkInNewTab('https://drive.google.com/drive/folders/11VLcHVzXmi_Frh801PHkdUWqEa60hRm0?usp=sharing')"
        >
          <img
              src="~@/assets/icons/ens-icon.svg"
              width="54"
              alt="Documentación ENS">
          <span>
            Documentación
          </span>

        </button>
      </a>

      <!--      </router-link>-->
      <router-link
          class="navbar-button-links"
          :to="{name: 'my-benefits'}">
        <button
            type="submit"
            class="btn btn_red btn-navbar"
        >
          <img
              src="~@/assets/icons/heart-icon-white.svg"
              width="25"
              alt="Beneficios">
          Mis Beneficios
        </button>
      </router-link>
      <router-link
          class="navbar-button-links"
          :to="{name: 'my-suggestions', params:{id:user.id}}">
        <button
            type="submit"
            class="btn btn_yellow btn-navbar"

        >
          <img
              src="~@/assets/icons/speaker-icon.svg"
              width="25"
              alt="Sugerencias">
          Sugerencias
        </button>
      </router-link>
      <div class="user-info">
        <!--                <div class="topbar-divider d-none d-sm-block"></div>-->
        <div
            class="pointer"
            @click="openDropdown()"
        >
          <label
              v-if="user"
              id="name-user"
          >Hola {{ user.name }}</label>
        </div>
        <div v-if="user.photo">
          <img
              class="rounded-circle img-fluid thumbnail"
              :src="baseUrl + user.photo"
              :alt="'Employee image'"
              :title="'Click para ampliar'"
          >
        </div>
        <div
            v-else
            class="media"
        >
          <img
              class="rounded-circle img-thumbnail thumbnail"
              src="~@/assets/images/user.png"
              :alt="'Default Image'"
          >
        </div>
        <div class="pointer">
          <font-awesome-icon
              :icon="open ? 'caret-right' : 'caret-down'"
              title=""
              style="margin-left: 5px; color: white"
              @click="openDropdown()"
          />
        </div>

        <div
            v-if="open"
            ref="dropdown"
            class="dropdown-content"
        >
          <ul>
            <li>
              <router-link :to="{name: 'change-password'}">
                <span>Cambiar contraseña</span>
              </router-link>
            </li>
            <li>
              <span
                  v-if="user"
                  @click="logout()"
              >Salir</span>
              <!--                            <router-link :to="{name: 'login'}">-->
              <!--                                <span>Salir</span>-->
              <!--                            </router-link>-->
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </nav>
</template>

<script setup>
import {useStore} from "vuex";
import auth from "../../api/auth/auth";
import {computed, ref} from "vue";

const open = ref(false);
const baseUrl = ref(process.env.VUE_APP_BASE_URI);
const store = useStore();

const user = computed(() => {
  return store.state.user
});

const openDropdown = () => {
  open.value = !open.value;
}

const logout = () => {
  auth.logout();
}

const openLinkInNewTab = (link) => {
  window.open(link, '_blank');
}

</script>


<style
    lang="scss"
    scoped>

#navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 5rem;
  width: 100%;
  padding: 0px 1.66rem;
  padding-left: 7.11rem;
  //border-bottom: 2px solid #6468F0;
  //background-color: #FFF;
  background-color: #6468F0;
  -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
  z-index: 100;

  .navbar-button-links {
    margin-top: auto;
    margin-bottom: auto;
  }

  .btn-navbar {
    width: 230px;
    height: 40px;
  }

  .btn_white_ens {
    background: #FFF;
    color: #474CE6;
  }

  .btn_white_ens:hover {
    background: #D3D3D3;
    color: #5946B2;
  }

  .logo-connectus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #name-user {
      color: white;
      font-weight: bolder;
    }

    .topbar-divider {
      width: 2px;
      border-right: 1px solid #e3e6f0;
      height: calc(4.375rem - 2rem);
      margin: auto 1rem;
    }

    .thumbnail {
      width: 60px;
      height: 60px;
      padding: 0.25rem;
      background-color: #fff;
      margin: 1rem;
    }

    span {
      padding-left: 0.5rem;
      font-size: 18px;
      font-weight: 400;
      color: #858796;
      vertical-align: middle;
      line-height: 1.125rem;
    }
  }

  .dropdown-content {
    width: auto;
    right: 10px;
    position: absolute;
    border-radius: 10px;
    float: none;
    top: 5.5em;
    display: block;
    left: auto;
    background-color: white;
    -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;

    ul {
      overflow: auto;
      display: block;
      height: auto;
      max-height: 12.66rem;

      li {
        width: 100%;
        font-size: 15px;
        display: block;
        padding: 0.33rem 1rem;
        border-bottom: 1px solid $light-grey-bg;
        transition: 0.6s;
        cursor: pointer;

        a {
          font-size: 15px;
        }
      }
    }
  }

}

</style>
