import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/emergency_contacts';

export default {
    // EMERGENCY CONTACT
    getEmergencyContacts(params) {
        return Api()
            .get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getEmergencyContact(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createEmergencyContact(item) {
        const saveEmergencyContact = item;
        return Api().post(RESOURCE_NAME, saveEmergencyContact)
            .catch((err) => errorHandler(err, null));
    },
    updateEmergencyContact(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteEmergencyContact(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
}
