<template>
  <div>
    <div class="extended-user-info-card">
      <el-avatar
        :src="photo"
        :size="75"
      >
        {{ userInitials }}
      </el-avatar>
      <h2 style="margin-left: 10px">
        {{ userFullName }}
      </h2>
      <h3 style="margin-left: auto">
        Total trabajado <i class="el-icon-thumb" /> {{ totalSignins }} /
        <i class="el-icon-alarm-clock" /> {{ totalWorklog }}
        <OccupationProgress :percentaje="occupationPercent" />
      </h3>
    </div>
    <div v-if="!isOnVacations && !isOnPublicHoliday">
      <el-divider>Registro de horas</el-divider>

      <el-table
        :data="info.worklog"
        border
        :cell-style="cellStyle"
      >
        <el-table-column
          label="Descripción"
          prop="description"
        />
        <el-table-column
          width="150"
          label="Cliente"
          prop="clientName"
        />
        <el-table-column
          width="150"
          label="Proyecto"
          prop="projectName"
        />
        <el-table-column
          width="75"
          label="Tiempo"
        >
          <template #default="scope">
            {{ toHours(scope.row.duration) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="isOnVacations">
      <el-divider>Vacaciones</el-divider>
      <div
        style="text-align: left"
        :style="headerStyle"
      >
        <i class="el-icon-cold-drink" /> Desde {{ formatDateAndMonth(holidaysFrom) }} hasta {{
          formatDateAndMonth(holidaysTo)

        }}<br>
      </div>
    </div>
    <div v-else-if="isOnPublicHoliday">
      <el-divider>Festivo</el-divider>
      <div
        style="text-align: left"
        :style="headerStyle"
      >
        <span v-if="publicHoliday.type === 'nacional'">
          <i class="el-icon-cold-drink" /> {{ formatDateAndMonth(publicHoliday.date) }} {{
            publicHoliday.description
          }}, Fiesta Nacional<br>
        </span>
        <span v-else>
          <i class="el-icon-cold-drink" /> {{ formatDateAndMonth(publicHoliday.dat) }} {{
            publicHoliday.description
          }} en {{ publicHoliday.workPlaceName }}<br>
        </span>
      </div>
    </div>


    <div v-if="!isOnVacations && !isOnPublicHoliday">
      <el-divider>Presencia</el-divider>
      <Presence :presence="info.presence" />
    </div>

    <div v-if="absences">
      <el-divider>Ausencia</el-divider>
      <Absence
        :absence="info.absence"
        :style="headerStyle"
      />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash'

import moment from "moment";
import {computed, ref, defineProps, onMounted} from "vue";
import OccupationProgress from './OccupationProgress';
import Presence from './Presence';
import Absence from './Absence';

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
})

const baseUri = process.env.VUE_APP_BASE_URI;
const holidaysFrom = ref('');
const holidaysTo = ref('');
const publicHoliday = ref({});


const headerStyle = computed(() => {
  if (!isWorking()) {
    if (isResting() || isRestingPublicHoliday()) {
      return {
        'border-left': '#808080' + ' solid 4px',
        'background': '#f5f4f4',
      };
    } else if (isAbsence()) {
      return {
        'border-left': 'cyan' + ' solid 4px',
        'background': 'lightcyan',
      };
    } else {
      return {};
    }
  }
  return {'border-top': props.info.worklog[0]?.color + ' solid 4px'};
})

onMounted(() => {
  if (props.info.holidays && props.info.holidays.fromDate && props.info.holidays.toDate) {
    holidaysFrom.value = props.info.holidays.fromDate;
    holidaysTo.value = props.info.holidays.toDate;
  }
  if (props.info.publicHoliday && props.info.publicHoliday.date) {
    publicHoliday.value = props.info.publicHoliday;
  }
})
// eslint-disable-next-line no-unused-vars
const project = computed(() => {
  return props.info.worklog?.[0].projectName;
})
// eslint-disable-next-line no-unused-vars
const client = computed(() => {
  return props.info.worklog?.[0].clientName;
})
// eslint-disable-next-line no-unused-vars
const timeentry = computed(() => {
  return props.info.worklog?.[0].description;
})
const photo = computed(() => {
  if (!props.info.employee.photo) {
    return null;
  }
  return baseUri.replace(/\/$/, "") + '/images/employees/' + props.info.employee.photo;
})
const userFullName = computed(() => {
  return `${props.info.employee?.name} ${props.info.employee.lastname}`;
})
const userInitials = computed(() => {
  return props.info.employee?.name.charAt(0).toUpperCase() + _.map(props.info.employee.lastname.split(' '), el => el.charAt(0).toUpperCase()).join('')
})
const totalSignins = computed(() => {
  return toHours(props.info.presence?.totalWorked ?? 0);
})
const totalWorklog = computed(() => {
  return toHours(props.info.totalWorked);
})
const occupationPercent = computed(() => {
  // return parseFloat(((props.info.totalWorked * 100) / (props.info.presence?.totalWorked ?? 0)).toFixed(2));
  const val = parseFloat(((props.info.totalWorked * 100) / (props.info.presence?.totalWorked ?? 0)).toFixed(2));
  return isNaN(val) ? 0 : ((val > 100) ? 100 : val);
})

const isOnVacations = computed(() => {
  return isResting();
})
// eslint-disable-next-line no-unused-vars
const isOnPublicHoliday = computed(() => {
  return isRestingPublicHoliday();
})
// eslint-disable-next-line no-unused-vars
const absences = computed(() => {
  return isAbsence();
})

// eslint-disable-next-line no-unused-vars
const cellStyle = ({row, column, rowIndex, columnIndex}) => {

  if (rowIndex === 0 && columnIndex === 0 && row.end === null) {
    return {'border-left': row.color + ' solid 5px'}
  }

  return {}
}
const toHours = (totalSeconds) =>
{
  const sec_num = parseInt(totalSeconds, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  // var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return hours + 'h ' + minutes + 'm';
}
const isWorking = ()=> {
  return props.info.worklog?.[0] && (props.info.worklog?.[0].start !== null) && (props.info.worklog?.[0].end === null)
}
/*const occupationText = (percent) => {
  return `Ocupación ${percent}%`;
}*/
const isResting = () => {
  return props.info?.holidays?.length !== 0;
}
const isRestingPublicHoliday = () => {
  return props.info?.publicHoliday?.length !== 0;
}
const isAbsence = () => {
  return props.info?.absence?.length !== 0;
}



const formatDateAndMonth =(value) => {
  if (value) {
    return moment(value, "DD-MM-YYYY").format('DD-MM');
  } else {
    return '';
  }
}

</script>

<style lang="scss">
.extended-user-info-card {
  display: flex;
  align-items: center;
}
</style>
