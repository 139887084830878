import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/absences';

export default {

    // ABSENCES
    getAbsences(params) {
        return Api()
            .get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getAbsence(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createAbsence(item) {
        return Api().post(RESOURCE_NAME, item)
            .catch((err) => errorHandler(err, null));
    },
    updateAbsence(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteAbsence(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
}
