<template>
  <div>
    <HeaderTitle title="Comunicación" />
    <TicketForm
      v-if="ticket"
      action="create"
      :ticket="ticket"
      @saveData="createTicket"
    />
  </div>
</template>

<script setup>


import TicketForm from "@/views/internal-tickets/InternalTicketForm.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import TicketService from "@/api/services/ticket.service";
import router from "@/app/router/router";
import notification from '@/utils/plugins/NotificationService'
import {ref} from "vue";
import {useStore} from "vuex";
import {ElNotification} from "element-plus";
const store = useStore();


 const ticket = ref({
   "phone": null,
   "equipmentTag": null,
   "issue": null,
   "description": null,
   "ticketType": null,
   "ticketPriority": null,
   "department": null,
   "project": null,
   "createdAt": new Date(),
   "comments": [],
   "ticketDocuments": [],
   "requestingEmployee": store.getters.getUserLogged['@id'],
   "requestingEmployeeEmail": store.getters.getUserLogged.email,
   "affectedEmployee": store.getters.getUserLogged['@id'],
   "affectedEmployeeEmail": store.getters.getUserLogged.email,
   "status": null,
   "isByPhone": false,
   "isOnSite": false,
   "isProjectEntity": false,
   "internal": true
 })



   const createTicket = (form) => {

      const formTicket = JSON.parse(JSON.stringify(form));
      formTicket.ticketType = form.ticketType ? typeof form.ticketType === 'object' ? form.ticketType['@id'] : form.ticketType : null;
      formTicket.ticketPriority = form.ticketPriority ? form.ticketPriority : null;
      formTicket.department = form.department ? form.department :null;
      formTicket.project = form.project ? typeof form.project === 'object' ? form.project['@id'] : form.project : null;
      formTicket.affectedEmployee = form.affectedEmployee ? form.affectedEmployee : null;
      formTicket.assignedEmployee = form.assignedEmployee ? typeof form.assignedEmployee === 'object' ? form.assignedEmployee['@id'] : form.assignedEmployee : null;
      formTicket.requestingEmployee = form.requestingEmployee &&  typeof form.requestingEmployee === 'object' ? form.requestingEmployee['@id'] : form.requestingEmployee;

      delete(formTicket.status)
      delete(formTicket.daysOpen)
      delete(formTicket.comments)
     if(formTicket.ticketDocuments.length > 0){
       formTicket.ticketDocuments = formTicket.ticketDocuments.map((doc)=>{
         return doc['@id'] ? doc['@id'] : doc
       })
     }

      TicketService.createTicket(formTicket).then((res)=>{
        if(res && (res.status === 200 || res.status === 201)){
          notification.addNotification("Datos guardados correctamente","success",10000)
          router.go(-1)
        }else{
          console.log(JSON.stringify(res, null, 2));
          ElNotification({
            title: 'Error',
            message: 'Ha ocurrido un error',
            type: 'error'
          })
        }

      }).catch((e)=>{
        console.log(JSON.stringify(e, null, 2));
        ElNotification({
          title: 'Error',
          message: 'Ha ocurrido un error',
          type: 'error'
        })
      })
    }

</script>

<style scoped>

</style>
