import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';
const RESOURCE = '/api/extranet_users';
const RESOURCE_ROLES = '/api/extranet_roles';
export default {
    async getUsers(params){
        return await Api()
            .get(RESOURCE,{params})
            .catch((err) => errorHandler(err, null));
    },
    async getClientUsers(params, id){
        return await Api()
            .get(`/api/clients/${id}/extranet_users`,{params})
            .catch((err) => errorHandler(err, null));
    },
    async getExtranetRolesUsers(){
        return await Api()
            .get(RESOURCE_ROLES,)
            .catch((err) => errorHandler(err, null));
    },
}
