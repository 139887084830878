<template>
  <div>
    <HeaderTitle :title="getTitle()" />

    <div class="content">
      <el-row class="d-flex align-items-center justify-content-start flex-wrap">
        <div class="d-flex align-items-center">
          <svg
            id="chronometer"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 233.766 233.766"
            style="enable-background: new 0 0 233.766 233.766"
            xml:space="preserve"
          >
            <g>
              <path
                d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                            c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                            l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                            C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                            c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                            c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                            s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                            c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                            M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                            S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                            c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
              />
              <path
                d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                            C124.383,80.339,121.025,76.981,116.883,76.981z"
              />
            </g>
          </svg>
          <div class="text-title">
            <b>Presencia</b>
          </div>
        </div>
        <div class="d-flex justify-content-end ml-auto mt-3">
          <button
            type="primary"
            :class="$store.state.sign_button ? 'btn_yellow' : 'btn_red'"
            class="btn btn-block m-2 btn-action"
            @click="addSignUp()"
          >
            {{ store.state.sign_button ? "EMPEZAR" : "TERMINAR" }}
          </button>
        </div>
      </el-row>

      <el-row
        v-for="(sign, index) in store.state.currentSign"
        :key="index"
      >
        <div class="card card-notice w-100 pt-2 px-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="col d-flex align-items-center">
              <h1>
                <font-awesome-icon
                  class="iconNav"
                  icon="sign-in-alt"
                />
              </h1>
              <h6 class="mx-3">
                <b>ENTRADA</b>
              </h6>
              <h1>{{ formatTimeWithH(sign.in) }}</h1>
            </div>
            <div class="col d-flex align-items-center">
              <h1>
                <font-awesome-icon
                  class="iconNav"
                  icon="door-open"
                />
              </h1>
              <h6 class="mx-3">
                <b>SALIDA</b>
              </h6>
              <h1>{{ formatTimeWithH(sign.out) }}</h1>
            </div>
            <div class="col d-flex align-items-center">
              <svg
                id="chronometer1"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 233.766 233.766"
                style="enable-background: new 0 0 233.766 233.766"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                                c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                                l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                                C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                                c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                                c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                                s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                                c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                                M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                                S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                                c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
                  />
                  <path
                    d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                                C124.383,80.339,121.025,76.981,116.883,76.981z"
                  />
                </g>
              </svg>
              <h6 class="mx-3">
                <b>TOTAL</b>
              </h6>
              <h1>
                {{
                  formatTimeStyle(calculateTotal(sign.in, sign.out))
                }}
              </h1>
            </div>
          </div>
        </div>
      </el-row>

      <el-row>
        <div class="card card-notice1 w-100 pt-2 px-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-4">
              <svg
                id="chronometer2"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 233.766 233.766"
                style="enable-background: new 0 0 233.766 233.766"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                      c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                      l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                      C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                      c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                      c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                      s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                      c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                      M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                      S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                      c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
                  />
                  <path
                    d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                      C124.383,80.339,121.025,76.981,116.883,76.981z"
                  />
                </g>
              </svg>
              <h6 class="mx-2">
                <b>TRABAJADO</b>
              </h6>
              <h1>{{ secondsToTime(sumTimeTotal) }}</h1>
            </div>
            <div class="d-flex align-items-center mr-4">
              <svg
                id="chronometer2"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 233.766 233.766"
                style="enable-background: new 0 0 233.766 233.766"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                      c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                      l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                      C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                      c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                      c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                      s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                      c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                      M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                      S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                      c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
                  />
                  <path
                    d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                      C124.383,80.339,121.025,76.981,116.883,76.981z"
                  />
                </g>
              </svg>
              <h6 class="mx-2">
                <b>ESTIMADO</b>
              </h6>
              <h1>{{ secondsToTime(sumEstimatedTime) }}</h1>
            </div>
            <div class="d-flex align-items-center mr-4 dif">
              <svg
                id="chronometer-dif"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 233.766 233.766"
                style="enable-background: new 0 0 233.766 233.766"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                      c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                      l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                      C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                      c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                      c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                      s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                      c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                      M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                      S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                      c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
                  />
                  <path
                    d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                      C124.383,80.339,121.025,76.981,116.883,76.981z"
                  />
                </g>
              </svg>
              <h6 class="mx-2">
                <b>DIFERENCIA</b>
              </h6>
              <h1>{{ secondsToTime(diffTime) }}</h1>
            </div>
            <div class="d-flex align-items-center ml-auto">
              <div>
                <svg
                  id="calendar-icon"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="124.813px"
                  height="124.813px"
                  viewBox="0 0 124.813 124.813"
                  style="enable-background: new 0 0 124.813 124.813"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                              L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                              l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                              l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                              c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                      />
                      <path
                        d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                              C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                              v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                              C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                      />
                    </g>
                  </g>
                </svg>
              </div>

              <div class="mx-2 text-mute text-calendar">
                {{ currentDay }}
              </div>
            </div>
          </div>
        </div>
      </el-row>

      <el-row class="mt-5 d-flex align-items-center justify-content-start flex-wrap">
        <div class="d-flex align-items-center mr-2">
          <svg
            id="chronometer"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 233.766 233.766"
            style="enable-background: new 0 0 233.766 233.766"
            xml:space="preserve"
          >
            <g>
              <path
                d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                            c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                            l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                            C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                            c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                            c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                            s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                            c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                            M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                            S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                            c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
              />
              <path
                d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                            C124.383,80.339,121.025,76.981,116.883,76.981z"
              />
            </g>
          </svg>
          <div class="text-title">
            <b>Fichajes últimos días</b>
          </div>
        </div>
        <div
          id="date-filter"
          class="d-flex justify-content-end ml-auto mt-1"
        >
          <!-- <shared-datepicker @blur="onchangeDateRange"></shared-datepicker> -->
          <el-date-picker
            v-model="daterange"
            type="daterange"
            start-placeholder="Fecha Inicio"
            end-placeholder="Fecha Fin"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
            :picker-options="pickerOptions"
            @change="onchangeDateRange()"
          />
        </div>
      </el-row>
      <el-row>
        <div
          id="table-presence"
          class="table table-user mt-0 pt-0"
        >
          <table>
            <thead class="card-head">
              <tr>
                <th>
                  <svg
                    id="calendar-icon-table"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="124.813px"
                    height="124.813px"
                    viewBox="0 0 124.813 124.813"
                    style="enable-background: new 0 0 124.813 124.813"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                                L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                                l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                                l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                                c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                        />
                        <path
                          d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                                C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                                v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                                C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                        />
                      </g>
                    </g>
                  </svg>
                  DIA
                </th>
                <th>
                  <svg
                    id="office-icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 482.012 482.012"
                    style="enable-background: new 0 0 482.012 482.012"
                    xml:space="preserve"
                  >
                    <g>
                      <rect
                        x="137.357"
                        y="235"
                        width="40"
                        height="40"
                      />
                      <rect
                        x="137.357"
                        y="75"
                        width="40"
                        height="40"
                      />
                      <rect
                        x="137.357"
                        y="155"
                        width="40"
                        height="40"
                      />
                      <rect
                        x="217.357"
                        y="75"
                        width="40"
                        height="40"
                      />
                      <rect
                        x="217.357"
                        y="155"
                        width="40"
                        height="40"
                      />
                      <path
                        d="M317.357,282.734c-19.299,0-35,15.701-35,35s15.701,35,35,35s35-15.701,35-35S336.656,282.734,317.357,282.734z"
                      />
                      <path
                        d="M332.357,216.539V0h-270v445h225.561l29.439,37.012l80.267-100.916c14.414-18.226,22.032-40.137,22.032-63.363
                                    C419.656,266.422,381.679,223.817,332.357,216.539z M92.357,415V30h210v186.539c-49.321,7.279-87.298,49.883-87.298,101.195
                                    c0,23.227,7.619,45.138,22.058,63.396L264.057,415H92.357z M374.118,362.455l-56.762,71.364l-56.734-71.331
                                    c-10.182-12.874-15.563-28.349-15.563-44.753c0-39.865,32.433-72.298,72.298-72.298s72.299,32.433,72.299,72.298
                                    C389.656,334.137,384.274,349.613,374.118,362.455z"
                      />
                    </g>
                  </svg>
                  OFICINA
                </th>
                <th>
                  <svg
                    id="lamp-icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 315 315"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    enable-background="new 0 0 315 315"
                  >
                    <path
                      d="m285.61,145.248l-61.643-133.682c-3.041-6.593-10.781-11.566-18.005-11.566h-96.924c-7.224,0-14.964,4.973-18.005,11.566l-61.643,133.682c-1.932,4.188-1.771,8.57 0.438,12.022 2.199,3.437 6.082,5.407 10.651,5.407h110.021v83.243c-34.008,3-62.089,27.654-68.104,60.83-0.37,2.042 0.185,4.143 1.515,5.735 1.33,1.593 3.298,2.514 5.373,2.514h136.432c2.075,0 4.043-0.921 5.373-2.514 1.33-1.593 1.885-3.693 1.515-5.735-6.016-33.175-34.097-57.829-68.104-60.83v-83.243h46.644v27.861c-8.034,2.903-13.807,10.624-13.807,19.697 0,11.549 9.334,20.944 20.807,20.944 11.474,0 20.808-9.395 20.808-20.944 0-9.073-5.773-16.795-13.808-19.697v-27.861h49.378c4.569,0 8.452-1.971 10.651-5.407 2.209-3.452 2.369-7.834 0.437-12.022zm-68.904,155.752h-118.412c8.212-24.42 31.867-41.389 59.205-41.389s50.994,16.969 59.207,41.389zm1.438-83.819c-3.753,0-6.807-3.115-6.807-6.944 0-3.828 3.054-6.942 6.807-6.942 3.754,0 6.808,3.114 6.808,6.942-0.001,3.828-3.054,6.944-6.808,6.944zm-174.919-68.503l60.521-131.248c0.754-1.635 3.523-3.43 5.292-3.43h96.924c1.769,0 4.538,1.795 5.292,3.43l60.521,131.248h-228.55z"
                    />
                  </svg>
                  TELETRABAJO
                </th>
                <th>
                  <svg
                    id="chronometer3"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 233.766 233.766"
                    style="enable-background: new 0 0 233.766 233.766"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                      c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                      l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                      C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                      c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                      c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                      s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                      c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                      M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                      S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                      c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z"
                      />
                      <path
                        d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                      C124.383,80.339,121.025,76.981,116.883,76.981z"
                      />
                    </g>
                  </svg>
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sign, index) in signings.slice().reverse()"
                :key="index + sign"
              >
                <td>
                  <svg
                    id="calendar-icon-table-cell"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="124.813px"
                    height="124.813px"
                    viewBox="0 0 124.813 124.813"
                    style="enable-background: new 0 0 124.813 124.813"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                                L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                                l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                                l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                                c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                        />
                        <path
                          d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                                C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                                v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                                C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                        />
                      </g>
                    </g>
                  </svg>
                  {{ sign.date }}
                </td>
                <td>
                  <div
                    v-for="(s, i) in sign.signs.filter((v) => !v.remote)"
                    :key="i+s.in"
                    class="row"
                  >
                    <div class="col-md-3" />
                    <div class="col-md-1">
                      <font-awesome-icon
                        v-if="s.in"
                        class="iconNav"
                        icon="sign-in-alt"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>{{ formatTimeWithOutStyle(s.in) }}</span>
                    </div>

                    <div class="col-md-1">
                      <font-awesome-icon
                        v-if="s.out"
                        class="iconNav"
                        icon="door-open"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>{{ formatTimeWithOutStyle(s.out) }}</span>
                    </div>
                    <div class="col-md-3" />
                  </div>
                </td>
                <td style="text-align: center">
                  <div
                    v-for="(s, i) in sign.signs.filter((v) => v.remote)"
                    :key="i+s.in"
                    class="row"
                  >
                    <div class="col-md-3" />
                    <div class="col-md-1">
                      <font-awesome-icon
                        v-if="s.in"
                        class="iconNav"
                        icon="sign-in-alt"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>{{ formatTimeWithOutStyle(s.in) }}</span>
                    </div>

                    <div class="col-md-1">
                      <font-awesome-icon
                        v-if="s.out"
                        class="iconNav"
                        icon="door-open"
                      />
                    </div>
                    <div class="col-md-2">
                      <span>{{ formatTimeWithOutStyle(s.out) }}</span>
                    </div>
                    <div class="col-md-3" />
                  </div>
                </td>
                <td style="text-align: center">
                  <span v-if="sign && sign.total">{{ secondsToTime(sign.total) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-row>
    </div>
    <shared-modal
      v-if="showmodal"
      :title="'Fichaje desde Presencia'"
      :theme="'secondary'"
      :size="'small'"
      @close="closeSignModal()"
    >
      <template #content>
        <br>
        <div>
          <p>
            <font-awesome-icon icon="exclamation-triangle" />
            Confirma que estas fuera de la oficina
          </p>

          <div class="row item-modal">
            <div class="col-sm-6">
              <button
                class="btn btn-block btn_red"
                type="button"
                @click="closeSignModal()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-6">
              <button
                class="btn btn_blue"
                type="button"
                @click="addSignUp()"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </template>
    </shared-modal>
  </div>
</template>

<script setup>
//Components
import HeaderTitle from "@/components/layout/HeaderTitle";
import SharedModal from "@/components/shared/SharedModal";
import moment from "moment";
import PresenceService from "@/api/services/presencia.service";
import CommonService from "@/api/services/common.service";
import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
const store = useStore();
const route = useRoute();
const props = defineProps({
  email: {
    type: String,
    required: true,
  },
})

const pickerOptions = ref({
  firstDayOfWeek: 1
})
const employeeName = ref(null)
// eslint-disable-next-line no-unused-vars
const sign_button = ref(true)
const estimatedTime = ref(null)
// eslint-disable-next-line no-unused-vars
const totalWorked = ref(null)
const daterange = ref([])
const now = ref(moment(new Date()))
const currentDay = ref('')
const currentSign = ref([])
// eslint-disable-next-line no-unused-vars
const totalTimePrecence = ref({
  hours: 2,
  min: 55
})
// eslint-disable-next-line no-unused-vars
const totalTimeVacaciones = ref(8)
// eslint-disable-next-line no-unused-vars
const newMails = ref(4)
// eslint-disable-next-line no-unused-vars
const projectsActive = ref(3)
const showmodal = ref(false)
const signings = ref([])


// eslint-disable-next-line no-unused-vars
const getUserLogged = computed(() => {
  return store.getters.getUserLogged;
})
// eslint-disable-next-line no-unused-vars
const TotalTime= computed(() => {
  return moment.duration(
      now.value.diff(moment(store.state.currentSign[0].in, "HHmmss"))
  );
})
const sumTimeTotal = computed(() => {
  if (signings.value.length === 0) {
    // totalWorked = null;
    return;
  }
  // totalWorked = 0;
  let total = 0;
  signings.value.forEach((val) => {
    if (val.total) {
      total += val.total;
    }else{
      total = moment.duration(now.value.diff(moment(val.in,"HHmmss"))).asMilliseconds();
    }

    // console.log("Trtt", totalWorked);
  });

  //store.commit('setHoursWorked',totalWorked);
  //return totalWorked;
  return total;
})
const diffTime = computed(() => {
  if (!sumTimeTotal.value || !sumEstimatedTime.value) return "";
  const worker = moment(sumTimeTotal.value);
  return moment.duration(worker.diff(moment(sumEstimatedTime.value)));
})
const sumEstimatedTime = computed(() => {
  if (signings.value.length === 0) {
    return;
  }
  let estimated = 0;
  let sum = 0;
  signings.value.forEach((val) => {
    estimated = getTimeToWorkMlSeconds(val.date);
    sum += estimated;
  });
  // console.log("sumEstimatedTime", secondsToTime(sum));
  return sum;
})
const getReportPresence= computed(() => {
  return store.getters['getReportPresence'];
})
const  getDateRangeReport = computed(() => {
  return store.getters['getDateRangeReport'];
})




const getTitle = () => {
  if(employeeName.value === null) return 'Presencia';
  else return 'Presencia '+employeeName.value;
}
const calculateTotal = (enter, out)  => {
  // console.log(enter,out ? out : '| sin fichaje salida');
  if (!out)
    return moment.duration(
        now.value.diff(moment(enter, "HHmmss"))
    );
  return moment.duration(
      moment(out, "HHmmss").diff(moment(enter, "HHmmss"))
  );
}
// eslint-disable-next-line no-unused-vars
const getServerTime = async()  => {
  return await CommonService.getServerTimestamp().then((response) => {
    now.value  = moment(response.data.serverTimestamp, "YYYY-MM-DDTHH:mm:ss");

  });
}
// eslint-disable-next-line no-unused-vars
const getTimeToWork = (date)  => {
  const d = moment(date);
  let estimatedTime = "08:30";
  if (d.isBetween(d.format("YYYY") + "-07-01", d.format("YYYY") + "-09-30"))
    estimatedTime = "07:00";
  else {
    if (moment().weekday(4).isSame(d)) {
      estimatedTime = "07:00";
    }
  }
  return estimatedTime;
}
const getTimeToWorkMlSeconds = (date)  => {
  const d = moment(date, "DD MM YYYY");
  let estimatedTime = 30600000;
  if (moment().weekday(5).day() === d.day()) {
    estimatedTime = 25200000;
    // console.log(d.format("DD-MM") + " " + d.format('dddd') + " " + d.day().toLocaleString() + " es viernes in getTimeToWorkMlSeconds " +  secondsToTime(estimatedTime));
  } else if (d.isBetween(d.format("YYYY") + "-07-01", d.format("YYYY") + "-09-30")) {
    estimatedTime = 25200000;
    // console.log(d.format("DD-MM") + " " + d.format('dddd') + " " + d.day().toLocaleString() + " verano in getTimeToWorkMlSeconds: " +  secondsToTime(estimatedTime));
  }
  // else {
  //   console.log(d.format("DD-MM") + " " + d.format('dddd') + " " + d.day().toLocaleString() + " estimatedTime in getTimeToWorkMlSeconds: " + secondsToTime(estimatedTime));
  // }
  return estimatedTime;
}
const getWorkTime = () => {
  //const user = getUserLogged;
  PresenceService.getWorkTime({ "employees.email": props.email }).then(
      (response) => {
        if(response.data["hydra:member"] && response.data["hydra:member"].length>0){
          estimatedTime.value  = response.data["hydra:member"][0].workHours;
          let now_current = now.value;
          if (
              now_current.isBetween(
                  now_current.format("YYYY") + "-07-01",
                  now_current.format("YYYY") + "-09-30"
              )
          )
            estimatedTime.value  = "07:00";
          else {
            if (moment().weekday(5).day() === now_current.day()) {
              estimatedTime.value  = "07:00";
            }
          }
        }

      }
  );
}


const getCurrentPresence = () => {

  //const user = getUserLogged;
  let from_date = moment(now);
  let to_date = moment(now);
  let email = props.email;

  PresenceService.getSignings({
    email: email,
    from: from_date.format("YYYY-MM-DD"),
    to: to_date.format("YYYY-MM-DD"),
  }).then(response=>{

    if(response.data.length===0) return;
    const element = response.data && response.data.days.length>0 ? response.data.days[0] : [];
    store.dispatch("currentPresence", element.signings);
  })

}
const getPresenceData = (isfilter = false) => {
  //const user = getUserLogged;
  let from_date = "";
  let to_date = "";
  let email = props.email;
  const day_week = moment(now).day();
  // console.log("Dia de la semana", day_week);
  if (!isfilter) {
    if (day_week <= 1) {
      from_date = moment().weekday(-6);
      to_date = moment().weekday(-2);
      //console.log(from_date,to_date);
    } else {
      from_date = moment().weekday(1);
      // to_date = moment().weekday(5);
      to_date = moment().weekday(day_week);
    }
  } else {
    from_date = moment(daterange.value[0]);
    to_date = moment(daterange.value[1]);
  }
  // console.log("FECHAS: ", from_date.format("YYYY-MM-DD"), to_date.format("YYYY-MM-DD"));

  signings.value  = [];
  currentSign.value  = [];


  if(route.params && route.params.id){
    const report = getReportPresence.value.filter(v=>v.id===route.params.id)[0];

    from_date = moment(daterange.value[0])
    to_date = moment(daterange.value[1]);
    //email = report.email;
    employeeName.value  = report.name;
  }else employeeName.value  = null;
  currentDay.value = from_date.format("DD/MM/YYYY")+' - '+to_date.format("DD/MM/YYYY");
  PresenceService.getSignings({
    email: email,
    from: from_date.format("YYYY-MM-DD"),
    to: to_date.format("YYYY-MM-DD"),
  }).then((response) => {

    if(response.data && response.data.days.length>0){
      response.data.days.forEach((element) => {
        let object = {};
        let flag = true;
        let signs = [];
        let tTime = 0;
        //console.log("el",element);
        element.signings.forEach((v) => {
          //console.log("V",v);
          if (flag) {
            object.remote = v.remote;

            object.in = v.time;
            signs.push(object);
          } else {
            object.remote = v.remote;
            object.out = v.time;

            const inn = moment(object.in, "HHmmss");
            const out = moment(object.out, "HHmmss");

            object.total = moment.duration(out.diff(inn));
            //console.log("Obj",object);
            if (tTime === 0)
              tTime = object.total.asMilliseconds();
            else
              tTime+=object.total.asMilliseconds();


            signs[signs.length - 1] = object;

            object = {};
          }
          //console.log("Time",tTime);
          flag = !flag;
        });

        signings.value.push({
          date: moment(element.date, "YYYYMMDD").format("DD/MM/YYYY"),
          signs,
          total: tTime===0 ? moment.duration(now.value.diff(moment(signs[0].in,"HHmmss"))).asMilliseconds() : tTime,
        });
        tTime = 0;
      });
    }else{
      store.commit('setSignButton',true);
    }


  });
}
onMounted(async () => {

  await getServerTime();
  setInterval(async function () {
    await getServerTime();
    //console.log($now);
  }, 5000);
  if (route.params && route.params.id) {

    daterange.value = getDateRangeReport.value;

  } else {
    store.commit('resetReportPresence');
    daterange.value  = [];
    employeeName.value  = null;
  }

  getWorkTime();
  getPresenceData();
  getCurrentPresence();

})
const  addSignUp = async() => {

  //closeSignModal();
  //const user = getUserLogged;
  store.commit('setStatusSignButton');
  store.dispatch('addSigning').then(async ()=>{
    console.log("Se ha marcado entrada/salida");
    await getServerTime();
    getCurrentPresence();
    getPresenceData();

  });
}
const onchangeDateRange = () => {
  if (!daterange.value) getPresenceData(false);
  else getPresenceData(true);
}
// eslint-disable-next-line no-unused-vars
const showSignModal = ()=> {
  showmodal.value = !showmodal.value;
}

const closeSignModal = () => {
  showmodal.value = false;
}
// eslint-disable-next-line no-unused-vars
const durationFormat = (d) => {
  if (!d) return "h:m";

  return moment(d.asMilliseconds()).format("HH:mm");
}
// eslint-disable-next-line no-unused-vars
const secondsToTime = (s) => {
  function addZ(n) {
    if(n<0){
      return (Math.abs(n) < 10 ? "-0" : "") + Math.abs(n);
    }

    return (n < 10 ? "0" : "") + n;
  }

  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;
  if(isNaN(hrs) || isNaN(mins))
    return "0h:0m";
  return addZ(hrs) + "h:" + addZ(mins) + "m";
}
// eslint-disable-next-line no-unused-vars
const formatDate = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatDateTime = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatTime = (value) => {
  if (value) {
    return moment(value).format("HH:mm") + "h";
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatTimeStyle = (value) => {
  if (value) {
    let hours =
        value.hours().toString().length === 1
            ? "0" + value.hours().toString()
            : value.hours();
    let minutes =
        value.minutes().toString().length === 1
            ? "0" + value.minutes().toString()
            : value.minutes();

    return hours + "h:" + minutes + "m";
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatTimeStyleTotals = (value) => {
  if (value) {
    let hours =
        value.hours().toString().length === 1
            ? "0" + value.hours().toString()
            : value.hours();
    let minutes =
        value.minutes().toString().length === 1
            ? "0" + value.minutes().toString()
            : value.minutes();

    return hours + ":" + minutes + "h";
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatTimeWithOutStyle = (value) => {
  if (value) {
    return moment(value, "HHmmss").format("HH:mm");
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const formatTimeWithH = (value) => {
  if (value) {
    return moment(value, "HHmmss").format("HH:mm") + "h";
  }
  return "";
}
// eslint-disable-next-line no-unused-vars
const welcome = (value)  => {
  console.log(value);
  let currentHour = moment(value).format("HH");
  let textWelcome = "";
  if (currentHour >= 6 && currentHour < 12) {
    console.log("Buenos dias", currentHour);
    textWelcome = "Buenos días";
  } else if (currentHour >= 12 && currentHour < 20) {
    console.log("Buenos tardes", currentHour);
    textWelcome = "Buenas tardes";
  } else if (currentHour >= 20 || currentHour < 6) {
    console.log("Buenas noches", currentHour);
    textWelcome = "Buenas noches";
  }
  return textWelcome;
}

</script>

<style scoped lang="scss">
.content {
  .table-fichaje {
    th {
      text-align: left;
      vertical-align: center;
      padding-left: 5px;
      color: #474ce6;
      background-color: white;
    }
    td {
      margin-top: 10px;
      border-bottom: 1px solid #ddd;
    }

    .card-head {
      border: 1px solid rgba(0, 0, 0, 0.125);
      height: 3.8em;
      border-radius: 1.8em;

      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .card-notice {
    border-radius: 0.8em;
    color: white;
    background-color: #474ce6;
  }
  .card-notice1 {
    border-radius: 0.8em;
    color: #474ce6;
  }
  .dif {
    color: #f8309a;
  }
  .text-title {
    margin-left: 5px;
    margin-top: 5px;
    width: 100%;
    height: auto;
    color: #474ce6;
    font-size: 2.5em;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-size-adjust: 50%;
  }
  .text-calendar {
    color: rgb(172, 165, 165);
    font-weight: bold;
  }
  #lamp-icon {
    fill: $bg-blue;
    width: 30px;
  }
  #office-icon {
    fill: $bg-blue;
    width: 20px;
  }
  #calendar-icon-table-cell {
    margin-right: 5px;
    fill: black;
    width: 18px;
    height: 18px;
    padding-bottom: 3px;
  }
  #calendar-icon-table {
    fill: $bg-blue;
    width: 20px;
    height: 20px;
  }
  #calendar-icon {
    fill: rgb(172, 165, 165);
    width: 1em;
    height: 1.5em;
    padding-bottom: 3px;
  }
  #chronometer3 {
    fill: $bg-blue;
    width: 35px;
  }
  #chronometer {
    fill: $bg-blue;
    width: 35px;
    //margin-top: 10px;
  }
  #chronometer2 {
    fill: $bg-blue;
    width: 2.9em;
    padding-bottom: 8px;
  }
  #chronometer-dif {
    fill: #f8309a;
    width: 2.9em;
    padding-bottom: 8px;
  }
  #chronometer1 {
    fill: white;
    width: 2.9em;
    padding-bottom: 8px;
  }
  .btn-action {
    width: 20em;
    height: 3em;
    font-weight: bold;
  }
}
.title-card {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  top: 14em;
}
#Presence {
  padding: 0em;
  padding-right: 5em;
  transition: 0.6s;

  .title-button {
    display: flex;
    align-items: center;

    h2 {
      color: #5a5c69;
      font-weight: 400;
      line-height: 1.2;
      font-size: 28px;
      margin: 0;
      margin-right: 30px;
    }

    button {
      color: #fff;
      border: 0;
      font-weight: 400;
      text-align: center;
      padding: 8px;
      font-size: 18px;
      width: 130px;
      border-radius: 8px;
    }

    .btn-on {
      background-color: red;
    }

    .btn-off {
      background-color: green;
    }
  }

  .total-cards {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card1 {
      width: 70%;

      .container-ficha {
        border-radius: 10px;
        margin-top: 35px;
        width: 100%;
        -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;

        .title-ficha {
          padding: 0.75rem 1.25rem;
          margin-bottom: 0;
          background-color: #f8f9fc;
          border-bottom: 1px solid #e3e6f0;

          h4 {
            color: #4e73df;
            font-weight: 700;
            margin-bottom: 0;
          }
        }

        .table-ficha {
          padding: 30px;
          background-color: white;

          table {
            border: 1px solid #e3e6f0;
            width: 100%;
            color: #858796;

            th {
              border: 1px solid #e3e6f0;
              padding: 12px;
              padding-left: 20px;
              font-size: 20px;
            }

            td {
              border: 1px solid #e3e6f0;
              padding: 12px;
              padding-left: 20px;
              background-color: white;
              font-size: 18px;
            }

            tr {
              &:nth-child(even) {
                td {
                  background-color: #ececec;
                }
              }
            }
          }
        }

        .table-all {
          table {
            th {
              text-align: center;
            }

            td {
              padding: 8px;
              text-align: center;
            }
          }
        }
      }
    }

    .card2 {
      width: 28%;

      .container-contador {
        -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        background-color: white;
        margin-top: 35px;
        padding: 20px;
        border-radius: 10px;

        .iconNav {
          color: #dddfeb;
          font-size: 30px;
        }
      }

      .contador-blue {
        border-left: 5px solid #4e73df;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          color: #4e73df;
          font-size: 16px;
        }

        div {
          font-size: 24px;
          font-weight: bold;
        }
      }

      .contador-green {
        border-left: 5px solid #1cc88a;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          color: #1cc88a;
          font-size: 16px;
        }

        div {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .footer-undanet {
    padding: 2rem 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
