import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


export default {
    signUp(params) {
        return Api()
            .post('/api/presence/signup', params)
            .catch((err) => errorHandler(err, null));
    },
    getSignings(params) {
        return Api()
            .get('/api/presence/signing', {params})
            .catch((err) => errorHandler(err, null));
    },
    getWorkTime(params){
        return Api()
        .get('/api/schedules', {params})
        .catch((err) => errorHandler(err, null));
    },
    getSigningsWithEmployee(params){
        return Api()
        .get('/api/presence/report', {params})
        .catch((err) => errorHandler(err, null));
    }

}
