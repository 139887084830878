import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


const RESOURCE_NAME = '/api/suggestion_statuses';
const RESOURCE_NAME_SUGGESTIONS = '/api/suggestions';
const RESOURCE_EMPLOYEE = '/api/employees/';
const RESOURCE_SUGGESTION_AS_AUTHOR = '/suggestions_as_authors';


export default {
    async get(resource){
        return await Api()
        .get(resource)
        .catch((err) => errorHandler(err, null));
    },
    async getStatus(params){
        return await Api()
        .get(RESOURCE_NAME,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getSuggestions(params){
        return await Api()
        .get(RESOURCE_NAME_SUGGESTIONS,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getSuggestionsAsAuthor(id, params) {
        return await Api()
            .get(RESOURCE_EMPLOYEE+id+RESOURCE_SUGGESTION_AS_AUTHOR, {params})
            .catch((err) => errorHandler(err, null));
    },
    async createSuggestions(suggestion){
        return await Api()
        .post(RESOURCE_NAME_SUGGESTIONS,suggestion)
        .catch((err) => errorHandler(err, null));
    },
    async updateSuggestions(id,suggestion){
        return await Api()
        .put(RESOURCE_NAME_SUGGESTIONS+'/'+id,suggestion)
        .catch((err) => errorHandler(err, null));
    },
    async getSuggestion(id){
        return await Api()
        .get(RESOURCE_NAME_SUGGESTIONS+'/'+id)
        .catch((err) => errorHandler(err, null));
    },
    async delete(id){
        return await Api()
            .delete(RESOURCE_NAME_SUGGESTIONS+'/'+id)
            .catch((err) => errorHandler(err, null));
    }
}
