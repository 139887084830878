<template>
  <div>
    <el-row>
      <el-col :span="20">
        <h2>Empleados con ausencias</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="absences"
            border
        >
          <el-table-column>
            <template #default="scope">
              <el-avatar :src="photo(scope.row)">
                {{ employeeInitials(scope.row) }}
              </el-avatar>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="Id" prop="id"></el-table-column>-->
          <el-table-column
              label="Nombre"
              prop="name"
          />
          <el-table-column
              label="Apellidos"
              prop="lastname"
          />
          <el-table-column
              label="Observaciones"
              prop="observations"
          />
          <el-table-column
              label="Desde"
              prop="fromDate"
          />
          <el-table-column
              label="Hasta"
              prop="toDate"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import _ from 'lodash';
import {employeeInitials} from "@/views/production/mixin/common";
import {computed} from "vue";
import {defineProps, onMounted} from 'vue';


const props = defineProps({
  absences: {
    type: Array,
    default: () => []
  }
});

// eslint-disable-next-line no-unused-vars
const computedAbsences = computed(() => {
  return _.orderBy(_.toArray(props.absences), ['lastname']);
})

onMounted(() => {
  fillData();
})

const   fillData = () => {
  return _.orderBy(_.toArray(props.absences), ['lastname']);
}



</script>

<style scoped>

</style>