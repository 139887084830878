import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_TRACKER = '/api/time_entries';



export default {
    async getEmployeeEntries(id){
        return await Api()
        .get(`/api/employees/${id}/time_entries`)
        .catch((err) => errorHandler(err, null));
    },
    async getEntries(params){
        return await Api()
        .get(RESOURCE_TRACKER,{params})
        .catch((err) => errorHandler(err, null));
    },
    async createEntry(params) {
        return Api()
        .post(RESOURCE_TRACKER, params)
        .catch((err) => errorHandler(err, null));
    },
    async updateEntry(id, params) {
        return Api()
        .put(`${RESOURCE_TRACKER}/${id}`, params)
        .catch((err) => errorHandler(err, null));
    },
    async startEntry(entry) {
        return Api()
            .post(`${RESOURCE_TRACKER}`, entry)
            .catch((err) => errorHandler(err, null));
    },
    async stopEntry(entry) {
        return Api()
            .put(`${RESOURCE_TRACKER}/${entry.id}`, entry)
            .catch((err) => errorHandler(err, null));
    },
    async discardEntry(id) {
        return Api()
        .delete(`${RESOURCE_TRACKER}/${id}`)
        .catch((err) => errorHandler(err, null));
    },
    async splitEntry(id) {
        return Api()
            .delete(`${RESOURCE_TRACKER}/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    async duplicateEntry(id) {
        return Api()
            .put(`${RESOURCE_TRACKER}/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    async getCurrentEntry(id) {
        return Api()
            // .get(`/api/employees/${id}/time_entries`)
            .get(`${RESOURCE_TRACKER}/${id}`)
            .catch((err) => errorHandler(err, null));
    },

}


