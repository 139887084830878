

// Style files
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/sass/styles.scss'
import 'v-calendar/style.css';
import 'vue-advanced-cropper/dist/style.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faUserSecret)
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { createApp } from "vue";
import App from "./App.vue";
import router from '@/app/router/router'
import store from '@/app/store/store'
import ElementPlus from 'element-plus'
import VCalendar from 'v-calendar';
import es from 'element-plus/dist/locale/es.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mitt from 'mitt';
import dayjs from 'dayjs'
// import {utc} from "moment";
const emitter = mitt()

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.provide('emitter', emitter)
appInstance.use(VCalendar, {});

dayjs.locale('es')
// set first day of week to Monday
// dayjs.Ls.en.weekStart = 1
dayjs.Ls.es ??= {};
dayjs.Ls.es.weekStart = 1
// dayjs.weekStart = 1
let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)


appInstance.use(ElementPlus, {
  locale: es,

});
appInstance.component('font-awesome-icon', FontAwesomeIcon)
appInstance.component('font-awesome-layers', FontAwesomeLayers)
appInstance.component('font-awesome-layer-texts', FontAwesomeLayersText)
appInstance.component('QuillEditor', QuillEditor)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  appInstance.component(key, component)
}

appInstance.mount("#app");
