<template>
  <div>
    <el-row
      v-if="holidays.holidaysOn7Days.length>0"
      class="px-4"
    >
      <el-col
        :span="20"
        class="d-flex "
      >
        <svg
          id="islands"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 372 372"
        >
          <path
            d="M186 0C83.4 0 0 83.4 0 186s83.4 186 186 186 186-83.4 186-186S288.6 0 186 0zM186 206.6c36.4 0 66.7 27.1 71.5 62.2H114.5C119.3 233.7 149.6 206.6 186 206.6zM266.3 233.4c-2.1-3.6-4.4-7.1-6.9-10.4h88.4c-0.8 3.4-1.7 6.8-2.7 10.2h-76.9C267.6 233.3 266.9 233.3 266.3 233.4zM186 20c91.5 0 166 74.5 166 166 0 5.8-0.3 11.4-0.9 17h-112.6C223.6 192.7 205.5 186.6 186 186.6c-4.4 0-8.7 0.3-12.9 0.9 -7.9-22.4-2.4-47 14.1-63.9 3.3 0.6 6.4 2.2 9 4.6 3.5 3.3 5.4 7.8 5.5 12.5 0.1 5.4 4.6 9.8 10 9.8 0.1 0 0.2 0 0.2 0 5.5-0.1 9.9-4.7 9.8-10.2 -0.2-10.1-4.4-19.5-11.7-26.5 -2.3-2.2-4.8-4.1-7.6-5.6 10.5-8.4 25.6-9.3 37.2-1.3 4.5 3.1 10.8 2 13.9-2.5 3.1-4.5 2-10.8-2.5-13.9 -19.1-13.2-44.2-11.6-61.4 2.4 -8.8-21.9-31.4-36.1-55.8-33.1 -5.5 0.7-9.4 5.6-8.7 11.1 0.7 5.5 5.6 9.4 11.1 8.7 13-1.6 25.1 4.6 31.8 14.9 -12.4-2.6-25.9 1-35.3 10.9 -3.8 4-3.7 10.3 0.3 14.1 1.9 1.8 4.4 2.8 6.9 2.8 2.6 0 5.3-1 7.2-3.1 5.5-5.8 13.9-7 20.8-3.8 -7.5 9.3-13 20.3-15.9 32 -3.7 14.9-3 30.7 1.7 45.2 -7.2 2.7-14 6.3-20.2 10.6H20.9C20.3 197.4 20 191.8 20 186 20 94.5 94.5 20 186 20zM186 352c-67.6 0-125.9-40.6-151.8-98.7h33.6c5.5 0 10-4.5 10-10s-4.5-10-10-10H26.9c-1-3.4-1.9-6.8-2.7-10.2h88.4c-11.8 15.5-18.8 34.8-18.8 55.8 0 5.5 4.5 10 10 10h164.5c5.5 0 10-4.5 10-10 0-8.9-1.3-17.4-3.6-25.5h63.1C311.9 311.4 253.6 352 186 352z"
          />
        </svg>

        <span class="card-title mt-2">Compañeros de vacaciones en los proximos 7 días</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        v-if="holidays.holidaysOn7Days.length>0"
        :span="24"
      >
        <div class="table table-users pt-0 px-4">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex justify-content-start">
                    <img
                      src="@/assets/icons/user-blue.png"
                      alt=""
                    >
                    <span>NOMBRES Y APELLIDOS</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <svg
                      id="calendar-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="124.813px"
                      height="124.813px"
                      viewBox="0 0 124.813 124.813"
                      style="enable-background:new 0 0 124.813 124.813;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                    L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                    l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                    l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                    c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                          />
                          <path
                            d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                    C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                    v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                    C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                          />
                        </g>
                      </g>
                    </svg>

                    <span class="mt-1">DESDE</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <svg
                      id="calendar-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="124.813px"
                      height="124.813px"
                      viewBox="0 0 124.813 124.813"
                      style="enable-background:new 0 0 124.813 124.813;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                    L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                    l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                    l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                    c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                          />
                          <path
                            d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                    C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                    v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                    C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                          />
                        </g>
                      </g>
                    </svg>

                    <span class="mt-1">HASTA</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="holiday in holidays.holidaysOn7Days"
                :key="holiday.id+holiday.fromDate"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <el-avatar :src="photo(holiday)">
                      {{ employeeInitials(holiday) }}
                    </el-avatar>
                    <span class="ml-2">{{ holiday.name + ' ' + holiday.lastname }}</span>
                  </div>
                </td>
                <td>
                  {{ holiday.fromDate }}
                </td>
                <td>
                  {{ holiday.toDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  <el-table :data="holidays.holidaysOn7Days" border>
             <el-table-column>
                 <template slot-scope="scope">
                     <el-avatar :src="photo(scope.row)">{{ employeeInitials(scope.row) }}</el-avatar>
                 </template>
             </el-table-column>
            <el-table-column label="Id" prop="id"></el-table-column>
             <el-table-column label="Nombre" prop="name"></el-table-column>
             <el-table-column label="Apellidos" prop="lastname"></el-table-column>
             <el-table-column label="Desde" prop="fromDate"></el-table-column>
             <el-table-column label="Hasta" prop="toDate"></el-table-column>
         </el-table> -->
      </el-col>
    </el-row>
    <el-row
      v-if="holidays.holidaysToday.length>0"
      class="px-4"
    >
      <el-col
        :span="20"
        class="d-flex "
      >
        <svg
          id="islands"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 372 372"
        >
          <path
            d="M186 0C83.4 0 0 83.4 0 186s83.4 186 186 186 186-83.4 186-186S288.6 0 186 0zM186 206.6c36.4 0 66.7 27.1 71.5 62.2H114.5C119.3 233.7 149.6 206.6 186 206.6zM266.3 233.4c-2.1-3.6-4.4-7.1-6.9-10.4h88.4c-0.8 3.4-1.7 6.8-2.7 10.2h-76.9C267.6 233.3 266.9 233.3 266.3 233.4zM186 20c91.5 0 166 74.5 166 166 0 5.8-0.3 11.4-0.9 17h-112.6C223.6 192.7 205.5 186.6 186 186.6c-4.4 0-8.7 0.3-12.9 0.9 -7.9-22.4-2.4-47 14.1-63.9 3.3 0.6 6.4 2.2 9 4.6 3.5 3.3 5.4 7.8 5.5 12.5 0.1 5.4 4.6 9.8 10 9.8 0.1 0 0.2 0 0.2 0 5.5-0.1 9.9-4.7 9.8-10.2 -0.2-10.1-4.4-19.5-11.7-26.5 -2.3-2.2-4.8-4.1-7.6-5.6 10.5-8.4 25.6-9.3 37.2-1.3 4.5 3.1 10.8 2 13.9-2.5 3.1-4.5 2-10.8-2.5-13.9 -19.1-13.2-44.2-11.6-61.4 2.4 -8.8-21.9-31.4-36.1-55.8-33.1 -5.5 0.7-9.4 5.6-8.7 11.1 0.7 5.5 5.6 9.4 11.1 8.7 13-1.6 25.1 4.6 31.8 14.9 -12.4-2.6-25.9 1-35.3 10.9 -3.8 4-3.7 10.3 0.3 14.1 1.9 1.8 4.4 2.8 6.9 2.8 2.6 0 5.3-1 7.2-3.1 5.5-5.8 13.9-7 20.8-3.8 -7.5 9.3-13 20.3-15.9 32 -3.7 14.9-3 30.7 1.7 45.2 -7.2 2.7-14 6.3-20.2 10.6H20.9C20.3 197.4 20 191.8 20 186 20 94.5 94.5 20 186 20zM186 352c-67.6 0-125.9-40.6-151.8-98.7h33.6c5.5 0 10-4.5 10-10s-4.5-10-10-10H26.9c-1-3.4-1.9-6.8-2.7-10.2h88.4c-11.8 15.5-18.8 34.8-18.8 55.8 0 5.5 4.5 10 10 10h164.5c5.5 0 10-4.5 10-10 0-8.9-1.3-17.4-3.6-25.5h63.1C311.9 311.4 253.6 352 186 352z"
          />
        </svg>
        <span class="card-title mt-2">Compañeros de vacaciones</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col
        v-if="holidays.holidaysToday.length>0"
        :span="24"
      >
        <div class="table table-users pt-0 px-4">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex justify-content-start">
                    <img
                      src="@/assets/icons/user-blue.png"
                      alt=""
                    >
                    <span>NOMBRES Y APELLIDOS</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <svg
                      id="calendar-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="124.813px"
                      height="124.813px"
                      viewBox="0 0 124.813 124.813"
                      style="enable-background:new 0 0 124.813 124.813;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                    L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                    l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                    l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                    c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                          />
                          <path
                            d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                    C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                    v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                    C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                          />
                        </g>
                      </g>
                    </svg>

                    <span class="mt-1">DESDE</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <svg
                      id="calendar-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="124.813px"
                      height="124.813px"
                      viewBox="0 0 124.813 124.813"
                      style="enable-background:new 0 0 124.813 124.813;"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M48.083,80.355l-1.915,11.374c-0.261,1.555,0.377,3.122,1.65,4.05c1.275,0.926,2.968,1.05,4.361,0.32l10.226-5.338
                                                    L72.631,96.1c0.605,0.314,1.268,0.472,1.924,0.472c0.859,0,1.716-0.269,2.439-0.792c1.274-0.928,1.914-2.495,1.651-4.05
                                                    l-1.913-11.374l8.234-8.077c1.126-1.103,1.527-2.749,1.044-4.247c-0.485-1.497-1.783-2.593-3.341-2.823l-11.41-1.692
                                                    l-5.139-10.329c-0.697-1.41-2.141-2.303-3.716-2.303c-1.572,0-3.015,0.893-3.718,2.303l-5.134,10.329l-11.41,1.691
                                                    c-1.561,0.23-2.853,1.326-3.339,2.823c-0.486,1.498-0.086,3.146,1.042,4.247L48.083,80.355z"
                          />
                          <path
                            d="M111.443,13.269H98.378V6.022C98.378,2.696,95.682,0,92.355,0H91.4c-3.326,0-6.021,2.696-6.021,6.022v7.247H39.282V6.022
                                                    C39.282,2.696,36.586,0,33.261,0h-0.956c-3.326,0-6.021,2.696-6.021,6.022v7.247H13.371c-6.833,0-12.394,5.559-12.394,12.394
                                                    v86.757c0,6.831,5.561,12.394,12.394,12.394h98.073c6.832,0,12.394-5.562,12.394-12.394V25.663
                                                    C123.837,18.828,118.275,13.269,111.443,13.269z M109.826,110.803H14.988V43.268h94.838V110.803z"
                          />
                        </g>
                      </g>
                    </svg>

                    <span class="mt-1">HASTA</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="holiday in holidays.holidaysToday"
                :key="holiday.id+holiday.fromDate"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <el-avatar :src="photo(holiday)">
                      {{ employeeInitials(holiday) }}
                    </el-avatar>
                    <span class="ml-2">{{ holiday.name + ' ' + holiday.lastname }}</span>
                  </div>
                </td>
                <td>
                  {{ holiday.fromDate }}
                </td>
                <td>
                  {{ holiday.toDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--   <el-table :data="holidays.holidaysToday" border>
              <el-table-column>
                  <template slot-scope="scope">
                      <el-avatar :src="photo(scope.row)">{{ employeeInitials(scope.row) }}</el-avatar>
                  </template>
              </el-table-column>
                               <el-table-column label="Id" prop="id"></el-table-column>
              <el-table-column label="Nombre" prop="name"></el-table-column>
              <el-table-column label="Apellidos" prop="lastname"></el-table-column>
              <el-table-column label="Desde" prop="fromDate"></el-table-column>
              <el-table-column label="Hasta" prop="toDate"></el-table-column>
          </el-table> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {employeeInitials, photo} from "@/views/production/mixin/common";

export default {

  name: "EmployeesWithHolidaysNew",
  components: {},
  props: ['holidays'],
  data() {
    return {}
  },
  computed: {
  /*  computedHolidays() {
      return _.orderBy(_.toArray(this.holidays), ['lastname']);

    },*/
  },
  mounted() {
    this.fillData();
  },
  methods: {
    employeeInitials,
    photo,
    fillData() {
      return _.orderBy(_.toArray(this.holidays), ['lastname']);

      },
  }
}
</script>

<style scoped lang="scss">
#islands {
  fill: $bg-blue;
  width: 25px;
}

#calendar-icon {
  fill: $bg-blue;
  height: 25px;
  width: 25px;
}

.card-title {
  letter-spacing: -0.05em;

  font-weight: bolder;
  margin-right: 10px;
  letter-spacing: -0.05em;
  font-size: 1.5em;
  color: $font-blue-color;
}

.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    tbody {
      td {
        letter-spacing: -0.05em;

        font-weight: bolder;

        letter-spacing: -0.05em;
        font-size: 0.9em;

        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
