import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


const RESOURCE_NAME = '/api/benefits';
const RESOURCE_NAME_MEDIA = '/api/benefit_media_objects';
export default {
    async get(params) {
        return await Api()
            .get(RESOURCE_NAME, {params})
            .catch((err) => errorHandler(err, null));
    },
    async getOne(id) {
        return await Api()
            .get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },

    async create(params) {
        return await Api()
            .post(RESOURCE_NAME, params)
            .catch((err) => errorHandler(err, null));
    },
    async delete(id) {
        return await Api()
            .delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    async update(id, obj) {
        return await Api()
            .put(RESOURCE_NAME + '/' + id, obj)
            .catch((err) => errorHandler(err, null));
    },
    async createMediaObject(file) {
        let data = new FormData();
        data.append('file', file, file.name);
        return await Api()
            .post(RESOURCE_NAME_MEDIA, data)
            .catch((err) => errorHandler(err, null));
    },
    async deleteMediaObject(id) {

        return await Api()
            .delete(RESOURCE_NAME_MEDIA + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    async getMediaObject(id) {
        return await Api()
            .get(RESOURCE_NAME + `/${id}/benefit_media_objects`)
            .catch((err) => errorHandler(err, null));
    },
    async getOneMediaObject(id) {
        return await Api()
            .get(`${id}`)
            .catch((err) => errorHandler(err, null));
    }
}
