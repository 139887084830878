<template>
  <div
    id="auth"
    class="vertical text-center animated fadeIn fast"
  >
    <router-view />
  </div>
</template>

<script>
 import auth from '@/api/auth/auth';

  export default {
    name: 'Auth',
    mounted() {
       auth.logout();
    }
  }
</script>


<style lang="scss" scoped>
  #auth {
    width: 100%;
    height: 100%;
    // background-image: url('../../assets/images/login_pattern-bg.jpg');
  }
</style>
