import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_DEPARTMENTS = '/api/departments';
const RESOURCE_JOBS = '/api/jobs';
const RESOURCE_WORKPLACE = '/api/workplaces';
const RESOURCE_LOCATIONS = '/api/locations';
const RESOURCE_PROVINCES = '/api/provinces';
const RESOURCE_LEAVE_TYPES = '/api/leave_types';
const RESOURCE_ABSENCE_TYPES = '/api/absence_types';

export default {

    // DEPARTAMENT
    getDepartments(params) {
        return Api().get(RESOURCE_DEPARTMENTS, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getDepartment(id) {
        return Api().get(RESOURCE_DEPARTMENTS + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    deleteDepartment(id) {
        return Api().delete(RESOURCE_DEPARTMENTS + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createDepartment(item) {
        return Api().post(RESOURCE_DEPARTMENTS, item)
            .catch((err) => errorHandler(err, null));
    },
    updateDepartment(item, id) {
        return Api().put(RESOURCE_DEPARTMENTS + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },

    // JOB
    getJobs(params) {
        return Api().get(RESOURCE_JOBS, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getJob(id) {
        return Api().get(RESOURCE_JOBS + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createJob(item) {
        return Api().post(RESOURCE_JOBS, item)
            .catch((err) => errorHandler(err, null));
    },
    updateJob(item, id) {
        return Api().put(RESOURCE_JOBS + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteJob(id) {
        return Api().delete(RESOURCE_JOBS + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },

    // WORKCENTER
    getWorkplaces(params) {
        return Api().get(RESOURCE_WORKPLACE, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getWorkplace(id) {
        return Api().get(RESOURCE_WORKPLACE + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createWorkplace(item) {
        return Api().post(RESOURCE_WORKPLACE, item)
            .catch((err) => errorHandler(err, null));
    },
    updateWorkplace(item, id) {
        return Api().put(RESOURCE_WORKPLACE + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteWorkplace(id) {
        return Api().delete(RESOURCE_WORKPLACE + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },


    // LOCATIONS
    getLocations(params) {
        return Api().get(RESOURCE_LOCATIONS, {params: params})
            .catch((err) => errorHandler(err, null));
    },

    // PROVINCES
    getProvinces(params) {
        return Api().get(RESOURCE_PROVINCES, {params: params})
            .catch((err) => errorHandler(err, null));
    },

    // TYPE LEAVES
    getLeaveTypes(params) {
        return Api().get(RESOURCE_LEAVE_TYPES, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    createLeaveType(item) {
        return Api().post(RESOURCE_LEAVE_TYPES, item)
            .catch((err) => errorHandler(err, null));
    },
    updateLeaveType(item, id) {
        return Api().put(RESOURCE_LEAVE_TYPES + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteLeaveType(id) {
        return Api().delete(RESOURCE_LEAVE_TYPES + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },

    // TYPE ABSENCES
    getAbsenceTypes(params) {
        return Api().get(RESOURCE_ABSENCE_TYPES, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    createAbsenceType(item) {
        return Api().post(RESOURCE_ABSENCE_TYPES, item)
            .catch((err) => errorHandler(err, null));
    },
    updateAbsenceType(item, id) {
        return Api().put(RESOURCE_ABSENCE_TYPES + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteAbsenceType(id) {
        return Api().delete(RESOURCE_ABSENCE_TYPES + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
}
