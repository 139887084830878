<template>
  <div>
    <!--    <el-table v-if="absenceData" :data="absenceData" border class="condensed-table">-->
    <!--&lt;!&ndash;      <el-table-column width="50">&ndash;&gt;-->
    <!--&lt;!&ndash;        <template slot-scope="scope">&ndash;&gt;-->
    <!--&lt;!&ndash;          <i v-if="scope.row.type === 'office'" class="el-icon-thumb"></i>&ndash;&gt;-->
    <!--&lt;!&ndash;          <i v-else class="el-icon-s-home"></i>&ndash;&gt;-->
    <!--&lt;!&ndash;        </template>&ndash;&gt;-->
    <!--&lt;!&ndash;      </el-table-column>&ndash;&gt;-->
    <!--      <el-table-column prop="observations" label="Observaciones"></el-table-column>-->
    <!--      <el-table-column prop="start" label="Entrada"></el-table-column>-->
    <!--      <el-table-column prop="end" label="Salida"></el-table-column>-->
    <!--    </el-table>-->
    <!--    <span v-else>Sin ausencias</span>-->
    <!--      {{ absence.observations | capitalize }} <br> Desde {{ absence.fromDate }} hasta {{ absence.toDate }}-->
    {{ capitalize(absenceData.observations) }} <br> Desde {{ absenceData.start }} hasta {{ absenceData.end }}
  </div>
</template>

<script setup>

import {computed, defineProps} from "vue";

const props = defineProps({
  absence: {
    type: Object,
    required: true
  }

})

       const absenceData = computed(() => {
          return {
              observations: props.absence.observations,
              start: props.absence.fromDate,
              end: props.absence.toDate ? props.absence.toDate : '-',
            };
        })
      // absenceData() {
      //   let info =  _.map(_.chunk(_.toArray(this.absence), 2), (data) => {
      //     return {
      //       start: data[0].time,
      //       end: data[1]?.time ?? '-',
      //       type: data[0].remote ? 'home' : 'office'
      //
      //     };
      //   });
      //   // return info;
      //   console.log(222222, this.absence)
      //   // let info = {
      //   //   observations: this.absence.observations,
      //   //   start: this.absence.fromDate,
      //   //   end: this.absence.toDate,
      //   // }
      //
      //   console.log(info);
      //   return info;
      // }
      const capitalize = (value) => {
        if (typeof value !== 'string') return ''
        return value.charAt(0).toUpperCase() + value.slice(1)
      }


</script>