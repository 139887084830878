<template>
  <div class="container m-0 p-0">
    <div class="d-flex flex-nowrap align-items-center">
      <svg
          id="undanita-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 595.3 841.9"
          style="enable-background: new 0 0 595.3 841.9"
          xml:space="preserve"
      >
        <path
            d="M554,362.9c-29.7,30.6-59.4,61.2-88.9,92c-1.5,1.5-2,4.5-2,6.7c-0.1,59.2-0.1,118.3-0.1,177.5c0,2,0,4,0,6.4
              c-109.9,0-219.6,0-329.9,0c0-2,0-3.9,0-5.9c0-59.3,0-118.7,0.1-178c0-3.4-1-5.9-3.4-8.3C100.4,423.2,71.2,393,42,362.9
              c0-0.3,0-0.7,0-1c1.1-1.4,2.3-2.8,3.3-4.3c21.5-33.1,42.7-66.4,64.6-99.2c27-40.7,65.4-61.8,114.2-62.4c49.3-0.6,98.6-0.6,148,0
              c48.8,0.6,87.2,21.7,114.2,62.4c21.8,32.9,43.1,66.1,64.6,99.2c1,1.5,2.2,2.9,3.3,4.3C554,362.2,554,362.5,554,362.9z M207.6,227.1
              c-30.7,5.4-54.8,20.7-71.9,46.4c-17.9,27.1-35.4,54.5-53.2,81.7c-2,3-1.7,4.6,0.7,7.1c15.6,15.9,31.1,32.1,46.6,48.1
              c0.7,0.8,1.6,1.5,3.2,3c0-18.2,0-35.2,0-52.2c10.3,0,20,0,30.1,0c0,85,0,169.6,0,254.4c90,0,179.6,0,269.8,0c0-85,0-169.7,0-254.4
              c10.3,0,20,0,30.2,0c0,17.3,0,34.2,0,51.5c0.9-0.6,1.3-0.7,1.6-1.1c16.2-16.8,32.4-33.6,48.7-50.3c2-2,1.7-3.5,0.3-5.6
              c-4.6-6.9-9.1-14-13.7-20.9c-15.4-23.4-29.9-47.4-46.5-69.9c-15.9-21.6-38.7-33.4-65.6-38c-1.4,6.9-2.7,13.6-4.2,20.2
              c-9,38.3-42.2,66.4-80.7,68.4c-39.8,2.1-75.8-22.1-88.5-60C211.6,246.6,210,237.1,207.6,227.1z M357.3,226.1c-39.7,0-79.1,0-118.5,0
              c3.1,35.1,27.2,59.8,59,59.6C327.8,285.5,353.5,265,357.3,226.1z"
        />
        <path
            class="st0"
            d="M369.6,343.1v101.7c0,9.9-1.7,19.1-5.2,27.7c-3.5,8.5-8.7,16.3-15.7,23.2c-7,7-14.8,12.3-23.3,15.8
              c-8.5,3.6-17.8,5.3-27.8,5.4c-9.9,0-19-1.8-27.5-5.4c-8.5-3.6-16.2-8.8-23.3-15.8c-7-6.9-12.2-14.6-15.7-23.2
              c-3.5-8.5-5.2-17.8-5.2-27.7V343.1h52.1v101.7c0.1,5.7,2.1,10.4,5.8,14c3.7,3.7,8.4,5.6,13.9,5.7c5.7-0.1,10.4-2,14.2-5.7
              c3.8-3.7,5.7-8.4,5.8-14v-71.7L369.6,343.1"
        />
      </svg>
      <h1 class="title mt-3">
        {{ title }}
      </h1>
    </div>
    <div
        v-if="show"
        class="row"
    >
      <div
          v-for="card in undanitas"
          :key="card.id"
          class="col-12 col-lg-6 px-lg-0 px-xl-auto"
      >
        <card-undanita
            :title="card.title"
            :content="card.newsBody"
            :datetime="card.timestamp"
            :imagen="getUrlMedia(card.media)"
            :employee="card.employee.name + card.employee.lastName"
            :idundanita="card.id"
            :iriundanita="card['@id']"
            :confirm-reader="card.readConfirmation"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CardUndanita from "./CardUndanita.vue";
import Undanita from "@/api/services/undanitas.service.js";
import {ref, defineProps, onMounted} from "vue";

defineProps({
  title: String,
  icon: String,
})
const undanitas = ref([]);
const show = ref(false);

onMounted(()  =>{
  filterUndanitas();
})

// eslint-disable-next-line no-unused-vars
// const getObjectsMedia = async(ids) => {
//   if(ids.length === 0) return Promise.resolve([]);
//   const aux = [];
//
//   ids.forEach((v) => {
//     aux.push(getMediaObjects(typeof v === "object" ? v.id :v));
//   });
//   return Promise.all(aux);
// }
// // eslint-disable-next-line no-unused-vars
// const getMediaObjects = async(id) =>{
//   return await Undanita.getMediaObject(id);
// }
const filterUndanitas = async () => {
  const params = {
    itemsPerPage: 2,
    page: 1,

  };
  params["order[timestamp]"] = "desc";
  params["status.name"] = "Publicado";
  await Undanita.get(params).then(async (resp) => {

    undanitas.value = resp.data["hydra:member"];

    for (const element of undanitas.value) {
      element.media = await getImageUndanitaMediaObject(element.id)
    }

    // for (const element of undanitas.value) {
    //   const media = await getObjectsMedia(
    //       element.undanitaMediaObjects
    //   );
    //
    //   element.media = media.map((v) => v.data);
    // }

    show.value = true;
  });
}
const getImageUndanitaMediaObject = async (id) => {
  return await Undanita.getMediaObject(id);
}

const  getUrlMedia = (media) => {

  if (media && media.data && media.data['hydra:member'] && media.data['hydra:member'].length > 0) {
    return media.data['hydra:member'][0].contentUrl;
  }
  return '';
}

</script>
<style lang="scss" scoped>
@import "../../assets/sass/styles.scss";
#undanita-icon {
  fill: $bg-blue;
  width: 50px;
}

#chronometer {
  fill: white;
  width: 10px;
  margin-right: 2px;
}

.title {
  color: $font-blue-color;
  letter-spacing: -0.05em;
  font-size: 2.99em;
  font-weight: bolder;
  line-height: 30px !important;
}
</style>
