import Api from '@/api/Api';

import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/holiday_incompatibilities';

export default {

    // HOLIDAYS
    getHolidayIncompatibilities(params) {
        return Api().get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getHolidayIncompatibility(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createHolidayIncompatibility(item) {
        return Api().post(RESOURCE_NAME, item)
            .catch((err) => errorHandler(err, null));
    },
    updateHolidayIncompatibility(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteHolidayIncompatibility(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },

}
