<template>
  <div>
    <header-title title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/speaker-icon.svg" width="30" alt="">
      <div class="title-text ml-4">
        BENEFICIOS
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="w-100 label-common">
          <div class="form-item">
            <label for="title" class="w-100">T&iacute;tulo</label>
            <el-input
              id="title"
              v-model="title"
              :placeholder="'Ingrese título'"
            />
          </div>
        </div>
      </div>
      <div class="col-md-2">
          <div class="form-item date-picker  label-common w-100">
          <label
            for="id-improvements"
            class="w-100"
          >Validez Desde</label>
          <el-date-picker
              size="large"
            :ref="'fromDate'"
            v-model="fromDate"
            :name="'from-date'"
            type="date"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
            :prefix-icon="'el-icon-date'"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
      </div>
      <div
          class="col-md-2 label-common"
      >
        <div class="form-item date-picker w-100">
          <label
              for="id-status"
              class="w-100"
          >Hasta</label>
          <el-date-picker
              size="large"
              :ref="'toDate'"
              v-model="toDate"
              :name="'to-date'"
              type="date"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              :prefix-icon="'el-icon-date'"
              :picker-options="{ firstDayOfWeek: 1,disabledDate:(time)=>disabledEndDate(time,fromDate)}"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div
          class="col-md-8 label-common"
      >
        <div class="form-item">
          <label
              for="id-status"
              class="w-100"
          >Empresa</label>
          <el-input
              id="title"
              v-model="company"
              :placeholder="'Ingrese empresa'"
          />
        </div>
      </div>
      <div
        class="col-md-4 label-common"
      >
        <div class="form-item">
          <label for="title" class="w-100">Estado</label>
          <el-radio-group
            v-model="status"
            class="ml-4"
          >
            <el-radio
              label="active"
              size="large"
            >
              Activo
            </el-radio>
            <el-radio
              label="noactive"
              size="large"
            >
              No activo
            </el-radio>
        </el-radio-group>
        </div>
      </div>


    </div>
    <div class="row">
      <div
        class="col-md-6 label-common"
      >
        <div class="form-item">
        <label
          for="id-status"
          class="w-100"
        >Nombre contacto</label>
        <el-input
          id="title"
          v-model="contact"
          :placeholder="'Ingrese contacto'"
        />
        </div>
      </div>
      <div
        class="col-md-6 label-common"
      >
        <div class="form-item">
        <label
          for="id-status"
          class="w-100"
        >Tel&eacute;fono contacto</label>
        <el-input
          id="title"
          v-model="contactPhone"
          :placeholder="'Ingrese telefono'"
        />
        </div>
      </div>
    </div>

    <div class="row ml-1 mt-5 mb-4 label-common">
      <label class="w-100">Descripci&oacute;n</label>
      <QuillEditor   contentType="html" v-model:content="description" theme="snow" />
    </div>


    <el-row class="label-common">
      <el-col :span="8">
        <div class="d-flex flex-nowrap flex-column mt-4 w-100 label-common">
          <label>Imagen (300*300)</label>

          <PreviewMediaObjectImage
            :media-object="mediaObjects"
            @deleteMediaObject="(id) => deleteMedia(id)"
          />

          <button
            :disabled="disableSelect"
            class="btn btn-block btn_yellow mt-4"
            style="width: 300px"
            @click="selectFile()"
          >
            Seleccionar
          </button>
        </div>
      </el-col>
    </el-row>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn_yellow btn-search"
        :disabled="disableSave"
        @click="goBack()"
      >
        VOLVER
      </button>
      <button
        class="btn btn_red btn-search ml-4"
        @click="save()"
      >
        GUARDAR
      </button>
    </div>
  </div>
</template>
<script>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import Service from "@/api/services/benefit";
import Notification from "@/utils/plugins/NotificationService";
import PreviewMediaObjectImage from "@/components/shared/PreviewMediaObjectImage.vue";
import moment from "moment";
import router from "@/app/router/router";

/* import moment from "moment"; */

export default {
  components: {
    HeaderTitle,
    PreviewMediaObjectImage,
  },
  props:{
    benefit: {type: Object, default: null},
    update: {type:Boolean, default: false}
  },

  data() {
    return {
      title: "",
      description:"",
      status: "noactive",
      optionsStatus: [],
      benefits:[],
      fromDate:'',
      toDate:'',
      mediaObjects:[],
      disableSave: false,
      disableSelect: false,
      contact:'',
      company:'',
      contactPhone:'',
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }, "link"]
      ]
    };
  },
  computed:{
    isAdmin(){
      const user = this.$store.getters.getUserLogged;
      return user.roles.includes('ROLE_ADMIN');
    },

  },
  watch:{

    mediaObjects(newVal) {
      //console.log("VIEWWWWWW",newVal);
      if (newVal.length === 3) {
        this.disableSelect = true;
      }
      if (newVal.length >= 0 && newVal.length < 3) {
        this.disableSelect = false;
      }
    }
  },
  mounted(){
    if(this.update && this.benefit !== null) {
      this.loadData(JSON.parse(JSON.stringify(this.benefit)));
    }
  },
  methods:{
    getMediaObjects() {
      Service.getMediaObject(this.$route.params.id).then((resp) => {
        // console.log("Media Objects",resp);
        this.mediaObjects = resp.data["hydra:member"];
      });
    },
    save(){
      if (!this.title) {
        Notification.addNotification(
            "El campo título no debe ser vacío.",
            "error",
            6000
        );
        return;
      }
      if (this.$route.params.id) this.updateBenefit();
      else {
        this.create();
      }
    },
    updateBenefit(){
      const params ={
        title:this.title,
        active:this.status === 'active' ? true:false,
        description:this.description,
        fromDate:this.fromDate,
        toDate:this.toDate,
        contactName:this.contact,
        contactPhone:this.contactPhone,
        company:this.company

      }
      if (this.mediaObjects.length > 0) {
        params.benefitMediaObjects = this.mediaObjects.map(
            (v) => v["@id"]
        );
      }
      this.disableSave = true
      Service.update(this.$route.params.id, params).
      then(() => {
        this.disableSave = false
        router.push({name: "benefits-search"})
      });
    },
    create(){
       const params ={
         title:this.title,
         active:this.status === 'active' ? true:false,
         description:this.description,
         fromDate:this.fromDate,
         toDate:this.toDate,
         contactName:this.contact,
         contactPhone:this.contactPhone,
         company:this.company

        }
      if (this.mediaObjects.length > 0) {
        params.benefitMediaObjects = this.mediaObjects.map(
            (v) => v["@id"]
        );
      }
      this.disableSave = true
        Service.create(params).then(()=> {
          this.disableSave = false
          router.push({name: "benefits-search"})
        });
    },
    disabledEndDate(date,departureDate){

      let flag = false;
      // If departureDate then return valid dates after departureDate
      if (departureDate) {
        flag = moment(date.getTime()) < moment(departureDate)

      } else {
        // If !departureDate then return valid dates after today
        flag = moment(date.getTime()) < moment.now();
        /* return date.getTime() < Date.now() */
      }

      return flag;
    },
    deleteMedia(id) {
      //console.log("AAAAAA",id);
      Service.deleteMediaObject(id).then(() => {
        //console.log("Delete Media",resp);
        this.mediaObjects = this.mediaObjects.filter(
            (v) => v.id != id
        );
      });
    },
    selectFile() {
      const _this = this;
      var fileSelector = document.createElement("input");
      fileSelector.setAttribute("type", "file");
      fileSelector.addEventListener("change", function (e) {
        //console.log("File",e.target.files);
      /*  var extn = e.target.files[0].type.split("/")[1];*/
        var size = e.target.files[0].size;
        var maxSize = 2097152; // 2097152 byte
        if (size < maxSize) {
          _this.disableSave = true;
          Service.createMediaObject(e.target.files[0]).then((resp) => {
            //console.log("Add media",resp);
            _this.mediaObjects.push(resp.data);
            _this.disableSave = false;
          });
        } else {
          Notification.addNotification(
              "No se puede cargar el Archivo porque excede el límite permitido.",
              "error",
              6000
          );
        }
        /*  _this.urlImage =URL.createObjectURL(e.target.files[0]); */
      });
      fileSelector.click();
      return false;
    },

    loadData(sgg){
      console.log("Data", sgg);
      //console.log("Sugg",sgg);
      this.title = sgg.title;
      this.description = sgg.description;
      this.status = sgg.active ? 'active':'noactive';
      this.fromDate = sgg.fromDate;
      this.toDate = sgg.toDate;
      this.company = sgg.company,
      this.contact=sgg.contactName,
      this.contactPhone=sgg.contactPhone,
      this.getMediaObjects()
      },
    async cleanMedia() {
      const prom = [];
      if (this.mediaObjects.length > 0) {
        const haveUndanew =
            this.benefit && this.benefit.benefitMediaObjects ? true : false;
        this.mediaObjects.forEach((v) => {
          if (haveUndanew) {
            const pos = this.benefit.benefitMediaObjects.findIndex(
                (r) => r === v["@id"]
            );
            if (pos === -1) prom.push(this.deleteMedia(v.id));
          } else {
            prom.push(this.deleteMedia(v.id));
          }
        });
        console.log("pomess", prom);
        if (prom.length > 0) {
          Promise.all(prom).then(() => {
            router.push({ name: "benefits-search" });
          });
        } else {
          router.push({ name: "benefits-search" });
        }
      } else {
        router.push({ name: "benefits-search" });
      }
    },
    async goBack(){
      await this.cleanMedia();
    },




  }
};
</script>
<style lang="scss" scoped>
.btn-search {
  &.btn_yellow {
    color: $bg-blue;
  }
  &.btn_red {
    color: white;
  }
  width: 20em;
  height: 3em;

  font-weight: bolder;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
}
.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}
.card-search {
  border-radius: 15px;
  height: 150px;
  background: url("../../assets/icons/altavoz.png"), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}
#icon-search {
  fill: $bg-blue;
  width: 6%;
}
#icon-search-calendar {
  fill: $bg-blue;
  width: 120px;
  height: 20px;
}
.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
      #icon-search {
        fill: $bg-blue;
        width: 20px;
      }
      #icon-user {
        fill: $bg-blue;
        width: 1.6em;
      }
    }
    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
