import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';
//import axios from 'axios';
//import CONSTS from '@const/constants'

const RESOURCE_SERVER_TIMESTAMP = '/api/server_timestamp'

export default {

    // SERVER TIMESTAMP
    getServerTimestamp() {
        return Api().get(RESOURCE_SERVER_TIMESTAMP)
            .catch((err) => errorHandler(err, null));
    },

    /**
     *
     * @param arr1 an array
     * @param arr2 an array
     * @returns {boolean}
     */
    ifArrayIsInArray(arr1, arr2) {
        if (arr1 && arr2) {
            for (const o of arr1) {
                for (const b of arr2) {
                    if (o === b) {
                        return true;
                    }
                }
            }
        }

        return false;
    },

    /**
     *
     * @param array
     * @param obj
     * @returns {boolean}
     */
    ifIsInArray(array, obj) {
        for (const o of array) {
            if (o === obj) {
                return true;
            }
        }

        return false;
    },
}
