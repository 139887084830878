<template>
  <div>
    <header-title title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <svg
        id="icon-search"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 595.3 841.9"
        style="enable-background: new 0 0 595.3 841.9"
        xml:space="preserve"
      >
        <path
          d="M214.3,488.9c2.5,17.2,4.8,33.7,7.2,50.2c0.7,4.8,1.4,9.6,2.1,14.3c1.6,11.3-3.6,22.7-14,27.6c-12.1,5.7-24.7,5.8-36.4-1.3
                        c-7.8-4.7-11-12.4-12.2-21.4c-2.9-21.8-6.1-43.5-9.3-65.3c-0.2-1.3-0.6-2.5-0.9-3.9c-3-0.2-5.9-0.2-8.6-0.7
                        c-12.7-2.2-22.3-12.2-22.5-25.1c-0.4-23-0.5-46,0-69c0.3-14.6,12.2-25.4,26.9-25.5c23.3-0.1,46.7,0.1,70-0.1
                        c57.7-0.4,106.4-21.6,145.7-63.9c7.9-8.5,15.7-17.3,23.5-25.9c7.1-7.8,16.2-10.2,25.3-6.8c9,3.4,14.3,11.3,14.3,21.9
                        c0.1,23.8,0.2,47.7,0,71.5c0,4.2,0.9,5.9,5.5,6.7c27.9,4.8,47.9,29.1,47.6,57.2c-0.2,28.1-20.7,51.8-48.6,56.4
                        c-1.3,0.2-2.6,0.5-4.4,0.9c0,2.1,0,4,0,6c0,22.8,0.2,45.7-0.1,68.5c-0.2,16.8-15.7,27.5-30.8,21.3c-3-1.2-5.7-3.4-8.1-5.7
                        c-11.8-11.5-22.9-23.8-35.1-34.8c-37.8-34.2-82.6-51.6-133.5-53.3C217,488.8,216,488.9,214.3,488.9z M403.7,563.9
                        c0-91.6,0-181.5,0-271.4c-0.4-0.2-0.8-0.4-1.3-0.6c-1,1.3-2,2.5-3.1,3.7c-6.6,7.3-13.2,14.6-19.8,21.9
                        c-33.8,37.5-75.4,60.8-125.1,69.5c-3.7,0.7-4.8,2.1-4.8,5.8c0.1,23.7,0.2,47.3-0.1,71c-0.1,5.3,1.4,7,6.5,8
                        c34.3,6.8,66.4,19.4,93.7,41.5c17.3,14,32.6,30.4,48.8,45.7C399.8,560.3,401.2,561.6,403.7,563.9z M228.2,390.5
                        c-0.6-0.3-1-0.5-1.3-0.5c-26.3,0-52.6,0-79,0.1c-5.4,0-7.2,2.2-7.2,7.9c0,20.3,0,40.6,0,61c0,7.2,1.7,8.8,8.8,8.8
                        c21.3,0,42.6,0,64,0c4.8,0,9.6,0,14.7,0C228.2,441.8,228.2,416.1,228.2,390.5z M425.9,393.2c0,24,0,47.8,0,71.5
                        c15.9-0.4,30.5-16.3,31.3-33.8C458.1,412,444.8,395.6,425.9,393.2z M172.7,488.9c0.2,2.3,0.3,4,0.6,5.6c2.8,19.4,5.6,38.9,8.4,58.3
                        c1.5,10.2,2,10.6,12.3,10.5c0.7,0,1.3,0,2,0c4.8-0.3,7.1-2.8,6.5-7.5c-3-21.3-6.1-42.5-9.3-63.7c-0.2-1.2-2.1-2.9-3.3-3
                        C184.4,488.7,178.9,488.9,172.7,488.9z"
        />
      </svg>
      <div class="title-text ml-4">
        SUGERENCIAS
      </div>
    </div>
    <el-row>
      <el-card
        class="card-search w-100"
        body-style="padding:0"
      >
        <div class="row mx-5">
          <div class="col-md-4">
            <div class="form-item  label-common">
              <label for="title" class="w-100">T&iacute;tulo</label>
              <el-input
                v-model="title"
                clearable
                class="search-input"
                placeholder="Ingrese título"
                @change="search"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item  label-common">
              <label
                for="id-improvements"
                class="w-100"
              >&Aacute;rea de mejora</label>
              <el-select
              v-model="improvements"
              filterable
              clearable
              placeholder="Todos"
              @change="search"
              >
                <el-option
                    v-for="item in optionsImprovements"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  />

              </el-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item  label-common">
              <label
                for="id-status"
                class="w-100"
              >Estado</label>
              <el-select
                  v-model="status"
                  filterable
                  clearable
                  placeholder="Todos"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="row mx-5">
          <div class="col-md-4">
            <div class="form-item  label-common">
              <label
                for="id-employee"
                class="w-100"
              >Autor</label>
              <el-select
                  class="w-100"
                  size="large"
                  v-model="employee"
                  filterable
                  clearable
                  placeholder="Todos"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsEmployee"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item  label-common">
              <label
                for="id-responsible"
                class="w-100"
              >Responsable</label>
              <el-select
                  v-model="employeeResponsible"
                  filterable
                  clearable
                  placeholder="Todos"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsResponsible"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-end">
            <button
              class="btn btn_yellow btn-search"
              @click="search()"
            >
              Buscar
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-users">
        <table>
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-start align-items-center gap-2">
                  <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="title-icon" />
                  <div>TITULO</div>
                </div>
              </th>
              <th>
                <div class="d-flex flex-row justify-content-start align-items-start gap-2">
                  <img src="@/assets/icons/user.svg" width="20" height="20"  alt="user-icon" />
                  <div class="ml-2">
                    AUTOR
                  </div>
                </div>
              </th>
              <th>
                <div class="d-flex justify-content-start align-items-center gap-2">
                  <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="area-icon" />
                  <div>AREA DE MEJORA</div>
                </div>
              </th>
              <th>
                <div class="d-flex justify-content-start align-items-center gap-2">
                  <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="estate-icon" />
                  <div>ESTADO</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sugg in suggestions"
              :key="sugg.id"
            >
              <td>
                <div class="link-name">
                  <router-link :to="{name: 'suggestions-update', params: { id: sugg.id }}">
                    {{ sugg.title }}
                  </router-link>
                </div>
              </td>
              <td>
                <CellEmployee
                  :employee="selectEmployee(sugg.author['@id'])"
                  :is-show-last-name="false"
                />
              </td>
              <td>
                {{ sugg.department && sugg.department.name }}
              </td>
              <td>
                {{ sugg.suggestionStatus && sugg.suggestionStatus.name }}
                <div class="buttons d-inline-flex justify-content-center align-items-center">
                    <router-link :to="{name: 'suggestions-update', params: { id: sugg.id }}" style="height: 20px;">
                      <shared-button-plus/>
                    </router-link>
                    <font-awesome-icon style="height: 20px;margin-top: 15px;margin-left:10px;margin-bottom: auto;"
                        icon="trash"
                        class="2x"
                        @click="deleteSuggestion(sugg.id)"
                    />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-row>
  </div>
</template>
<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import EmployeeServices from "@/api/services/employee.service";
import SuggestionServices from "@/api/services/suggestion.service";
import DepartmentServices from "@/api/services/department.service";
import CellEmployee from "@/components/shared/CellEmployee.vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import {ElMessageBox} from "element-plus";
import notification from "@/utils/plugins/NotificationService";

import { ref, onMounted } from "vue";

      const itemsPerPage= ref(10);
      const page= ref(1);
      const title= ref("");
      const improvements= ref("");
      const optionsImprovements= ref([]);
      const status= ref("");
      const optionsStatus= ref([]);
      const employee= ref("");
      const employeeResponsible= ref('');
      const optionsResponsible= ref([]);
      const optionsEmployee= ref([]);
      const suggestions= ref([]);

  onMounted(() => {

    Promise.all([
      getEmployees(),
      getStatus(),
      getDepartments()])
    .then(()=>filterSuggestions());
    /* this.getProjectManager(); */
  })

    const search = () => {
      filterSuggestions();
    }

    // const selectSuggestionStatus = (status) => {
    //   const statussugg =  optionsStatus.value.find(v=>v['@id']===status);
    //   //console.log("SSS",statussugg);
    //   return statussugg && statussugg.name && statussugg.name;
    // }

    const selectEmployee = (emp) => {
      return optionsEmployee.value.find(v => v['@id'] === emp);
    }

    const filterSuggestions = () => {
      const params = {
        itemsPerPage:itemsPerPage.value,
        page:page.value,
        'order[timestamp]':'DESC',

        }
      if (title.value)
        params.title = title.value;

      if (improvements.value){
        params['department.name'] = improvements.value
      }
      if(employeeResponsible.value)
      {
        let responsible = optionsResponsible.value.filter(v=>v.id===employeeResponsible.value)[0];
        params['responsible.name'] = responsible.firstName;
        params['responsible.lastName'] = responsible.lastName;
      }
      if(employee.value){
        params.orSearch_fullname_author = employee.value;
      }

      if(status.value){
        params['suggestionStatus.name'] = status.value;
      }

      SuggestionServices.getSuggestions(params)
        .then((resp)=>{
            suggestions.value = resp.data['hydra:member'];
        })
    }

    const deleteSuggestion = (id) => {
      ElMessageBox.confirm(
          'Esta seguro que desea continuar?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
      )
          .then(async () => {
            SuggestionServices.delete(id).then(() => {
              filterSuggestions();
              notification.addNotification("Sugerencia eliminada correctamente","success",10000)
            });

          })
          .catch(() => {

          })

    }
   const  getDepartments= async () => {
      return await DepartmentServices.getDepartments({itemsPerPage:9999,page:1})
          .then((resp)=>{
                optionsImprovements.value = resp.data['hydra:member'];
                return true;
       });
    }

     const getEmployees = async () => {
      return  EmployeeServices.getEmployees({itemsPerPage:9999, page:1, active:true})
          .then((resp)=>{
                //console.log("Select emp",resp.data['hydra:member'])
                optionsEmployee.value = [...resp.data['hydra:member']];

                optionsResponsible.value = resp.data['hydra:member'].map(v=>{
                  v.firstName = v.name;
                  v.name = v.firstName + ' ' + v.lastName;
                  return v;
                });
                return true;
       });
     }
    /*  getProjectManager(){
      ProjectManagerServices.getProjectsManager({itemsPerPage:9999,page:1})
        .then(resp=>{
          this.optionsResponsible = resp.data['hydra:member'];

        })
     }, */
     const getStatus = () => {
        SuggestionServices.getStatus({itemsPerPage:9999, page:1})
        .then((resp)=>{
            optionsStatus.value = resp.data['hydra:member'];
        })
     }
</script>
<style lang="scss" scoped>
.title-text {
  text-align: center;
  align-self: center;

  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}
.card-search {
  border-radius: 15px;
  height: 285px;
  background: url("../../assets/icons/altavoz.png"), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}
#icon-search {
  fill: $bg-blue;
  width: 6%;
}
.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}
.btn-search {
  width: 20em;
  height: 3em;
  color: $bg-blue;
  font-weight: bolder;
  text-transform: uppercase;
}
.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
      #icon-search {
        fill: $bg-blue;
        width: 2.9em;
      }
      #icon-user{
        fill: $bg-blue;
        width: 1.6em;
      }
    }
    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
