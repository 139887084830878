import axios from 'axios'
import CONSTS from '@const/constants'


export default (apiKey) => {
  return axios.create({
    baseURL: CONSTS.CLOCKIFY_API_ALT,
    withCredentials: false,
    headers: {
      'Accept': '*/*',
      'x-api-key':  apiKey
    },
  })
}
