import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


export default {
    getDailyReport(params) {
        return Api()
            .get('/api/production_reports/daily_production', {params: params})
            .catch((err) => errorHandler(err, null));
    },
    syncClockify() {
        return Api()
        .get('/api/production_reports/sync_clockify')
        .catch((err) => errorHandler(err, null));
    },

}

