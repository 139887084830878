<template>
  <div v-if="benefit"
       class="card m-2 p-2 pointer"
       style="width: 250px"
       @click="showMyDialogBenefit()"
  >
    <el-image
        lazy
        :src="baseUrl + (benefit && benefit.benefitMediaObjects && benefit.benefitMediaObjects.length >0 ?  benefit.benefitMediaObjects[0].contentUrl : null)"
        fit="scale-down"
        class="card-img-top"
        style="height:16rem"
    >
      <template v-slot:error>
        <img
            class="card-img-top"
            src="@/assets/images/picture-loading-failed.png"
            alt=""
            srcset=""
        >
      </template>
    </el-image>
    <div class="card-body">
      <div class="benefit-text">
        {{ benefit.title }}
      </div>
      <div>
        <b class="text-valid">Validez</b>
      </div>
      <div>
        <p class="text-valid">
          Del {{ formatDateTime(benefit.fromDate) }} hasta {{ formatDateTime(benefit.toDate) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>

import moment from "moment/moment";
import {defineProps} from "vue";
import {useStore} from "vuex";


const store = useStore();
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  benefit: {
    type: Object,
    required: true
  }
})

const baseUrl = process.env.VUE_APP_BASE_URI;

const formatDateTime = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }
  return "";
}
const showMyDialogBenefit = async () => {
  await store.dispatch('setBenefit', props.benefit).then(() => {
    store.dispatch('setShowDialogBenefit', true);
  })
}


</script>
<style lang="scss" scoped>
.benefit-text {
  text-align: start;
  align-self: center;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}
.text-valid{
  font-size: 0.7em;
}
</style>