import Api from '@/api/Api';
import axios from 'axios';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/employees';
const RESOURCE_MEDIA_OBJECT = '/media_objects';
//const RESOURCE_ABSENCE_SUPPORTING_DOCUMENT = '/absence_supporting_documents';


export default {

    // EMPLOYEES
    async get(resource){
        return Api()
            .get(resource)
            .catch((err) => errorHandler(err, null));
    },
    getEmployees(params) {
        return Api()
            .get(RESOURCE_NAME, { params: params })
            .catch((err) => errorHandler(err, null));
    },
    getSystemEmployees(params) {
        return Api()
            .get(RESOURCE_NAME+'?active=1&job.name=Administrador de Sistemas', { params: params })
            .catch((err) => errorHandler(err, null));
    },
    getEmployee(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err)=> errorHandler(err, null));
    },
    createEmployee(item) {
        return Api().post(RESOURCE_NAME, item)
            .catch((err) => errorHandler(err, null));
    },
    updateEmployee(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteEmployee(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    uploadImageFile( file) {
        let formData = new FormData();
        const token = localStorage.getItem('token');
        formData.append('file', file);
        return axios.post(process.env.VUE_APP_API_URI + RESOURCE_MEDIA_OBJECT, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/ld+json' },
            errorHandle: false
        })
            .catch(error => {
                console.log('Error: ' + error.response)
            })

    },
    // uploadDocumentFile(item, file) {
    //     let formData = new FormData();
    //     const token = localStorage.getItem('token');
    //     formData.append('file', file);
    //
    //     return axios.post(process.env.VUE_APP_API_URI + RESOURCE_ABSENCE_SUPPORTING_DOCUMENT, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             // 'Authorization': 'Bearer ' + token,
    //             'Accept': 'application/ld+json' },
    //         errorHandle: false
    //     })
    // },
    getImageEmployee(id) {
        return Api()
            .get(id)
            .catch((err) => errorHandler(err, null));
    },
    deleteImageEmployee(id) {
        const token = localStorage.getItem('token');
        return axios.delete(process.env.VUE_APP_API_URI + RESOURCE_MEDIA_OBJECT + '/' + id, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/ld+json' },
            errorHandle: false
        })
            .catch(error => {
                console.log('Error: ' + error.response)
            })
    },

}

