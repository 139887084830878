import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_DOMAINS = '/api/servers';

export default {
    async getServers(params){
        return await Api()
            .get(RESOURCE_DOMAINS,{params})
            .catch((err) => errorHandler(err, null));
    }
}
