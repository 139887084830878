
import errorHandler from '@/utils/plugins/ErrorHandler';
import ApiClockify from "@/api/ApiClockify";
import ApiClockifyAlt from "@/api/ApiClockifyAlt";


const RESOURCE_TAGS = '/tags';
const RESOURCE_ENTRY = '/timeEntries';


export default {
    async getTags(apiKey){
        return await ApiClockify(apiKey)
        .get(RESOURCE_TAGS)
        .catch((err) => errorHandler(err, null));
    },
    async startTime(apiKey,params){
        return await ApiClockify(apiKey)
        .post(RESOURCE_ENTRY,params)
        .catch((err) => errorHandler(err, 'Ha ocurrido un error al iniciar el tiempo'));
    },
    async stopTime(apiKey,id, payload){
        return await ApiClockify(apiKey)
        .put('/timeEntries/'+id+'/full', payload)
        .catch((err) => errorHandler(err, null));
    },
    async getTimeEntry(apiKey,timeEntryId) {
        return await ApiClockifyAlt(apiKey)
            .get('/time-entries/'+timeEntryId)
            .catch();
    },
    async getTimeEntries(apiKey, userId) {
        return await ApiClockifyAlt(apiKey)
            .get('/user/'+userId+'/time-entries?in-progress=true')
            .catch();
    },
    async updateEntry(apiKey,timeEntryId, payload) {
        return await ApiClockify(apiKey)
            .put('/timeEntries/'+timeEntryId+'/tags', payload)
            .catch();
    }
}


