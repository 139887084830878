<script setup>

import {computed, onBeforeUnmount, onMounted, ref} from "vue";

const currentTime = ref(new Date());
let timer = null;
let isRunning = true;

const time = computed(() => currentTime.value.toLocaleTimeString());


onMounted(() => {
  toggleClock();
});

onBeforeUnmount(() => {
  clearInterval(timer);
});
const updateClock = () => {
  currentTime.value = new Date();
};

const toggleClock = () => {
  isRunning = !isRunning;
  if (isRunning) {
    timer = setInterval(updateClock, 1000);
  } else {
    clearInterval(timer);
  }
};

</script>

<template>
    <div class="tracker-dtime">{{ time }}</div>
</template>

<style scoped lang="scss">

</style>