<script>


import moment from "moment";

export default {
  name: "DomainHistoryDataTable",
  props: ['domainHistory'],
  data() {
    return {}
  },
  mounted() {
    // console.log('mounted DomainHistoryDataTable', this.domainHistory);
  },
  methods: {
    sortBy() {
      if (this.sortType === '') {
        this.sortType = 'ASC';
      } else if (this.sortType === 'ASC') {
        this.sortType = 'DESC';
      } else if (this.sortType === 'DESC') {
        this.sortType = '';
      }
    },
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    sortTableData(column) {
      if (this.sortType === '') {
        return this.domains;
      } else if (this.sortType === 'ASC') {
        return this.domains.sort((a, b) => a[column] > b[column] ? 1 : -1);
      } else if (this.sortType === 'DESC') {
        return this.domains.sort((a, b) => a[column] < b[column] ? 1 : -1);
      }
    }
  }
};
</script>

<template>
  <div>
    <el-row
      v-if="domainHistory.length>0"
      class="px-4"
    >
      <el-col
        v-if="domainHistory.length>0"
        :span="24"
      >
        <div class="table table-dns pt-0 px-4">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">NOMBRE</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">ASCII NAME</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">SUBDOMINIO</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">ESTADO</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">PLAN</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">WEB</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">DNS</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">CORREO</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">FECHA INICIO</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">FECHA FIN</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">ACTUALIZADO</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="domain in domainHistory"
                :key="domain.id"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ domain.name }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ domain.ascii_name }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">
                      <font-awesome-icon
                        v-if="domain.subdomain"
                        style="color: green"
                        icon="check-circle"
                      />
                      <font-awesome-icon
                        v-else
                        style="color: red"
                        icon="times-circle"
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      v-if="domain.status === 'active'"
                      style="color: green"
                      icon="check-circle"
                    />
                    <font-awesome-icon
                      v-else-if="domain.status === 'disabled'"
                      style="color: red"
                      icon="times-circle"
                    />
                    <font-awesome-icon
                      v-else-if="domain.status === 'suspended'"
                      style="color: orange"
                      icon="exclamation-circle"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ domain.planName }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      v-if="domain.ipActive"
                      style="color: green"
                      icon="check-circle"
                    />
                    <font-awesome-icon
                      v-else
                      style="color: red"
                      icon="times-circle"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      v-if="domain.dnsInServer"
                      style="color: green"
                      icon="check-circle"
                    />
                    <font-awesome-icon
                      v-else
                      style="color: red"
                      icon="times-circle"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      v-if="domain.mxActive"
                      style="color: green"
                      icon="check-circle"
                    />
                    <font-awesome-icon
                      v-else
                      style="color: red"
                      icon="times-circle"
                    />
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(domain.dateStart) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(domain.dateEnd) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(domain.lastUpdate) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">

.table-dns {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    tbody {
      td {
        letter-spacing: -0.05em;

        font-weight: bolder;

        letter-spacing: -0.05em;
        font-size: 0.9em;

        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
