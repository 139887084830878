<template>
  <form-project
    v-if="showFormProject"
    :project="project"
    @change="updateObservation"
  />
</template>
<script>
import FormProject from "./FormProject.vue";
import ProjectsService from '@/api/services/projects.service';
import Notification from '@/utils/plugins/NotificationService';
//import moment from 'moment';
export default {
    components:{FormProject},
    data(){
        return {
            showFormProject: false,
            project:{
                name:'',
                date_ini:'',
                client:{
                    id:'',
                    name:''
                },
                nro_horas:'',
                projectType:{
                    id:'',
                    name:''
                },
                projectManager:'',
                clockifyProject:'',
                consumedTime:'',
                contractedTime:'',
                tag:'',
                initialDate:'',
                lastSynchronization:'',
                observations:''
            },
            
            
        }
    },
    mounted(){
        this.getProject(this.$route.params.id );
    },
    methods:{
        getProject(id){
            const $this = this;
            ProjectsService.getProject(id).then((result) => {
               
                $this.project = result.data;
                this.showFormProject = true
               
                //moment.locale('es');
                //$this.project.lastSynchronization = moment($this.project.lastSynchronization,"yyyy-MM-DDTHH:mm:ssZ").format("yyyy-MM-dd HH:mm:ss");
            }).catch(err => {
                console.error("Types",err);
               Notification.addNotification('Error al obtener los campos del proyecto seleccionado.', 'error', 6000);
            this.$store.state.loading = false;
            this.showFormProject = false
            });
        },
        updateObservation(obj){
           
            ProjectsService.udpdateObservations({observations:obj.observations,tag:obj.tag},this.$route.params.id).then(() => {
                Notification.addNotification('Observación actualizada correctamente', 'success', 6000);
               
                
            }).catch(err => {
                console.error("Types",err);
               Notification.addNotification('Error al intentar modificar el proyecto seleccionado.', 'error', 6000);
            this.$store.state.loading = false;
            });
        }
    }
}
</script>