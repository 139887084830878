import Api from '@/api/Api';
import axios from 'axios';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_ABSENCE_SUPPORTING_DOCUMENT = '/absence_supporting_documents';

export default {

    upload() {

    },
    // uploadAbsenceSupportingDocument(item, file) {
    //     console.log(item, file);
    //     let formData = new FormData();
    //     const token = localStorage.getItem('token');
    //     formData.append(file,name);
    //     return axios.post(process.env.VUE_APP_API_URI + RESOURCE_ABSENCE_SUPPORTING_DOCUMENT, formData, {
    //     // return axios.post('http://intranet.appdev/api/absence_supporting_documents', formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             // 'Authorization': 'Bearer ' + token,
    //             'Accept': 'application/ld+json' },
    //         errorHandle: false
    //
    //     })
    // },
    // uploadDocumentFile(item, file) {
    //     let formData = new FormData();
    //     const token = localStorage.getItem('token');
    //     formData.append('file', file);
    //
    //     return axios.post(process.env.VUE_APP_API_URI + RESOURCE_ABSENCE_SUPPORTING_DOCUMENT, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             // 'Authorization': 'Bearer ' + token,
    //             'Accept': 'application/ld+json' },
    //         errorHandle: false
    //     })
    // },
    uploadDocumentFile(file) {
        let formData = new FormData();
        const token = localStorage.getItem('token');
        formData.append('file', file);

        return axios.post(process.env.VUE_APP_API_URI + RESOURCE_ABSENCE_SUPPORTING_DOCUMENT, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/ld+json' },
            errorHandle: false
        })
    },
    getAbsenceAbsenceSupportingDocument(id) {
        return Api()
            .get(id)
            .catch((err) => errorHandler(err, null))
    },
    deleteAbsenceSupportingDocument(id) {
        const token = localStorage.getItem('token');
        return axios.delete(process.env.VUE_APP_API_URI + RESOURCE_ABSENCE_SUPPORTING_DOCUMENT + `/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .catch((err) => errorHandler(err, null));
    },
}