<template>
  <div>
    <el-table
      v-if="presenceData.length > 0"
      :data="presenceData"
      border
      class="condensed-table"
    >
      <el-table-column width="50">
        <template #default="scope">
          <i
            v-if="scope.row.type === 'office'"
            class="el-icon-thumb"
          />
          <i
            v-else
            class="el-icon-s-home"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="start"
        label="Entrada"
      />
      <el-table-column
        prop="end"
        label="Salida"
      />
    </el-table>
    <span v-else>Sin datos de presencia</span>
  </div>
</template>

<script setup>
import _ from 'lodash'
import {computed, defineProps} from "vue";

const props = defineProps({
  presence: {
    type: Object,
    required: true
  }
})
const presenceData = computed(() => {
  return _.map(_.chunk(_.toArray(props.presence.signings), 2), (data) => {
    return {
      start: data[0].time,
      end: data[1]?.time ?? '-',
      type: data[0].remote ? 'home' : 'office'
    };
  });
})

</script>