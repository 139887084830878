<template>
  <div
    class="dropdown simple-dropdown"
    :class="[color, errorValidation ? 'error-validation' : '']"
  >
    <button
      ref="button"
      :class="{'filled': selectedItem && selectedItem[param] || Array.isArray(selectedItem) && selectedItem.length > 0, 'is-invalid': isInvalid || (!selectedItem && rules && rules.required) }"
      type="button"
      :style="{height: height ? height: 'auto'}"
      class="dropdown-btn btn btn-block text-left btn-light"
      :disabled="disabled"
      @click.stop="openDropdown()"
    >
      {{
        multi && selectedItem && selectedItem.length > 0 ? title + ': ' + selectedItem.length + ' seleccionados'
        : selectedItem && selectedItem[param2] && selectedItem[param2].title ? selectedItem[param] + ' ' +
          selectedItem[param2].title
          : selectedItem && selectedItem[param2] ? selectedItem[param] + ' ' + selectedItem[param2] : selectedItem &&
            selectedItem[param] ? selectedItem[param] : title ? title : 'Selecciona un elemento'
      }}
      <div class="icons">
        <font-awesome-icon
          v-if="!multi && removable && selectedItem"
          class="icon remove-element"
          icon="times"
          @click.stop="removeItem()"
        />
        <font-awesome-icon
          v-if="!open"
          class="icon"
          icon="caret-down"
        />
        <font-awesome-icon
          v-if="open"
          class="icon"
          icon="caret-up"
        />
      </div>
    </button>
    <div
      v-if="open"
      ref="dropdown"
      class="dropdown-content"
    >
      <div
        v-if="searchable"
        class="search"
      >
        <SharedInput
          v-model="searchStr"
          class="search-input"
          :color="'light'"
          :param="label"
          :param2="label"
          :placeholder="'Buscar...'"
          @change="searchItems(searchStr)"
        />
      </div>
      <ul v-if="filteredItems.length > 0">
        <li
          v-for="item in filteredItems"
          :key="item.id"
          :class="{'selected': checkIfIsSelected(item), 'empty': filter && filter !== item[param2] && filter !== item[param2].title}"
          @click="selectItem(item)"
        >
          <span v-if="!filter || (filter === item[param2] || filter === item[param2].title)">{{
            item[param]
          }} {{ item[param2] && item[param2].title ? item[param2].title : item[param2] }}</span>
        </li>
      </ul>
      <ul v-else>
        <li
          v-for="item in items"
          :key="item.id"
          :class="{'selected': checkIfIsSelected(item), 'empty': filter && filter !== item[param2] && filter !== item[param2].title}"
          @click="selectItem(item)"
        >
          <span v-if="!filter || (filter === item[param2] || filter === item[param2].title)">{{
            item[param]
          }} {{ item[param2] && item[param2].title ? item[param2].title : item[param2] }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


import SharedInput from '@/components/shared/SharedInput.vue'
import _ from 'lodash'

export default {
  name: 'SimpleDropdown',
  props: {
    items: {default: []},
    param: {type: String, default: 'name'},
    param2: {type: String, default: null},
    label: {type: String, default: 'dropdownLabel'},
    filter: {type: String, default: null},
    removable: {type: Boolean, default: true},
    searchable: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    rules: {type: Object, default: null},
    title: String,
    color: String,
    selected: {type: [Object, Array]},
    isInvalid: Boolean,
    height: null,
    multi: {type: Boolean, default: false},
    errorValidation: {type: Boolean, default: false},
     class_div_col_label: {
      type: String,
      default: 'col-md-4'
    },
    class_div_col_input: {
      type: String,
      default: 'col-md-8'
    },
  },
  data() {
    return {
      open: false,
      selectedItem: null,
      loading: false,
      searchStr: '',
      filteredItems: []
    }
  },
  methods: {
    selectItem(item, emitEvent = true) {
      if (!this.multi) {
        if (this.selectedItem && this.selectedItem.id === item.id && this.removable) {
          this.selectedItem = null
        } else {
          this.selectedItem = item
        }
        this.open = false;
        // this.appendToComponent();
        if (emitEvent)
          this.$emit('selectItem', this.selectedItem)
      } else {
        if (this.checkIfIsInArray(this.selectedItem, item, 'id')) {
          this.selectedItem.splice(this.getIndexInArray(this.selectedItem, item, 'id'), 1);
        } else {
          this.selectedItem.push(item);
        }
        if (emitEvent)
          this.$emit('selectItem', this.selectedItem)
      }
    },
    openDropdown() {
      this.open = !this.open;
      if (this.open) {
        setTimeout(() => {
          // this.appendDropdownBody()
        }, 100)
        setTimeout(() => {
          if (this.searchable) {
            if (this.$el.querySelector("#" + this.label)) {
              this.$el.querySelector("#" + this.label).focus()
            }
          }
          if (!this.multi && this.searchable && this.selectedItem) {
            this.scrollToElement();
          }
        }, 500)
      } else {
        // this.appendToComponent()
        this.selectedItem = this.selected;
      }
    },
    closeDropdown() {
      this.open = false;
    },
    removeItem() {
      this.selectedItem = null;
      this.$emit('selectItem', this.selectedItem);
    },
    scrollToElement() {
      if (this.$el.querySelector(".selected")) {
        this.$el.querySelector(".selected").scrollIntoView({block: 'center', behavior: 'smooth'})
      }
    },
    searchItems(str) {
      this.debounceSearch(() => {
        //this.$emit('search', str)

        this.filteredItems = this.items.filter(o => o.name.toLowerCase().includes(str.toLowerCase()));
      })
    },
    debounceSearch: _.debounce((callback) => {
      callback()
    }, 500),
    selectNextItem(item) {
      if (item && this.items.length > 1) {
        let index = -1
        this.items.forEach((i, n) => {
          if (item[this.param] === i[this.param]) {
            index = n
          }
        })
        if (index !== this.items.length - 1) {
          this.selectedItem = this.items[index + 1]
        }
        if (this.selectedItem) {
          setTimeout(() => {
            this.scrollToElement();
          }, 300)
        }
      } else {
        this.selectedItem = this.items[0]
      }
    },
    selectPrevItem(item) {
      if (item && this.items.length > 1) {
        let index = 0
        this.items.forEach((i, n) => {
          if (item[this.param] === i[this.param]) {
            index = n
          }
        })
        if (index !== 0) {
          this.selectedItem = this.items[index - 1]
        }
        if (this.selectedItem) {
          setTimeout(() => {
            this.scrollToElement();
          }, 300)
        }
      } else {
        this.selectedItem = this.items[this.items.length - 1]
      }
    },
    checkIfIsSelected(item) {
      if (!this.multi) {
        if (this.selectedItem && item.id === this.selectedItem.id) {
          return true
        }
      } else {
        if (this.checkIfIsInArray(this.selectedItem, item, 'id')) {
          return true
        }
      }
      return false
    },
    checkIfIsInArray(arr, item, param) {
      let result = false
      arr.forEach((elem) => {
        if (elem[param] === item[param]) {
          result = true
        }
      })
      return result
    },
    getIndexInArray(arr, item, param) {
      let index = -1;
      arr.forEach((elem, i) => {
        if (elem[param] === item[param]) {
          index = i
        }
      })
      return index
    },
    appendDropdownBody() {
      const content = this.$el.querySelector('.dropdown-content')
      const parent = this.$el.querySelector('.dropdown-btn')
      const bottom = parent.getBoundingClientRect().bottom + window.scrollY
      const left = parent.getBoundingClientRect().left + window.scrollY
      const width = parent.offsetWidth
      const body = document.querySelector('body')
      content.style.left = left + 'px';
      content.style.top = bottom + 'px';
      content.style.width = width + 'px';
      body.appendChild(content)
    },
    appendToComponent() {
      const content = document.querySelector('.dropdown-content')
      this.$el.appendChild(content)
    }
  },
  watch: {
    selected(value) {
      this.selectedItem = value
    },

    open(value) {
      // Emitimos un evento avisando al componente padre de que el dropdown se ha desplegado...
      if (value)
        this.$emit('sharedDropdownOpened');
    },


  },
  created() {
    let self = this;
    window.addEventListener('click', function (e) {
      if (!self.$el.contains(e.target)) {
        if (self.open) {
          self.open = false;
          self.selectedItem = self.selected;
        }
      }
      e.preventDefault()
      e.stopPropagation()
    })
    window.addEventListener('keydown', function (e) {
      if (event.keyCode === 13) {
        if (self.$el.contains(e.target)) {
          if (self.open) {
            self.open = false;
            // self.appendToComponent();
            self.$emit('selectItem', self.selectedItem)
          } else {
            self.openDropdown();
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    })
    window.addEventListener('keydown', function (e) {
      if (event.keyCode === 40) {
        if (self.$el.contains(e.target) && !self.open) {
          self.openDropdown()
        }
        if (self.$el.contains(e.target) && self.open) {
          self.selectNextItem(self.selectedItem)
          e.preventDefault()
          e.stopPropagation()
        }
      }
    })
    window.addEventListener('keydown', function (e) {
      if (event.keyCode === 38) {
        if (self.$el.contains(e.target) && !self.open) {
          self.openDropdown()
        }
        if (self.$el.contains(e.target) && self.open) {
          self.selectPrevItem(self.selectedItem)
          e.preventDefault()
          e.stopPropagation()
        }
      }
    })
  },
  mounted() {
    if (this.selected) {
      this.selectedItem = this.selected
    }
  },
  components: {
    SharedInput
  },
  filters: {
    uppercase: function (str) {
      return str.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown {
  position: relative;

  button {
    border: solid 1px black;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  &.address {
    button {
      background: $blue-grey-bg;
      //background-color: white;
      height: 30px;
    }
  }

  &.secondary {
    button {
      background: $secondary-color;
      height: 42px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.light {
    button {
      background: $blue-grey-bg;
      //background: white;
      height: 42px;
    }
  }

  &.white {
    button {
      background: $light-bg;
      height: 42px;

      &:hover, &:active, &:focus {
        background: $light-bg;
      }
    }
  }

  &.bg_white {
    button {
      background: $light-bg;
      height: 55px;

      &:hover, &:active, &:focus {
        background: $light-bg;
      }
    }
  }
}

button {
  border: 2px solid rgba(255, 255, 255, 0);
  transition: 0.6s;

  &.filled {
    font-weight: 700 !important;
  }

  &.is-invalid {
    border: 2px solid #dc626f;
  }
}

.remove-element {
  margin-right: 5px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  min-width: 11.33rem;
  height: auto;
  background-color: $light-bg;
  border-radius: 1rem;
  padding: 0.11rem 0px;
  box-shadow: 0 0.22rem 0.77rem rgba(0, 0, 0, .1);
  z-index: 2;

  .search {
    width: 100%;
    display: block;
    padding: 10px;

    .form-item {
      margin-bottom: 0px;

      &.search-input {
        input {
          width: 100%;
        }
      }
    }
  }

  ul {
    overflow: auto;
    display: block;
    height: auto;
    max-height: 12.66rem;
    border-radius: 1em;

    li {
      width: 100%;
      display: block;
      padding: 0.33rem 1rem;
      border-radius: 1em;
      border-bottom: 1px solid $light-grey-bg;
      transition: 0.6s;
      cursor: pointer;
      text-align: left;
      color: black;

      &.selected {
        background: $light-grey-bg;
      }

      &.empty {
        padding: 0;
      }
    }
  }
}

.error-validation {
  border: 2px solid #F56C6C;
}

</style>

<style lang="scss">

.dropdown-content {
  .search {
    .form-item {
      &.search-input {
        input {
          width: 100%;
        }
      }
    }
  }
}

</style>
