import Api from '@/api/Api';
//import axios from 'axios';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/public_holidays';

export default {

    // PUBLIC HOLIDAYS
    getPublicHolidays(params) {
        return Api().get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getPublicHoliday(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createPublicHoliday(item) {
        return Api().post(RESOURCE_NAME, item)
            .catch((err) => errorHandler(err, null));
    },
    updatePublicHoliday(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deletePublicHoliday(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    }
}
