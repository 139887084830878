<template>
  <div class="container m-0 p-0 mt-3">
    <img
      src="@/assets/icons/message.svg"
      alt=""
    >
    <div class="title-text ml-4 d-inline-block">
      {{ title }}
    </div>
    <div class="ticket-messages">
      <SharedMessage
        v-for="message in messages"
        :key="message.id"
        :message="message"
      />
      <SharedCommentForm
        :is-private="isPrivate"
        :ticket="ticket"
        title=""
        :placeholder="placeholder"
        @refresh="refreshTicket"
      />
    </div>
  </div>
</template>

<script>
import SharedMessage from "@/components/shared/SharedMessage.vue";
import SharedCommentForm from "@/components/shared/SharedCommentForm.vue";
import TicketService from "@/api/services/ticket.service";

export default {
  name: "SharedTicketComments",
  components: {SharedCommentForm, SharedMessage},
  props: {
    title: {
      type: String,
      required: true
    },
    placeholder:{
      type:String,
    },
    isPrivate: {
      type: Boolean,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      messages: [],
       formModel: {
         comment: null,
         ticket: this.ticket['@id'],
         authorEmployee: '/api/employees/'+this.$store.state.user.id,
         authorExtranetUser: null,
         private: this.private
       }
    }
  },
  mounted() {
    this.messages = this.ticket ? this.ticket.comments.filter(item=> {
      return  item.private === this.isPrivate
    }) : []
  },
  methods:{
    refreshTicket(){
      TicketService.getTicket(this.ticket.id).then((res)=> {
          this.messages = res.data.comments.filter(item=> {
            return  item.private === this.isPrivate
          })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
  .ticket-messages {
    border: 1px solid;
    border-radius: 25px;
    background-color: white;
    padding: 15px;
    margin-top: 25px;
  }
</style>