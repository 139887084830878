export default {
  notifications: [],
  addNotification(msg, type, time) {
    let notification = {
      message: msg ? msg : 'Alerta',
      type: type ? type : 'warning',
      icon: this.getIconByType(type ? type : 'warning'),
      timeout: setTimeout(() => {
        let index = this.notifications.indexOf(notification)
        this.notifications.splice(index, 1)
      }, time) 
    }
    this.notifications.push(notification)
  },
  removeNotification(index){
    let notification = this.notifications[index]
    clearTimeout(notification.timeout)
    this.notifications.splice(index, 1)
  },
  getIconByType(type) {
    switch (type) {
      case 'warning':
        return 'exclamation-triangle'
      case 'info':
        return 'bell'
      case 'success':
        return 'check-circle'
      case 'error':
        return 'ban'
      default:
        return 'exclamation-triangle'
    }
  }
}