
export default {

    COLORS: {
        'ABIERTO': '#F9156F',
        'EN CURSO': '#4AABC3',
        'CERRADO': '#919191',
    },

    getColor: function(state) {
        return this.COLORS[state] ?? '#000000';
    }
}