<template>
  <div class="d-flex flex-row background">
    <div
      id="change-password-new"
      class="animated fadeIn fast"
    >
      <div>
        <img
          class="logo-connectus mb-3"
          src="../../assets/images/logo_ConnectUs_png_25.png"
          alt=""
        >
      </div>
      <div>
        <img
          class="mb-4"
          src="../../assets/images/dots.png"
          alt=""
        >
      </div>
      <form
        id="change-password-form"
        name="change-password-form"
        @submit.prevent="submit"
      >
        <div class="form-item">
          <input
            id="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            data-vv-as="contraseña"
            class="login"
            placeholder="Nueva contraseña"
            :class="{'is-invalid': submitted && errors.has('password')}"
            @keyup.enter="submit"
          >
          <font-awesome-icon
            class="eye pointer"
            :icon="showPassword ? 'eye-slash' : 'eye'"
            @click="showPassword = !showPassword"
          />
          <div
            v-if="submitted && errors.has('password')"
            class="animated fadeIn invalid-feedback"
          >
            <font-awesome-icon
              class="icon"
              icon="info-circle"
            />
            {{ errors.first('password') }}
          </div>
        </div>

        <div class="form-item">
          <input
            id="repeatPassword"
            v-model="repeatPassword"
            :type="showRepeatPassword ? 'text' : 'password'"
            name="repeatPassword"
            data-vv-as="contraseña"
            class="login"
            placeholder="Confirmar contraseña"
            :class="{'is-invalid': submitted && errors.has('repeatPassword')} && !samePassword(password, repeatPassword)"
            @keyup.enter="submit"
          >
          <font-awesome-icon
            class="eye pointer"
            :icon="showRepeatPassword ? 'eye-slash' : 'eye'"
            @click="showRepeatPassword = !showRepeatPassword"
          />
          <div
            v-if="submitted && errors.has('repeatPassword')"
            class="animated fadeIn invalid-feedback"
          >
            <font-awesome-icon
              class="icon"
              icon="info-circle"
            />
            {{ errors.first('repeatPassword') }}
          </div>
        </div>
        <button
          class="btn btn-block btn_yellow btn__login"
          type="button"
          @keyup.enter="submit"
          @click="submit"
        >
          ACEPTAR
        </button>
      </form>
    </div>
  </div>
 
  <!-- <div id="change-password" class="animated fadeIn fast">
        <div class="text-center">
            <h1>Cambio de contraseña</h1>
        </div>
        <br>
        <form id="change-password-form" name="change-password-form" @submit.prevent="submit">
            <div class="form-item">
                <input
                        :type="showPassword ? 'text' : 'password'"
                        id="password"
                        name="password"
                        data-vv-as="contraseña"
                        class="bg-light"
                        placeholder="Nueva contraseña"
                        v-model="password"
                        @keyup.enter="submit"
                        v-validate="'required'"
                        :class="{'is-invalid': submitted && errors.has('password')}">
                <font-awesome-icon class="eye pointer" @click="showPassword = !showPassword"
                                   :icon="showPassword ? 'eye-slash' : 'eye'"/>
                <div v-if="submitted && errors.has('password')" class="animated fadeIn invalid-feedback">
                    <font-awesome-icon class="icon" icon="info-circle"/>
                    {{ errors.first('password') }}
                </div>
            </div>

            <div class="form-item">
                <input
                        :type="showRepeatPassword ? 'text' : 'password'"
                        id="repeatPassword"
                        name="repeatPassword"
                        data-vv-as="contraseña"
                        class="bg-light"
                        placeholder="confirmar contraseña"
                        v-model="repeatPassword"
                        @keyup.enter="submit"
                        v-validate="'required'"
                        :class="{'is-invalid': submitted && errors.has('repeatPassword')} && !samePassword(password, repeatPassword)">
                <font-awesome-icon class="eye pointer" @click="showRepeatPassword = !showRepeatPassword"
                                   :icon="showRepeatPassword ? 'eye-slash' : 'eye'"/>
                <div v-if="submitted && errors.has('repeatPassword')" class="animated fadeIn invalid-feedback">
                    <font-awesome-icon class="icon" icon="info-circle"/>
                    {{ errors.first('repeatPassword') }}
                </div>
            </div>
            <button class="btn_blue pointer" type="button" @keyup.enter="submit" @click="submit">Entrar</button>
        </form>
              <div class="logo">
                <img src="@/assets/images/logo-undanet.png" alt="logo_undanet"/>-->
  <!--        </div>-->
  <!--</div> -->
</template>


<script>

    // Component Dependencies
    import {mapState} from 'vuex'

    // Services
    import Notification from '@/utils/plugins/NotificationService'


    //Components
    //import SharedSpinner from '@/components/shared/SharedSpinner.vue'
   // import SharedNotification from '@/components/shared/SharedNotification.vue'

    // Authentication
    import auth from '@/api/auth/auth'

    export default {
        name: "ChangePassword",
        data() {
            return {
                authenticated: false,
                password: '',
                repeatPassword: '',
                showPassword: false,
                showRepeatPassword: false,
                submitted: false,
            }
        },
        computed: {
            ...mapState({
                userLogged: 'user',
                loading: 'loading',
            })
        },
        watch: {
            '$route'(to, from) {
                if (from.name === 'login') {
                    this.initDataApplication();
                }
            }
        },
        mounted() {
            this.$store.state.loading = false;
            this.initDataApplication();
            this.authenticated = auth.checkAuth()
        },
        updated() {
            this.authenticated = auth.checkAuth()
        },
        methods: {
            initDataApplication() {
                // console.log('initDataApplication', this.$route.name)
                // console.log('User logged', this.$store.getters.getUserLogged)
                if (this.$route.name !== 'login') {
                    this.$store.dispatch('beforeCreate')
                    if (!this.$store.getters.getUserLogged) {
                        auth.logout();
                    }
                }
            },
            submit() {
                this.submitted = true;

                // if (this.password.length < 8 || this.repeatPassword.length < 8) {
                //
                // } else if (this.password != this.repeatPassword) {
                //
                // }
                setTimeout(() => {
                    // this.$validator.validateAll()
                    //     .then(valid => {
                    //         if (valid) {
                    //             console.log();
                    //         }
                    //     })

                 /*   this.$validator.validateAll()
                        .then(() => {
                            if (this.errors.items.length > 0) {
                                Notification.addNotification('No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos', 'warning', 6000);
                                return;
                            }*/
                            if (!this.samePassword(this.password, this.repeatPassword)) {
                                Notification.addNotification('Las contraseñas deben ser iguales', 'warning', 6000);
                                return;
                            }
                            this.updatePassword();
                         //   this.$validator.reset();
                      //  })
                }, 100);

            },
            samePassword(password, repeatPassword) {
                if (password != repeatPassword) {
                    return false;
                } else {
                    return true;
                }
            },
          async  updatePassword() {
                this.$store.state.loading = true;
                let user = {
                    id: this.userLogged.id,
                    password: this.password,
                    repeatPassword: this.repeatPassword,
                };
               await auth.changePassword(user)
                    .then(() => {
                        Notification.addNotification('Contraseña actualizada correctamente', 'success', 6000);
                        this.$store.state.loading = false;
                    })
                    .then(() => {
                        auth.logout();
                    })
                    .catch(() => {
                        Notification.addNotification('Error al actualizar la contraseña', 'error', 6000);
                        this.$store.state.loading = false;
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
.logo-connectus{
   filter: brightness(1);
}
.login {
  background: transparent;
  padding: 0;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid white;
  color: white;

  &:focus{
    background:transparent;
    color:white;
    padding: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid white;
  }

  &::placeholder {
    color: white;
  }
}
.u-icon{
  width: max-content
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #474ce6 inset;
}
.background{

  background: #474ce6;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
  margin: auto;
  top: 0;
  left: 0;
}

#change-password-new {
  position: relative;
  max-width: 31.25rem;
  width: 100%;
  height: 45%;
  /* background-color: rgba(255,255,255,0.2); */
  padding: 4rem;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 5%;

  h1 {
    font-family: $font-title;
    font-weight: 800;
    color: #FFFFFF;
  }

  h3 {
    font-weight: 300;
  }

  .logo {
    position: absolute;
    bottom: 4.44rem;
    left: 0px;
    right: 0px;
    margin: auto;

    img {
      width: 60%;
    }
  }

  .btn__login {
    margin-top: 1.5em;
    height: 3em;
    border-radius: 30px;
  }

}



    #change-password {
        position: relative;
        max-width: 37.00rem;
        width: 90%;
        height: 100%;
        /*background: #fbc600; */
        background: #4167D5;
        padding: 4rem;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1 {
            font-family: $font-title;
            font-weight: 800;
            color: #FFFFFF;
        }

        h3 {
            font-weight: 300;
        }

        .logo {
            position: absolute;
            bottom: 4.44rem;
            left: 0px;
            right: 0px;
            margin: auto;

            img {
                width: 60%;
            }
        }
    }

    @media screen and (max-width: 380px) {
        #change-password {
            padding: 2rem;
        }
    }

    @media screen and (max-height: 480px) {
        .logo {
            display: none;
        }
    }

</style>