<script setup>

</script>

<template>
    <div>
        <h1>TrackerNet * Infome</h1>
    </div>
</template>

<style
    scoped
    lang="scss">

</style>
