<template>
  <div
      id="header"
      class="header"
  >
    <!--  <div id="home" class="vertical text-center animated fadeIn fast">-->
    <div id="grad"/>
    <div id="content">
      <div class="d-flex align-items-end justify-content-between">
        <div class="pr-5">
          <h1>
            {{ title }}
            <svg
                id="asterisk"
                xmlns="http://www.w3.org/2000/svg"
                width="724"
                height="1024"
                viewBox="0 0 724 1024"
            >
              <path
                  d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
              />
            </svg>
          </h1>
          <h2>{{ formatDate(serverTimestamp) }}</h2>
        </div>
        <div class="ml-auto">
          <shared-presence/>
        </div>
      </div>

      <div
          v-if="showCard"
          class="d-flex justify-content-center align-items-center my-4"
          style="flex: 1;"
      >
        <div class="header-icon">
          <a
              :href="'https://app.clockify.me/'"
              target="_blank"
              @click="openLinkInNewTab('https://app.clockify.me/')"
          >
            <!--          <a href="javascript:void(0)" @click="openLinkInNewTab('https://app.clockify.me/')">-->
            <!--          <a href="https://app.clockify.me/" target="_blank" rel="noreferrer noopener">-->
            <el-card class="box-card d-flex justify-content-center pointer">
              <svg
                  id="coclify-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
              >
                <path d="M11.352,11.921c0,0.539,0.214,1.028,0.562,1.385c0.346,0.355,0.829,0.576,1.364,0.577c0.535-0.001,1.017-0.222,1.364-0.577c0.362-0.373,0.563-0.869,0.563-1.385c0-1.082-0.864-1.96-1.928-1.96C12.214,9.961,11.352,10.839,11.352,11.921z"/>
                <polygon points="22.5 19.578 17.064 14.065 15.506 15.642 20.944 21.157"/>
                <path d="M1.5,12c0,6.627,5.298,12,11.835,12c0.003,0,0.005,0,0.007,0c2.113,0,4.095-0.563,5.747-1.517l-2.656-2.695l0.053-0.02c-0.968,0.409-2.033,0.635-3.15,0.635h-0.001c-4.577,0-8.287-3.78-8.287-8.44c0-4.66,3.71-8.44,8.287-8.44c1.115,0.001,2.178,0.226,3.092,0.613l2.611-2.65C17.347,0.54,15.403,0,13.335,0C6.799,0,1.5,5.373,1.5,12z"/>
                <polygon points="15.48 8.248 17.038 9.825 22.476 4.311 20.919 2.734 15.481 8.248"/>
              </svg>
              <h2>Clockify</h2>
            </el-card>
          </a>
        </div>
        <div class="header-icon">
          <a
              :href="'https://gmail.com/'"
              target="_blank"
              @click="openLinkInNewTab('https://gmail.com/')"
          >
            <!--          <a href="https://gmail.com/" target="_blank" rel="noreferrer noopener">-->
            <el-card class="box-card d-flex justify-content-center pointer">

              <svg
                  id="gmail-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
              >
                <path d="M14.5 2h-13A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm-1.766 1L8 6.738 3.266 3h9.468zM2 13V4.646l6 4.615 6-4.616V13H2z"/>
              </svg>

              <h2>Correo</h2>
            </el-card>
          </a>
        </div>
        <div class="header-icon">
          <a
              :href="'https://drive.google.com'"
              target="_blank"
              @click="openLinkInNewTab('https://drive.google.com')"
          >
            <!--          <a href="https://drive.google.com" target="_blank" rel="noreferrer noopener">-->
            <el-card class="box-card d-flex justify-content-center pointer">
              <svg
                  id="drive-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
              >
                <path d="m15.467 10-4.8-9H5.333l4.8 9zM4.747 2.026 0 10.333 2.667 15l4.655-8.146zM6.104 11l-2.286 4H13.5l2.5-4z"/>
              </svg>


              <!--              <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
                                   :src="require(`@/assets/icons/inclined-paper-plane.svg`)"/>-->
              <h2>Drive</h2>


            </el-card>
          </a>
        </div>
        <div class="header-icon">
          <a
              :href="'https://undanet.slack.com'"
              target="_blank"
              @click="openLinkInNewTab('https://undanet.slack.com')"
          >
            <!--          <a href="https://undanet.slack.com" target="_blank" rel="noreferrer noopener">-->
            <el-card class="box-card d-flex justify-content-center pointer">
              <svg
                  id="slack-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
              >
                <path d="M2,14.67a2,2,0,1,0,4,0v-2H4A2,2,0,0,0,2,14.67Zm12.64-3.34a2,2,0,0,0,2-2V4a2,2,0,1,0-4,0V9.33A2,2,0,0,0,14.66,11.33Zm7.32-2a2,2,0,1,0-4,0v2h2A2,2,0,0,0,22,9.33ZM9.34,12.67a2,2,0,0,0-2,2V20a2,2,0,1,0,4,0V14.67A2,2,0,0,0,9.34,12.67ZM14.66,18h-2v2a2,2,0,1,0,2-2ZM20,12.67H14.66a2,2,0,0,0,0,4H20a2,2,0,0,0,0-4ZM9.34,7.33H4a2,2,0,1,0,0,4H9.34a2,2,0,0,0,0-4ZM9.34,2a2,2,0,0,0,0,4h2V4A2,2,0,0,0,9.34,2Z"/>
              </svg>
              <h2>Slack</h2>
            </el-card>
          </a>
        </div>
        <div class="header-icon">
          <a
              :href="'https://web.skype.com/'"
              target="_blank"
              @click="openLinkInNewTab('https://web.skype.com/')"
          >
            <!--          <a href="https://web.skype.com/" target="_blank" rel="noreferrer noopener">-->
            <el-card class="box-card d-flex justify-content-center pointer">
              <svg
                  id="skype-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
              >
                <path
                    d="M21.43451,14.15552a9.586,9.586,0,0,0,.21155-2.02631,9.47653,9.47653,0,0,0-9.54029-9.42254,9.11414,9.11414,0,0,0-1.62494.14178A5.53558,5.53558,0,0,0,2.00061,7.466a5.42884,5.42884,0,0,0,.75354,2.7558,10.0197,10.0197,0,0,0-.18866,1.88452,9.33889,9.33889,0,0,0,9.54028,9.25788,8.56663,8.56663,0,0,0,1.74268-.16584A5.57942,5.57942,0,0,0,16.46368,22a5.433,5.433,0,0,0,4.97083-7.84448ZM16.43964,15.9931a3.63146,3.63146,0,0,1-1.62488,1.22473,6.33963,6.33963,0,0,1-2.52033.44709,6.21683,6.21683,0,0,1-2.89764-.61176,3.733,3.733,0,0,1-1.31964-1.17779,2.574,2.574,0,0,1-.494-1.41339.88041.88041,0,0,1,.30646-.68384,1.09008,1.09008,0,0,1,.77643-.28247.94433.94433,0,0,1,.637.2127,1.793,1.793,0,0,1,.44708.65863,3.39792,3.39792,0,0,0,.49518.87256,1.78964,1.78964,0,0,0,.72955.56488,3.01435,3.01435,0,0,0,1.24872.23554,2.9217,2.9217,0,0,0,1.71985-.44708,1.33217,1.33217,0,0,0,.65979-1.13092,1.135,1.135,0,0,0-.35333-.87134,2.18491,2.18491,0,0,0-.91944-.51923c-.37616-.11774-.89538-.23553-1.53112-.37616a13.9908,13.9908,0,0,1-2.14295-.6358,3.34814,3.34814,0,0,1-1.36651-1.01312,2.47429,2.47429,0,0,1-.49512-1.57807,2.62945,2.62945,0,0,1,.54205-1.60205,3.41154,3.41154,0,0,1,1.53113-1.084,6.652,6.652,0,0,1,2.37964-.37623,6.403,6.403,0,0,1,1.88452.25843,4.07215,4.07215,0,0,1,1.31842.65979,2.91587,2.91587,0,0,1,.77765.87134,1.80281,1.80281,0,0,1,.23553.87139.96189.96189,0,0,1-.30645.70667.9912.9912,0,0,1-.7536.30646.9735.9735,0,0,1-.63575-.18866,2.38218,2.38218,0,0,1-.47113-.61176,2.93711,2.93711,0,0,0-.77758-.96631A2.37614,2.37614,0,0,0,12.0589,7.96a2.7028,2.7028,0,0,0-1.5083.37738,1.07558,1.07558,0,0,0-.56488.89539.95783.95783,0,0,0,.18866.56488,1.41851,1.41851,0,0,0,.54205.4002,2.69264,2.69264,0,0,0,.68262.25959c.23559.07092.613.16467,1.15381.28247.65979.14178,1.27276.30646,1.81475.47107a5.43,5.43,0,0,1,1.38941.6358,2.85691,2.85691,0,0,1,.89532.94226,2.8284,2.8284,0,0,1,.32935,1.3905A2.89029,2.89029,0,0,1,16.43964,15.9931Z"/>
              </svg>
              <h2>Skype</h2>
            </el-card>
          </a>
        </div>
      </div>
    </div>
    <shared-modal
        v-if="showmodal"
        :title="'Fichaje desde Presencia'"
        :theme="'secondary'"
        :size="'small'"
        @close="closeSignModal()"
    >
      <template #content>
        <br>
        <div>
          <p>
            <font-awesome-icon icon="exclamation-triangle"/>
            Confirma que estas fuera de la oficina
          </p>

          <div class="row item-modal">
            <div class="col-sm-6">
              <button
                  class="btn btn-block btn_red"
                  type="button"
                  @click="closeSignModal()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-6">
              <button
                  class="btn btn_blue"
                  type="button"
                  @click="sign_button = !sign_button; closeSignModal()"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </template>
    </shared-modal>
  </div>
</template>

<script>

import CommonService from '@/api/services/common.service';

import moment from "moment";
import SharedModal from "@/components/shared/SharedModal";
import SharedPresence from "@/components/shared/SharedPresence";
import {mapState} from "vuex";
import {ElNotification} from "element-plus";


export default {
  name: "Header",
  props: {
    title: {
      type: String,
      default: ""
    },
    showCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sign_button: true,
      serverTimestamp: null,
      showmodal: false,
    }
  },
  mounted() {
    /* this.$router.currentRoute */

    this.getServerTimeStamp();
    if (this.user && this.user.id) {
      this.getPresenceData(this.user.id);
    } else {
      ElNotification({
        type: 'error',
        message: 'Identificador del empleado incorrect',
        duration: 6000
      })
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  components: {
    SharedModal,
    SharedPresence

  },
  methods: {
    getServerTimeStamp() {
      CommonService.getServerTimestamp().then((resTimestamp) => {
        this.serverTimestamp = resTimestamp.data.serverTimestamp;
      }).catch(() => {
        ElNotification({
          type: 'error',
          message: 'Error al obtener el timestamp del servidor',
          duration: 6000
        })
      });
    },
    signing() {

    },
    getPresenceData(employeeID) {
      console.log(employeeID);
    },
    showSignModal() {
      this.showmodal = !this.showmodal
    },

    closeSignModal() {
      this.showmodal = false
    },
    openLinkInNewTab(link) {
      window.open(link, '_blank');
    },
    formatDate: function (date) {
      if (date) {
        moment.locale('es');

        return moment(date).format('DD') + ' de ' + moment(date).format('MMMM');
      }
      return '';
    },
    formatTimeStyle: function (value) {
      console.log(value);
      if (value) {
        let hours = value.hours().toString().length === 1 ? '0' + value.hours().toString() : value.hours();
        let minutes = value.minutes().toString().length === 1 ? '0' + value.minutes().toString() : value.minutes();

        return hours + 'h:' + minutes + 'm';

      }
      return '';
    },
  },
}
</script>

<style
    lang="scss"
    scoped>
@import "../../assets/sass/styles.scss";

#gmail-icon {
  width: 80px;
  fill: $bg-blue;
}

#coclify-icon, #drive-icon, #slack-icon, #skype-icon {
  width: 80px;
  fill: $bg-blue;
}

#chronometer-head {
  fill: $yellow-color;
  width: 55px;
  margin-right: 5px;

}

.text-presence {
  font-size: 4em;
  font-weight: 900;
  letter-spacing: -0.05em;
}

.btn-action {
  width: 20em;
  height: 3em;
  font-weight: bold;
}

#header {
  color: white;
  margin-top: -280px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  #grad {
    background: rgba(114, 119, 245, 1);
    background: -moz-linear-gradient(left, rgba(114, 119, 245, 1) 0%, rgba(109, 111, 244, 1) 12%, rgba(83, 88, 235, 1) 75%, rgba(71, 77, 230, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(114, 119, 245, 1)), color-stop(12%, rgba(109, 111, 244, 1)), color-stop(75%, rgba(83, 88, 235, 1)), color-stop(100%, rgba(71, 77, 230, 1)));
    background: -webkit-linear-gradient(left, rgba(114, 119, 245, 1) 0%, rgba(109, 111, 244, 1) 12%, rgba(83, 88, 235, 1) 75%, rgba(71, 77, 230, 1) 100%);
    background: -o-linear-gradient(left, rgba(114, 119, 245, 1) 0%, rgba(109, 111, 244, 1) 12%, rgba(83, 88, 235, 1) 75%, rgba(71, 77, 230, 1) 100%);
    background: -ms-linear-gradient(left, rgba(114, 119, 245, 1) 0%, rgba(109, 111, 244, 1) 12%, rgba(83, 88, 235, 1) 75%, rgba(71, 77, 230, 1) 100%);
    background: linear-gradient(to right, rgba(114, 119, 245, 1) 0%, rgba(109, 111, 244, 1) 12%, rgba(83, 88, 235, 1) 75%, rgba(71, 77, 230, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7277f5', endColorstr='#474de6', GradientType=1);
    height: 310px;
    margin-top: 200px;
    margin-left: -190px;
    margin-right: -80px;
    //width: 1200px;
  }

  #content {
    margin-top: -220px;

    .header-icon {
      flex: 1;
      padding: 0 15px;
      margin-right: 15px;
    }
  }

  h1 {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 0.5em;
    fill: $yellow-color;
  }

  #chronometer, #islands, #plane, #cursor {
    fill: $bg-blue;
    width: 45px;
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .box-card {
    height: 150px;
    border-radius: 20px;
    margin: 10px;
    font-size: xx-large;
    color: $font-blue-color;
    position: relative;

    h2 {
      margin-top: 4px;
      font-size: 0.8em;
      font-weight: 800;
      letter-spacing: -0.05em;
    }
  }

  .el-card.card-content {
    text-align: center;
  }

  .el-card__header {
    border-bottom: none;
    text-align: left;
    padding: 0px;

  }

  .el-card__body {
    text-align: center;

    .content-card {
      display: flex;
      padding: 0px;
      color: black;
    }

    & div {
      justify-content: center;
      //align-items: flex-end;
    }
  }

  .card-icon {
    span {
      letter-spacing: -0.05em;
      font-size: 1.3em;
      font-weight: bolder;
    }
  }

  .btn-icon-plus {
    position: absolute;
    right: 10px;
    bottom: 10px;
    //width: 20px;
    //height: 20px;
    color: #f7d20e;
  }

  /* button {
    color: #fff;
    border: 0;
    font-weight: 400;
    text-align: center;
    padding: 8px;
    font-size: 18px;
    width: 130px;
    border-radius: 8px;
  } */

}
</style>
