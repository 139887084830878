<template>
  <div class="card p-3 box-card">
    <el-image
        v-if="imagen"
        :src="apiUrl + imagen"
        fit="scale-down"
        class="card-img-top"
        style="max-height:16rem"
    >
      <template v-slot:error>
        <img
            class="card-img-top"
            src="@/assets/images/picture-loading-failed.png"
            alt=""
            srcset=""
        >
      </template>
    </el-image>
    <div class="card-body">
      <span class="time">{{ formatDateTime(datetime) }}</span>
      <h2 class="card-title title-new-img">
        {{ title }}
      </h2>
      <div
          class="card-text text-font-content-img"
          v-html="formatContent(content)"
      />
    </div>
    <div class="card-footer">
      <shared-button-plus @onClick="showDetailUndanitas()"/>
    </div>
  </div>
</template>

<script setup>
import SharedButtonPlus from "./SharedButtonPlus.vue";
import Undanitas from "@/api/services/undanitas.service.js";
import Employee from "@/api/services/employee.service.js";
import moment from 'moment';
import {defineProps, ref} from "vue";
import {useStore} from "vuex";

const store = useStore();
const props = defineProps({
  content: String,
  title: String,
  imagen: String,
  datetime: String,
  employee: String,
  idundanita: Number,
  iriundanita: String,
  confirmReader: Boolean
})

const apiUrl = ref(process.env.VUE_APP_BASE_URI);

const formatDateTime = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
  return '';
}
const createUndanitaReader = () => {
  /* console.log("EMPLOYEE",this.$store.getters.getUserLogged); */
  Employee.getEmployee(store.getters.getUserLogged.id)
      .then(response => {
        // console.log("timestamp", moment().format());
        /* const reader = {
          timestamp:moment().format(),
          employee:response.data['@id'],
          undanita:this.$props.idundanita
        } */
        Undanitas.getReader(props.idundanita)
            .then(reader_data => {
              let reader = reader_data.data['hydra:member'].find(v => v.employee === response.data['@id']);
              if (reader) {
                reader.timestamp = moment().format();
                // reader.employee = response.data['@id'],
                Undanitas.updateReader(reader);
              } else {
                reader = {
                  timestamp: moment().format(),
                  employee: response.data['@id'],
                  undanita: props.iriundanita
                }
                Undanitas.createReader(reader).then(resp => {
                  console.log("EMPLOYEE", resp);
                }).catch(err => {
                  console.log("ERROR", err);
                })
              }
            })
      })
}
const showDetailUndanitas = async () => {
  const undanitas = props
  console.log("undanitas ", undanitas)
  await store.dispatch("setUndanita", undanitas);
  await store.dispatch("setShowDialogUndanita", true);
  if (props.confirmReader)
    createUndanitaReader();
}

const formatContent = (value) => {
  if (value) {
    return value.replace(/\n/g, "<br />");
  }
  return "";
}

</script>
<style
    lang="scss"
    scoped>
.card {
  max-width: 28em;
}

.card-footer {
  background-color: $bg-blue;
  border: 0;
  text-align: right;
  padding: 5px 10px;
}

#chronometer {
  fill: #999;
  width: 10px;
  margin-right: 2px;
}

.box-card {
  overflow: hidden;
  height: 520px;
  min-height: 520px;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  color: white;
  background-color: $bg-blue;
}

.time {
  font-size: 12px;
  color: #999;
}

.text-font-content {
  color: white;
  letter-spacing: -0.05em;
  font-size: 1.2em;
  display: -webkit-box;
  min-height: 20px;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625; /* as per desire */
}

div.text-font-content-img {
  color: white;
  letter-spacing: -0.05em;
  display: -webkit-box;
  min-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625; /* as per desire */
  > *, p {
    font-size: 1.2em;
  }
}

.title-new {
  color: white;
  display: -webkit-box;
  min-height: 80px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625; /* as per desire */
}

.title-new-img {
  color: white;
  display: -webkit-box;
  min-height: 80px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625; /* as per desire */
}

</style>
