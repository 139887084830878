<template>
  <div class="dialog animated fadeIn fast">
    <div
      class="dialog-overlay"
      :class="{ 'white': theme === 'light', 'secondary': theme === 'secondary'}"
      @click.stop="closeDialog()"
    >
      <div
        class="dialog-container"
        @click="$event.stopPropagation()"
      >
        <div
          class="dialog-header"
          :class="{'close-icon': closeIcon}"
        >
          <h1 v-if="title">
            {{ title }}
          </h1>
          <a
            v-if="closeIcon"
            class="close-icon pointer"
            @click="closeDialog()"
          ><font-awesome-icon
            class="d-inline-block"
            icon="times"
          /></a>
        </div>
        <h3
          v-if="message"
          class="message"
        >
          {{ message }}
        </h3>
        <br>
        <hr class="c_secondary">
        <br>
        <div class="row">
          <div class="col-md-6">
            <button
              class="btn btn-block btn-danger"
              style="margin-bottom: 1.125rem;"
              @click="decline"
            >
              {{ declineText }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              class="btn btn-block btn-secondary"
              style="margin-bottom: 1.125rem"
              @click="confirm"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'SharedDialog',
    props: { 
      theme: { type: String, default: 'dark' },
      closeIcon: { type: Boolean, default: true },
      title: { type: String, default: '¿Deseas realizar esta acción?'},
      message: { type: String, default: 'Al confirmar esta acción puede que se produzcan cambios en los datos de la herramienta.'},
      declineText: { type: String, default: 'Cancelar' },
      confirmText: { type: String, default: 'Confirmar' },
    },
    methods: {
      closeDialog() {
        this.$emit('close', false);
      },
      confirm() {
        this.$emit('confirm');
      },
      decline() {
        this.$emit('decline');
      }
    }
  }

</script>


<style lang="scss" scoped>
  @import '@/assets/sass/core/_variables.scss';
  .dialog {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 99999;
    .dialog-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      background: rgba(0,0,0,.1);
      .dialog-container {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: auto;
        max-width: 30%;
        height: fit-content;
        max-height: 70%;
        margin: auto;
        padding: 60px 40px;
        color: $secondary-color;
        background: #ebebed;
        border-radius: 2px;
        overflow: auto;
        .dialog-header {
          position: relative;
          display: block;
          padding: 0px;
          min-height: 40px;
          width: 100%;
          border-bottom: 0px;
          margin-bottom: 10px;
          &.close-icon {
            padding-right: 40px;
          }
          h1 {
            text-align: left;
            font-family: $font-title;
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 0px;
            padding-top: 10px;
          }
          .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 24px;
          }
        }
        hr.c_secondary {
          &:after {
            color: $secondary-color;
          }
        }
      }
      &.white {
        .dialog-container {
          background: $light-bg;
          color: $dark-color;
        }
      }
      &.secondary {
        .dialog-container {
          background: $secondary-color;
          color: $dark-color;
        }
      }
      .message {
        white-space: pre-wrap;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .dialog .dialog-overlay .dialog-container {
      max-width: 90%;
    }
  }

</style>