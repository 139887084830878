import axios from 'axios'
import CONSTS from '@const/constants'

export default () => {
  const token = localStorage.getItem('token');
  return axios.create({
    baseURL: CONSTS.BASE_URL,
    withCredentials: false,
    headers: {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'Authorization': 'Bearer ' + token
    },
  })
}
