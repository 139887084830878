<template>
  <div>
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Departamentos
      </div>
    </div>
    <el-card style="height: 200px"
             class="card-search w-100 wback"
             body-style="padding:0"
    >
      <div class="row mx-5">
        <div class="container-departments">
          <div class="title-departments">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class=" w-100 mb-2">Nombre</label>
                      <el-input
                          v-model="searchFilterDepartmentName"
                          placeholder="Nombre del Departamento"
                          prefix-icon="el-icon-search"
                          clearable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
              <button
                  style="width: 280px;height: 40px;"
                  class="btn btn_yellow btn-search"
                  @click="search()"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table table-departments">
      <div v-if="departments && departments.length > 0">
        <table>
          <thead>
          <tr>
            <th>Nombre</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="department in departments"
              :key="department.id"
          >
            <td>
              {{ department.name }}
            </td>
            <td>
              <div class="buttons d-inline-flex">
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editDepartment(department)"
                  />
                </div>
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedDepartment = department;"
                  />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado departamentos</h5>
      </div>


      <!--      <div class="table-card-departments">-->
      <!--        <div v-if="departments && departments.length > 0">-->
      <!--          -->
      <!--        </div>-->
      <!--      </div>-->


      <SharedPagination
          v-if="departments && departments.length > 4"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getDepartments()"
      />

      <div class="row text-right">
        <div class="col-md-4 offset-md-8">
          <button @click="createOrUpdateDepartment = true" class="btn btn-primary">NUEVO DEPARTAMENTO</button>
        </div>
      </div>
      
    </div>

    <el-dialog
        v-model="createOrUpdateDepartment"
        class="dense-dialog"
        @closed="closeCreateOrUpdateDialog()">
      <div>
        <form @submit.prevent="">
          <div class="form-group row">
            <div class="col-md-5">
              <div class="form-item">
                <label for="departmentName" class="w-100">Nombre departamento</label>
                <el-input
                    v-model="departmentName"
                    :label="'Nombre departamento'"
                    :placeholder="'Nombre departamento'"
                />
              </div>

            </div>
            <!--                    <div class="col-md-3 text-right"  v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol) && title.length > 0">-->
            <div class="row">
              <div class="col-md-4 offset-md-8 d-flex flex-column justify-content-end align-items-end">
                <button
                    class="btn btn-primary"
                    @click="selectedDepartment ? handleUpdateDepartment() : createDepartment(department)"
                >
                  {{ selectedDepartment ? 'Actualizar' : 'Nuevo'}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
        v-model="deleteDialogShown"
        class="dense-dialog"
        :title="'¿Desea eliminar permanentemente este departamento?'"
        @closed="selectedDepartment = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedDepartment = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteDepartment(selectedDepartment)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';


export default {
  name: "Departments",
  components: {
    SharedPagination: SharedPagination,
    HeaderTitle,
  },
  props: {},
  data() {
    return {
      departments: [],
      department: {},
      submitted: false,
      deleteDialogShown: false,
      selectedDepartment: null,
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      departmentName: '',
      createOrUpdateDepartment: false,
      searchFilterDepartmentName: ''
    }
  },
  computed: {},
  mounted() {
    this.getDepartments();
  },
  methods: {
    getDepartments(searchQuery = null) {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getDepartments(params)
          .then((res) => {
            this.departments = res.data['hydra:member'];
          })
    },
    deleteDepartment(department) {
      ResourceService.deleteDepartment(department.id)
          .then(() => {
            Notification.addNotification('Departamento eliminado correctamente.', 'success', 6000);
            this.selectedDepartment = null;
            this.deleteDialogShown = false;
            this.getDepartments();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el departamento seleccionado', 'error', 6000);
        this.$store.state.loading = false;
      })
    },
    createDepartment(department) {
      department.name = this.departmentName;
      this.$store.state.loading = true;
      ResourceService.createDepartment(department)
          .then(() => {
            Notification.addNotification('Departamento creado correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel()
          })
          .catch(() => {
            Notification.addNotification('Error al crear el departamento', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })

    },
    editDepartment(department){
      this.selectedDepartment = department;
      this.departmentName = department.name;
      this.createOrUpdateDepartment = true
    },
    handleUpdateDepartment(){
      this.selectedDepartment.name = this.departmentName;
      this.updateDepartment(this.selectedDepartment)
    },
    async updateDepartment(department) {
      this.$store.state.loading = true;
      await ResourceService.updateDepartment(department, department.id)
          .then(() => {
            Notification.addNotification('Departamento actualizado correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel()
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el departamento', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    reset(){
      this.department = {};
      this.departmentName = '';
      this.selectedDepartment = null;
    },
    cancel() {
      this.reset()
      this.getDepartments();
    },
    closeCreateOrUpdateDialog(){
      this.createOrUpdateDepartment = false
      this.reset()
    },
    search(){
     this.getDepartments(this.searchFilterDepartmentName) 
    }
  }

}
</script>

<style lang="scss" scoped>

.department-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-departments {
  table {
    //margin-left: auto;
    //margin-right: auto;
    //width: auto;
  }
}

</style>
