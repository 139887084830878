<template>
  <div>
    <header-title title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/heart-icon.svg" width="50" alt="">
      <div class="title-text ml-4">
        Mis beneficios
      </div>
    </div>
    <div class="row flex flex-wrap  my-4 mx-2">
      <CardBenefit
        v-for="b in benefits"
        :key="b.id"
        :benefit="b"
      />
    </div>
    <el-dialog
        title=""
        v-model="store.state.showDialogBenefit"
        :show-close="false"
    >
    <BenefitCDialog />
    </el-dialog>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import BenefitServices from "@/api/services/benefit";
import CardBenefit from "@/components/shared/CardBenefit.vue";
import BenefitCDialog from "@/components/shared/BenefitCDialog.vue";
import { useStore } from "vuex";
import {onMounted, ref} from "vue";

const store = useStore();
const benefits = ref([]);

 onMounted(() => {
   store.dispatch('setBenefit', null);
   store.dispatch('setShowDialogBenefit', false);
    loadMiBenefits();
  })

    const loadMiBenefits = async() =>{
      const params = {
        // itemsPerPage:this.itemsPerPage,
        // page:this.page,
        'active':true,
      };
      return BenefitServices.get(params).then(resp=>{
        benefits.value = resp.data['hydra:member'];
      });
    }
</script>
<style lang="scss" scoped>
#icon-heart {
  fill: $bg-blue;
  width: 3%;
}
</style>