<template>
  <div class="loader animated fadeIn">
    <font-awesome-icon
      class="icon"
      icon="cog"
      spin
    />
  </div>
</template>

<script>
  export default {
    name: 'Spinner'
  }
</script>

<style lang="scss">
.loader {
  position:absolute;
  background: rgba(255, 255, 255, 0.2);
  height: 100%;
  width:100%;
  left:0;
  top:0;
  z-index:1;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    color: #41416a;
    margin: auto;
    height:50px;
    width: 50px;
  }
}

</style>
