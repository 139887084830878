<template>
  <el-progress
      :stroke-width="20"
      :percentage="percentaje"
      :color="occupationColors"
      :text-inside="true"
      :text-color="textColor"
  />
</template>

<script setup>
import {defineProps, ref} from 'vue';

defineProps({
  percentaje: {
    type: Number,
    default: 0
  }
});

const occupationColors = ref([
  {color: '#f56c6c', percentage: 70},
  {color: '#e6a23c', percentage: 80},
  {color: '#5cb87a', percentage: 100}
]);

const textColor = ref('#fff');


</script>
