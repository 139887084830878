<template>
  <div>
    <header-title title="Comunicación"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="../../assets/icons/undanews.png"
          class="icon-undanews"
          width="30"
          alt=""
          srcset=""
      >
      <div class="title-text">
        Undanews
      </div>
    </div>
    <el-row>
      <el-card
          class="card-search w-100"
          body-style="padding:0"
      >
        <div class="row no-gutters mx-5">
          <div class="col-md-4">
            <div class="d-flex flex-nowrap flex-column mt-4 w-75 label-common">
              <div class="form-item">
                <label
                    for="id-employee"
                    class="w-100"
                >Empleado</label>
                <el-select
                    id="id-employee"
                    v-model="employee"
                    class="w-100"
                    placeholder="Seleccione empleado"
                    filterable
                    clearable
                    @change="filterUndanews()"
                >
                  <el-option
                      v-for="item in optionsEmployee"
                      :key="item['@id']"
                      :label="item.fullName"
                      :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex flex-column flex-nowrap mt-4 w-100 label-common">
              <div class="form-item">
                <label for="title">T&iacute;tulo</label>
                <el-input
                    id="title"
                    v-model="title"
                    class="search-input"
                    :placeholder="'Ingrese título'"
                    clearable
                    @change="filterUndanews()"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
                class="
                d-flex
                flex-nowrap flex-column
                mt-4
                w-100
                pl-5
                label-common
              "
            >
              <div class="form-item">
                <label
                    for="id-status"
                    class="w-100"
                >Estado</label>
                <el-select
                    id="id-status"
                    v-model="status"
                    class="w-75"
                    placeholder="Seleccione estado"
                    clearable
                    filterable
                    @change="filterUndanews()"
                >
                  <el-option
                      v-for="item in optionsStatus"
                      :key="item['@id']"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters mx-5">
          <div class="col-md-4">
            <div class="form-item date-picker">
              <label class="w-100">Desde</label>
<!--              <el-date-picker-->
<!--                  ref="fromDate"-->
<!--                  v-model="selectedFromDate"-->
<!--                  type="date"-->
<!--                  format="DD/MM/YYYY"-->
<!--                  value-format="YYYY-MM-DD"-->
<!--                  :prefix-icon="customPrefix"-->
<!--                  :picker-options="{ firstDayOfWeek: 1 }"-->
<!--              />-->
              <el-date-picker
                  v-model="selectedFromDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  @change="filterUndanews"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-item date-picker">
              <label class="w-100">Hasta</label>
              <el-date-picker
                  v-model="selectedToDate"
                  type="date"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{ firstDayOfWeek: 1,disabledDate:(time)=>disabledEndDate(time,selectedFromDate)}"
                  @change="filterUndanews"
              />
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-end ">
            <button
                class="btn btn_red "
                @click="$router.push({name:'new_undanews'})"
            >
              NUEVA
            </button>
            <button
                class="btn btn_yellow  ml-2"
                @click="filterUndanews()"
            >
              BUSCAR
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-users">
        <table>
          <thead>
          <tr>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-1">
                <img
                    src="@/assets/icons/calendar-icon.svg"
                    width="20"
                    height="20"
                    alt="calendar-icon"/>
                <div>FECHA</div>
              </div>
            </th>
            <th>
              <div class="d-flex flex-row  justify-content-start align-items-start gap-1">
                <img
                    src="@/assets/icons/user.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div class="ml-2">
                  EMPLEADOS
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-1">
                <img
                    src="@/assets/icons/unda-icon.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div>TITULO</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-1">
                <img
                    src="@/assets/icons/unda-icon.svg"
                    width="20"
                    height="20"
                    alt="user-icon"/>
                <div>ESTADO</div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="und in undanews"
              :key="und.id"
          >
            <td>
              {{ formatDate(und.timestamp) }}
            </td>
            <td>
              <CellEmployee
                  :employee="selectEmployee(und.author)"
                  :without-link="true"
                  :is-show-last-name="false"
              />
            </td>
            <td>
              <router-link :to="{name: 'update_undanews', params: { id: und.id }}">
                {{ und.title }}
              </router-link>
            </td>
            <td>
              {{ selectStatus(und.newsStatus) }}
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div
                    class="pointer"
                    @click="goUpdate(und.id)"
                >
                  <img
                      width="25"
                      src="../../assets/icons/edit_undanew.png"
                      alt=""
                      srcset=""
                  >
                </div>
                <div class="m-4">
                  <font-awesome-icon
                      width="25"
                      icon="trash"
                      class="pointer"
                      @click="deleteUndanews(und)"
                  />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-row>
    <el-row>
      <SharedPagination
          v-if="undanews && totalResults > itemsPerPage"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          @change="page = $event;filterUndanews();"
      />
    </el-row>
    <el-row class="mt-5">
      <button
          class="btn btn_yellow  float-right w-25"
          @click="goBack()"
      >
        VOLVER
      </button>
    </el-row>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import EmployeeServices from "@/api/services/employee.service.js";
import Undanews from "@/api/services/undanews.service.js";
import CellEmployee from "@/components/shared/CellEmployee.vue";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import moment from "moment";
import {h, onMounted, ref, shallowRef} from "vue";
import {useRouter} from "vue-router";

const itemsPerPage = ref(10);
const page = ref(1);
const totalResults = ref(0);
const title = ref('');
const optionsEmployee = ref([]);
const undanews = ref([]);
const employee = ref('');
const optionsStatus = ref([]);
const status = ref('');
const selectedFromDate = ref('');
const selectedToDate = ref('');

const router = useRouter();
onMounted(() => {
  getEmployees();
  getStatus();
  filterUndanews();
})

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const formatDate = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }
  return "";
}
const deleteUndanews = (und) => {
  Undanews.delete(und.id).then(() => {
    /* const prom = [];
     if(und.undanewsMediaObjects.length>0){
    und.undanewsMediaObjects.forEach(v=>{
        prom.push(deleteMedia(v.id));
    })
    if(prom.length>0){
          Promise.all(prom)
      .then(()=>{
        filterUndanews();
      });
    }else{
      filterUndanews();
    }

  }else */
    filterUndanews();
  });
}
const goUpdate = (id) => {
  router.push({name: 'update_undanews', params: {id}})
}
const goBack = () => {
  router.go(-1);
}
const selectStatus = (status) => {
  //  console.log("SSS",status);
  const statusUnd = optionsStatus.value.find(v => v['@id'] === status['@id']);
  //console.log("SSS",statusUnd);
  return statusUnd && statusUnd.name && statusUnd.name;
}
const selectEmployee = (emp) => {
  if (!emp) return null;
  return optionsEmployee.value.find(v => v['@id'] === emp['@id']);

}
const filterUndanews = () => {
  const params = {
    itemsPerPage: itemsPerPage.value,
    page: page.value
  }
  if (title.value) {
    params.title = title.value;
  }
  if (employee.value) {
    params['author.id'] = employee.value;
  }
  if (status.value) {
    console.log("STATUS", status.value);
    params['newsStatus.id'] = status.value;
  }
  if (selectedToDate.value && selectedFromDate.value) {
    params['timestamp[before]'] = selectedToDate.value//moment(selectedFromDate,"YYYY-MM-DD");
    params['timestamp[after]'] = selectedFromDate.value//moment(selectedToDate,"YYYY-MM-DD");
  } else if (selectedFromDate.value) {
    params['timestamp[after]'] = selectedFromDate.value//moment(selectedFromDate,"YYYY-MM-DD");
  } else if (selectedToDate.value) {
    params['timestamp[before]'] = selectedToDate.value//moment(selectedToDate,"YYYY-MM-DD");
  }
  params["order[timestamp]"] = "desc";
  Undanews.get(params).then(resp => {
    undanews.value = resp.data['hydra:member'];
    totalResults.value = resp.data["hydra:totalItems"];
  });
}
const disabledEndDate = (date, departureDate) => {
  //console.log("date",date);
  //console.log("depatura",departureDate);
  let flag = false;
  // If departureDate then return valid dates after departureDate
  if (departureDate) {
    flag = moment(date.getTime()) < moment(departureDate)

  } else {
    // If !departureDate then return valid dates after today
    flag = moment(date.getTime()) < moment.now();
    /* return date.getTime() < Date.now() */
  }
  //console.log("BANDERA",flag);
  return flag;
}
const getEmployees = () => {

  EmployeeServices.getEmployees({itemsPerPage: 9999, page: 1, active: true})
      .then((resp) => {
        optionsEmployee.value = resp.data['hydra:member']
      });
}
const getStatus = () => {
  Undanews.getStatus({itemsPerPage: 9999, page: 1}).then((resp) => {
    optionsStatus.value = resp.data['hydra:member'];
  });
}
// eslint-disable-next-line no-unused-vars
// const getUndanews = () => {
//   Undanews.get({itemsPerPage: itemsPerPage.value, page: page.value}).then((resp) => {
//     optionsStatus.value = resp.data['hydra:member'];
//   });
// }

</script>
<style
    lang="scss"
    scoped>

.icon-undanews {
  width: 6.5%;
}

.title-text {
  text-align: center;
  align-self: center;

  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}

.card-search {
  border-radius: 15px;
  height: 285px;
  background: url("../../assets/images/Uundanet.png"), white;
  background-repeat: no-repeat;
  background-position-x: 98%;
  overflow: visible !important;
}

.icon-search {
  width: 15%;
  height: 3%;
}

#icon-user {
  fill: $bg-blue;
  width: 1.8em;
}

//#icon-calendar {
//  fill: $bg-blue;
//  height: 40px;
//  width: 36px;
//}

.table-users {
  table {

    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}
</style>
