// Import Auth Dependencies
import router from '@/app/router/router'
import axios from 'axios'
import store from '@/app/store/store'
import errorHandler from '@/utils/plugins/ErrorHandler'
import CONSTS from '@const/constants'

// Services
import notification from '@/utils/plugins/NotificationService'

// URL consts
const BASE_URL = CONSTS.BASE_URL
const LOGIN_URL = BASE_URL + '/login'
const CHANGE_PASSWORD_URL = BASE_URL + '/api/password_recovery/change_password';
const RECOVER_PASSWORD_URL = BASE_URL + '/api/password_recovery/send_recovery';


export default {
    // User auth object
    user: {
        authenticated: false
    },
    // TODO Refresh token
    async refreshToken(msg, status, time) {
        const refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token) {
            const request = {
                method: 'post',
                url: LOGIN_URL,
                headers: {
                    "Content-Type": "application/json"
                },
                auth: {
                    username: 'intranet',
                    password: 'intranet-secret'
                },
                data: {
                    refresh_token: refresh_token,
                    grant_type: 'refresh_token',
                    scope: 'trust'
                }
            }
            await axios(request)
                .then((response) => {
                    localStorage.setItem('token', response.data.access_token)
                })
                .catch(async () => {
                    notification.addNotification(msg, status, time)
                    await this.logout()
                })
        } else {
            notification.addNotification(msg, status, time)
            await this.logout()
        }
    },
    login(credentials) {
        store.state.loading = true;
        return axios.post(LOGIN_URL, credentials)
            .then(async res => {
                notification.addNotification('Sesión iniciada correctamente.', 'success', 6000);
                localStorage.setItem('token', res.data.token);
                this.user = res.data.user
                this.user.authenticated = true;
                await store.dispatch('setUserLogged', this.user);
                await router.push({name: 'home'})
                store.state.loading = false;
            })
            .catch((err) => {
                this.user.authenticated = false;
                errorHandler(err, null, 'auth');
                store.state.loading = false;
            });
    },
    // Recover password
    recover(email) {
        const request = {
            method: 'post',
            url: RECOVER_PASSWORD_URL,
            params: {
                email: email
            }
        }
        return axios(request);
    },

    // Logout from active session
    async logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('store')
        this.user.authenticated = false
        await store.dispatch('setUserLogged', null)
        await router.push({name: 'login'})
    },

    // Check if user is logged (token in LocalStorage)
    checkAuth() {
        let jwt = localStorage.getItem('token')
        this.user.authenticated = !!jwt
        return this.user.authenticated
    },

    // Change password
    changePassword(user) {
        let jwt = localStorage.getItem('token')
        return axios.post(CHANGE_PASSWORD_URL, user, {headers: {Authorization: `Bearer ${jwt}`}})
            .then(() => {
                // console.log(res);
            })
            .catch(() => {
                // console.log(err);
            })
    }
}
