<template>
  <div class="background">
    <div id="banana">
      <img
        src="../../assets/images/login_hola_banana.png"
        alt=""
        srcset=""
      >
    </div>
    <div
      id="login"
      class="animated fadeIn fast"
    >
      <div>
        <img
          class="logo-connectus mb-3"
          src="../../assets/images/logo_ConnectUs_png_25.png"
          alt=""
        >
      </div>
      <div>
        <img
          class="mb-4"
          src="../../assets/images/dots.png"
          alt=""
        >
      </div>
      <form
        id="loginForm"
        name="loginForm"
        @submit.prevent="submit"
      >
        <div class="form-item">
          <input
            id="username"
            v-model="credentials.username"
            type="text"
            name="username"
            data-vv-as="usuario"
            class="login"
            placeholder="Usuario"
            :class="{'is-invalid': submitted && errors.username}"
            @keyup.enter="submit"
          >
          <div
              v-for="error in $v.username.$errors"
              :key="error.uid"
            class="animated fadeIn invalid-feedback"
          >
            <font-awesome-icon
              class="icon"
              icon="info-circle"
            />


            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="form-item mb-5">
          <input
            id="password"
            v-model="credentials.password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            data-vv-as="contraseña"
            class="login"
            placeholder="Contraseña"
            :class="{'is-invalid': submitted && errors.password}"
            @keyup.enter="submit"
          >
          <font-awesome-icon
            class="eye pointer"
            :icon="showPassword ? 'eye-slash' : 'eye'"
            @click="showPassword = !showPassword"
          />
          <div
              v-for="error in $v.password.$errors"
              :key="error.uid"
            class="animated fadeIn invalid-feedback"
          >
            <font-awesome-icon
              class="icon"
              icon="info-circle"
            />
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <!--  <div class="text-right recover-link">
            <a href="#" @click.prevent="recover">Olvidaste tu contraseña?</a>
          </div> -->

        <button
          class="btn btn-block btn_yellow btn__login"
          type="button"
          @keyup.enter="submit"
          @click="submit"
        >
          ENTRAR
        </button>
        <button
          class="btn btn-block btn_purple btn__login"
          type="button"
          @click.prevent="recover"
        >
          OLVIDE MI CONTRASEÑA
        </button>
      </form>
    </div>
    <div id="aspas">
      <img
        class="aspa-big"
        src="../../assets/images/aspa_amarilla_small_entrada.png"
        alt=""
        srcset=""
      >
      <img
        class="aspa-small"
        src="../../assets/images/aspa_amarilla_small_entrada.png"
        alt=""
        srcset=""
      >
      <img
        class="estrella-white"
        src="../../assets/images/estrella_blanca_entrada.png"
        alt=""
        srcset=""
      >
      <img
        class="logo-undanet"
        src="../../assets/images/logo-undanet.png"
        alt=""
        srcset=""
      >
    </div>
  </div>
</template>

<script setup>
import auth from '@/api/auth/auth';
import router from "../../app/router/router";
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import {computed,ref} from "vue";
import message from '@/utils/constants/message';
import Notification from "@/utils/plugins/NotificationService";

const showPassword = ref(false);
const submitted = ref(false);
const errors = ref({});
const credentials = ref({
  username:'', password:''
})
const rules = computed(()=>{
  return  {
    username:{
      required:helpers.withMessage(message.required,required),
      email:helpers.withMessage(message.email,email),
    },
    password:{
      required:helpers.withMessage(message.required,required),
      minLength:helpers.withMessage("La longitud mínima del campo es de 8", minLength(8))},
  }
})
const $v = useVuelidate(rules, credentials);
const submit = async () => {
  const valid = await $v.value.$validate();
  if(valid){
    await auth.login({email: credentials.value.username, password: credentials.value.password, site: process.env.VUE_APP_SITE }, 'holidays')

  }else {
    Notification.addNotification(
        "Hay campos con valores incorrectos",
        "error",
        6000
    );
  }
}
const recover = () => {
  router.push({name: 'recover-password'});
}
</script>

<style lang="scss" scoped>
.logo-connectus{
   filter: brightness(1);
}
/* .logo-undanet{
  position: absolute;
  bottom:2%;
  right:2%;
  width:10%;
  filter: brightness(0);
}
.estrella-white{
   position: absolute;
  bottom:20%;
  right:25%;
  width:2%;
  filter: brightness(0);
}
.aspa-big{
  position: absolute;
  top: 15%;
  right:25%;
  width:4.2%;
}
.aspa-small{
  position: absolute;
  top:41%;
  right:32%;
  width:2.5%;
} */
.aspa-big{
  position: absolute;
  top: 15%;
  right: calc(60% - 40px);
  width: 80px;
  transition: all 0.3s linear;
}
.aspa-small{
  position: absolute;
  top: 41%;
  right: calc(100% - 45px);
  width: 45px;
  transition: all 0.3s linear;
}
.estrella-white{
  position: absolute;
  bottom: 20%;
  right: calc(50% - 15px);
  width: 30px;
  filter: brightness(1);
  transition: all 0.3s linear;
}
.logo-undanet{
  position: absolute;
  bottom: 2%;
  right: 20px;
  width: 200px;
  filter: brightness(1);
  transition: all 0.3s linear;
}

.background{
  display: flex;
  background: #474ce6;
  width: 100vw;
  height:100vh;
}

.image-login {
  width: 100%;
  height: auto;
}

.container {
  display: flex;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color:white;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #474ce6 inset;
}
.login {
  background: #474ce6;
  padding: 0;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid white;
  color: white;
  
  &:focus{
    background: #474ce6;
    color:white;
    padding: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid white;
  }

  &::placeholder {
    color: white;
  }
}

.u-icon{
  width: max-content
}
input {
   border-bottom: 1px solid white!important;
}
#banana {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s linear, order 0s linear;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-right: -10%;
  }
}

#aspas {
  width: 25%;
  position: relative;
  margin-left: auto;
  transition: all 0.3s linear, order 0s linear;
}

#login {
  top: calc(40% - 220px);
  left: 45%;
  max-width: 100%;
  width: 450px;
  height: 440px;
  z-index: 1;
  transition: all 0.3s linear, order 0s linear;
}

#login {
  position: absolute;
  /* top:20%;
  left:42%;
  max-width: 31.25rem;
  width: 100%;
  height: 45%; */
  background-color:transparent;
  padding: 4rem;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 5%;
  h1 {
    font-family: $font-title;
    font-weight: 800;
    color: #FFFFFF;
  }

  h3 {
    font-weight: 300;
  }

  .logo {
    position: absolute;
    bottom: 4.44rem;
    left: 0px;
    right: 0px;
    margin: auto;

    img {
      width: 60%;
    }
  }

  .btn__login {
    margin-top: 1.5em;
    height: 3em;
    border-radius: 30px;
  }

  img {
    max-width: 100%;
  }

}

.star_white {
  position: absolute;
  left: 1636px;
  top: 528px;
}

.plus-yellow-big {
  position: absolute;
  left: 120em;
  top: 1em;
}

.plus-yellow-small {
  position: absolute;
  left: 114em;
  top: 25em;
}

.asterisk-yellow {
  position: absolute;
  top: 150px;
  left: 150px;
  font-size: 50px;
  color: yellow;
}

.asterisk-white {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
</style>
