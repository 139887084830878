import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE= '/api/ticket_status_histories';

export default {
    async createStatusHistory(data){
        return Api()
            .post(RESOURCE, data)
            .catch((err) => errorHandler(err, null));
    },
}


