<script setup>

import {computed, defineProps} from "vue";

const props = defineProps({
  totalTime: Number,
  title:{
    type: String,
    default: 'Total'
  }
})

const timeInfo = computed(() => {

  const hours = Math.floor(props.totalTime / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((props.totalTime % 3600) / 60).toString().padStart(2, '0');
  const remainingSeconds = (props.totalTime % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}:${remainingSeconds}`;
});
</script>

<template>
  <div>
    <div class="tracker-total-time">
      <div class="tracker-total-time__title">{{ props.title }}: <span class="tracker-total-time__time tracker-time">{{timeInfo}}</span></div>

    </div>
  </div>
</template>

<style scoped lang="scss">
 .tracker-total-time {
   display: flex;
   justify-content: start;
   align-items: center;
   .tracker-total-time__title {
     color: #474ce6;
     font-size: 13px;
   }
   .tracker-total-time__time {
     font-size: 16px!important;
     color: black!important;
     font-weight: 500;
   }
 }
</style>