<template>
  <div v-if="!loading">
    <FormBenefit
      :update="true"
      :benefit="benefit"
    />
  </div>
</template>
<script>
import FormBenefit from "@/views/benefits/FormBenefit.vue";
import Services from "@/api/services/benefit";

export default {
  components:{
    FormBenefit
  },
  data(){
    return{
      benefit:{},
      loading: true
    }
  },
  mounted(){
    this.loading = true;
    this.getSuggestion()
  },
  methods:{
    getSuggestion(){
      const id = this.$route.params.id;
      Services.getOne(id)
          .then((resp)=>{
            this.benefit = resp.data;
          })
          .finally(() => {
            this.loading = false;
          });

    }
  }
}
</script>