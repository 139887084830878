<script setup>

// eslint-disable-next-line no-undef
const props = defineProps({
  project: Object
})
</script>

<template>
  <el-row>
    <el-col :span="4">
      <div class="project-circle" :style="{backgroundColor: props.project.color}"></div>
    </el-col>
    <el-col :span="20">
      <div class="font-weight-bold" :style="{color: props.project.color}">{{ props.project.name }}</div>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
   .project-circle {
     width: 10px;
     height: 10px;
     border-radius: 50%;
     margin-top: 5px;
   }
</style>