import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_NAME = '/api/schedules';

export default {
    // EMERGENCY CONTACT
    getSchedules(params) {
        return Api()
            .get(RESOURCE_NAME, {params: params})
            .catch((err) => errorHandler(err, null));
    },
    getSchedule(id) {
        return Api().get(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
    createSchedule(item) {
        const saveSchedule = item;
        return Api().post(RESOURCE_NAME, saveSchedule)
            .catch((err) => errorHandler(err, null));
    },
    updateSchedule(item, id) {
        return Api().put(RESOURCE_NAME + `/${id}`, item)
            .catch((err) => errorHandler(err, null));
    },
    deleteSchedule(id) {
        return Api().delete(RESOURCE_NAME + `/${id}`)
            .catch((err) => errorHandler(err, null));
    },
}
