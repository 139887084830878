import Api from "@/api/Api";
import errorHandler from "@/utils/plugins/ErrorHandler";

const RESOURCE_STATES = "/api/time_entry_tags";

export default {
    async getTags(params) {
        return await Api()
            .get(RESOURCE_STATES, { params })
            .catch((err) => errorHandler(err, null));
    },
    async getType(id) {
        return Api()
            .get(RESOURCE_STATES + "/" + id)
            .catch((err) => errorHandler(err, null));
    }

}
