import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';


const RESOURCE_NAME = '/api/undanews';
const RESOURCE_NAME_STATUS = '/api/news_statuses'
const RESOURCE_NAME_MEDIA = '/api/undanews_media_objects'
const RESOURCE_NAME_READER = 'undanews_readers';


export default {
    async get(params){
        return await Api()
        .get(RESOURCE_NAME,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getOne(id){
        return await Api()
        .get(RESOURCE_NAME+`/${id}`)
        .catch((err) => errorHandler(err, null));
    },
    async getStatus(params){
        return await Api()
        .get(RESOURCE_NAME_STATUS,{params})
        .catch((err) => errorHandler(err, null));
    },
    async create(params){
        return await Api()
        .post(RESOURCE_NAME,params)
        .catch((err) => errorHandler(err, null));
    },
    async delete(id){
        return await Api()
        .delete(RESOURCE_NAME+`/${id}`)
        .catch((err) => errorHandler(err, null));
    },
    async update(id,undanews){
        return await Api()
        .put(RESOURCE_NAME+'/'+id,undanews)
        .catch((err) => errorHandler(err, null));
    },
    async createMediaObject(file){
        let data = new FormData();
        data.append('file', file, file.name);
        return await Api()
        .post(RESOURCE_NAME_MEDIA,data)
        .catch((err) => errorHandler(err, null));
    },
    async createReader(reader){

        return await Api()
        .post(`/api/${RESOURCE_NAME_READER}`,reader)
        .catch((err) => errorHandler(err, null));
    },
    async updateReader(reader){

        return await Api()
        .put(`${reader['@id']}`,reader)
        .catch((err) => errorHandler(err, null));
    },
    async getReader(id, params){

        return await Api()
        .get(`${RESOURCE_NAME}/${id}/${RESOURCE_NAME_READER}`, {params: {params}})
        .catch((err) => errorHandler(err, null));
    },
    async getOneReader(id){

        return await Api()
        .get(`${id}`)
        .catch((err) => errorHandler(err, null));
    },
    async deleteMediaObject(id){

        return await Api()
        .delete(RESOURCE_NAME_MEDIA+`/${id}`)
        .catch((err) => errorHandler(err, null));
    },
    async getMediaObject(id){
        return await Api()
        .get(RESOURCE_NAME+`/${id}/undanews_media_objects`)
        .catch((err) => console.log(err));
    },
    async getOneMediaObject(id){
        return await Api()
        .get(`${id}`)
        .catch((err) => errorHandler(err, null));
    }


}
