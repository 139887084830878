import _ from 'lodash'
import moment from "moment";

const baseUri = process.env.VUE_APP_BASE_URI;

export function employeeInitials(employee) {
    return employee.name.charAt(0).toUpperCase() + _.map(employee.lastname.split(' '), el => el.charAt(0).toUpperCase()).join('')
}

export function photo(employee) {
    if (!employee.photo) {
        return null;
    }
    return baseUri.replace(/\/$/, "") + '/images/employees/' + employee.photo;
}
export function toHours(totalSeconds) {
    let sec_num = parseInt(totalSeconds, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    // var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    return hours+'h '+minutes+'m';
}

export function convertToHours(totalSeconds) {
    const secNum = parseInt(totalSeconds, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - (hours * 3600)) / 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return hours + 'h ' + minutes + 'm';
}

export function getDay(date) {
    return moment(date).format('DD');
}

export function getMonth(date) {
    return moment(date).format('MM');
}

export function getYear(date) {
    return moment(date).format('YYYY');
}

export function getDateTimeFormatted(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function toDecimalHours(seconds) {
    return Math.round(((seconds / 60 / 60) + Number.EPSILON) * 100) / 100
}