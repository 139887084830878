<template>
  <div class="holiday-incompatibilities">
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Vacaciones incompatibles
      </div>
    </div>
    <el-card
        class="card-search w-100 wback"
        body-style="padding:0"
        style="height: auto !important;"
    >
      <div class="row mx-5">
        <div class="container-holiday-incompatibilities">
          <form>

            <div class="row">
              <div class="col-md-6">
                <div class="form-item">
                  <label for="holidayIncompatibilityName" class="w-100">Nombre incompatibilidad</label>
                  <el-input
                      clearable
                      v-model="holidayIncompatibilityName"
                      :placeholder="'Nombre Incompatibilidad'"
                      @change="filterHolidayIncompatibilityByName($event)"
                  />
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6">
                <div class="form-item">
                  <label for="employeeName" class="w-100">Nombre empleado</label>
                  <el-input
                      clearable
                      v-model="employeeName"
                      :placeholder="'Nombre'"
                  />
                </div>
              </div>
            </div>

            <!--
            <div class="form-item">
                <div class="row text-center">
                    <div class=col-md-6>
                        <div class="row">
                            <div class="col-md-2">
                                <label class="label-state">Estado:</label>
                            </div>
                            <div class="col-md-10">
                                <div class="colsd">
                                    <SharedDropdown
                                            id="status"
                                            v-model="selectedStatusFilter"
                                            :items="statusOptions"
                                            :selected="selectedStatusFilter"
                                            @selectItem="filterByStatus($event)"
                                            data-vv-as="selectedStatusFilter"
                                            :rules="{ required: false }"
                                            data-vv-name="statusOptions"
                                            :label="'ddd'"
                                            :param="'name'"
                                            :color="'light'"
                                            :title="'Todos'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
              <button
                  style="width: 280px;height: 40px;"
                  class="btn btn_yellow btn-search"
                  @click="search()"
              >
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="dataSynchronized && holidayIncompatibilities && holidayIncompatibilities.length > 0">
        <table>
          <thead>
          <tr>
            <th>Nombre</th>
            <th>Empleado</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="holidayIncompatibility in holidayIncompatibilities">
            <tr
                v-for="employee in holidayIncompatibility.employees"
                :id="holidayIncompatibility.id + '-' + employee.id"
                :key="holidayIncompatibility.id + '-' + employee.id"
            >
              <td
                  class="pointer"
                  @click="openModalConfigHolidayIncompatibility(holidayIncompatibility)"
              >
                {{ holidayIncompatibility.name }}
              </td>
              <td
                  class="pointer"
                  @click="openModalConfigHolidayIncompatibility(holidayIncompatibility)"
              >
                {{ employee.name }} {{ employee.lastName }}
              </td>
              <td>
                <!-- <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon
                        class="btn_icon blue_btn"
                        icon="eye"
                        title="Editar"
                        style="margin-left: 5px"
                        @click="openModalConfigHolidayIncompatibility(holidayIncompatibility);"
                    />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon
                        class="btn_icon delete_btn"
                        icon="times"
                        title="Eliminar"
                        style="margin-left: 5px"
                        @click="deleteDialog(holidayIncompatibility, employee);"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado incompatibilidades</h5>
      </div>

      <SharedPagination
          v-if="holidayIncompatibilities && totalResults > 10"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getHolidayIncompatibilities()"
      />
    </div>

    <div class="row">
      <div
          class="col-md-12"
          style="padding: 20px 0px 20px 0px;"
      >
        <!--                    <div class="col-md-3 text-right"  v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol) && title.length > 0">-->
        <div class="d-flex justify-content-end">
          <div class="col-md-2">
            <button
                type="submit"
                class="btn btn_blue"
                @click="openModalConfigHolidayIncompatibility()"
            >
              Nueva Incompatibilidad
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmación de eliminacion de una incompatibilidad -->
    <el-dialog
        v-model="deleteDialogShown"
        class="dense-dialog"
        :title="'¿Desea eliminar permanentemente esta incompatibilidad de ' + selectedHolidayIncompatibilityText + '?'"
        @closed="selectedHolidayIncompatibility = null;selectedEmployee = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedHolidayIncompatibility = {};selectedEmployee = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteSelectedHolidayIncompatibility(selectedHolidayIncompatibility, selectedEmployee)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- Modal de configuración de incompatibilidad ########################################################################################################-->
    <el-dialog
        v-model="showModalConfigIncompatibility"
        :title="'Incompatibilidad de vacaciones'"
        @closed="closeModalConfigHolidayIncompatibility()"
    >
        <div class="row item-modal">
          <div class="col-sm-12">
            <label class="modal-config-label">Configurador</label>
          </div>
        </div>


        <el-form
            ref="newHolidayIncompatibilityForm"
            class="newHolidayIncompatibilityForm"
            :model="selectedHolidayIncompatibility"
            name="newHolidayIncompatibilityForm"
            :rules="newHolidayIncompatibilityRules"
            status-icon
            @submit.prevent
        >
          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <el-input
                  v-model="selectedHolidayIncompatibility.name"
                  :label="'Nombre:'"
                  :color="'light'"
                  :param="'name'"
                  :placeholder="'Nombre'"
              />
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <el-input
                  v-model.number="selectedHolidayIncompatibility.minNumberEmployees"
                  :label="'Número mínimo de empleados:'"
                  :type="'number'"
                  :color="'light'"
                  :param="'minNumberEmployees'"
                  :placeholder="'0'"
              />
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <!-- col-sm-6 -->
              <label class="modal-add-label">Añadir empleados</label>
              <font-awesome-icon
                  class="btn_icon modal-add-employee-icon"
                  icon="plus-circle"
                  title="Añadir"
                  @click="addEmployeeFromConfigModal()"
              />
            </div>
            <!--
            <div class="col-sm-6">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="incompatibility-active"
                        :checked="selectedHolidayIncompatibility.active ? true : false">
                    <label class="custom-control-label" for="incompatibility-active">Incompatibilidad activa</label>
                </div>
            </div>
            -->
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="table">
                <div
                    v-if="selectedHolidayIncompatibility.employees && selectedHolidayIncompatibility.employees.length > 0"
                >
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th />
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in selectedHolidayIncompatibility.employees"
                        :key="employee.id"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        <font-awesome-icon
                            class="btn_icon modal-remove-employee-icon"
                            icon="minus-circle"
                            title="Eliminar"
                            @click="deleteEmployeeFromConfigModal(selectedHolidayIncompatibility, employee)"
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <label class="mt-1">No hay empleados todavía...</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-6">
              <button
                  class="btn btn-block btn_red"
                  type="button"
                  @click="closeModalConfigHolidayIncompatibility()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-6">
              <button
                  class="btn btn_blue"
                  type="button"
                  @click="requestSaveConfigHolidayIncompatibility(selectedHolidayIncompatibility)"
              >
                Aceptar
              </button>
            </div>
          </div>
        </el-form>
    </el-dialog>

    <!-- Modal Añadir Miembros a la incompatibilidad -->
    <el-dialog
        v-model="showModalEmployees"
        :title="'Añadir empleados'"
        :close-button-in-header="true"
    >
        <div>
          <div class="row modal-item">
            <div class="col-md-9 form-item">
              <el-input
                  v-model="searchNameModalEmployees"
                  label="Nombre"
                  placeholder="Nombre"
                  @change="filterEmployeesByName($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="table">
                <div v-if="allEmployeesList && allEmployeesList.length > 0">
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Departamento</th>
                      <th>Puesto</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in allEmployeesList"
                        :key="employee.id"
                        class="pointer"
                        :class="{ selected: isEmployeeSelected(employee) }"
                        @click="addEmployeeToConfigIncompatibility(employee)"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        {{ employee.job ? employee.job.name : null }}
                      </td>
                      <td>
                        {{
                          employee.job && employee.job.department ? employee.job.department.name
                              : '--'
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <SharedPagination
                      v-if="allEmployeesList && allEmployeesListTotal > itemsPerPage"
                      class="pagination"
                      :page="page"
                      :total-results="allEmployeesListTotal"
                      :page-size="itemsPerPage"
                      style="padding: 0px 0px 20px 0px;"
                      @change="page = $event; getEmployees();"
                  />
                </div>
                <div v-else>
                  <label>No hay empleados disponibles</label>
                </div>
              </div>
            </div>
          </div>


          <!--
          <div>
              <br/>
              <div class="row item">
                  <div class="col">
                      <button class="btn btn-block btn_red" @click="closeModalEmployees()">
                          Cancelar
                      </button>
                  </div>
                  <div class="col">

                      <button class="btn btn_blue">
                          Aceptar
                      </button>
                  </div>
              </div>
          </div>
          -->
        </div>
    </el-dialog>
  </div>
</template>

<script>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
//import SharedDropdown from "@/components/shared/SharedDropdown.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";

// Services
import HolidayIncompatibleService from '@/api/services/holidayIncompatibility.service';
import EmployeeService from '@/api/services/employee.service';

import Notification from '@/utils/plugins/NotificationService'


export default {
  name: "HolidayIncompatibilities",
  components: {
    HeaderTitle,
    SharedPagination: SharedPagination,
    //SharedDropdown,
  },
  data() {
    const nameRule = (rule, value, callback) => {
      if (!value) {
        this.newHolidayIncompatibilityRules.name.error = true;
        callback(new Error("Debe especificar un nombre"));
      } else {
        this.newHolidayIncompatibilityRules.name.error = false;
        callback();
      }
    };
    const minNumberEmployeesRule = (rule, value, callback) => {
      // TODO: verificar la validación de este número
      if (value == null || value < 0) {
        this.newHolidayIncompatibilityRules.minNumberEmployees.error = true;
        callback(new Error("Debe especificar un mínimo de empleados válido"));
      } else {
        this.newHolidayIncompatibilityRules.minNumberEmployees.error = false;
        callback();
      }
    };
    const employeesRule = (rule, value, callback) => {
      if (!value || value.length <= 0) {
        this.newHolidayIncompatibilityRules.employees.error = true;
        callback(new Error("Debe seleccionar al menos un empleado"));
      } else {
        this.newHolidayIncompatibilityRules.employees.error = false;
        callback();
      }
    };

    return {
      holidayIncompatibility: {
        name: '',
        minNumberEmployees: 0,
        employees: [],
      },
      holidayIncompatibilities: [],
      holidayIncompatibilityName: '',
      employeeName: '',
      params: {},
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      searchName: '',
      deleteDialogShown: false,
      dataSynchronized: false,
      newHolidayIncompatibilityRules: {
        name: {
          required: true,
          validator: nameRule,
          error: false,
          trigger: "blur"
        },
        minNumberEmployees: {
          required: true,
          validator: minNumberEmployeesRule,
          error: false,
          trigger: "blur"
        },
        employees: {
          required: true,
          validator: employeesRule,
          error: false,
          trigger: "blur"
        },
      },
      selectedHolidayIncompatibility: {
        name: '',
        minNumberEmployees: 0,
        employees: [],
      },
      selectedEmployee: null,
      selectedHolidayIncompatibilityText: '',

      /* Estado de una incompatibiliodad
      statusOptions: [
          {id: 0, name: 'Activa', value: 1},
          {id: 1, name: 'Inactiva', value: 0}
      ],
      selectedStatusFilter:  {id: 0, name: 'Activa', value: 1},
      */

      // Modal de cofiguración/nueva incompatibilidad
      showModalConfigIncompatibility: false,
      // Modal de añadir empleados a la incompatibilidad
      showModalEmployees: false,
      searchNameModalEmployees: '',
      allEmployeesList: [],
      allEmployeesListTotal: 0,
    }
  },
  mounted() {
    this.getHolidayIncompatibilities();
  },
  methods: {
    async getHolidayIncompatibilities(filters) {
      this.params = {
        ...this.params,
        filter: filters,
        page: this.page,
        itemsPerPage: this.itemsPerPage
      };
      this.$store.state.loading = true;
      this.dataSynchronized = false;
      await HolidayIncompatibleService.getHolidayIncompatibilities(this.params)
          .then((res) => {
            this.holidayIncompatibilities = res.data['hydra:member'];
            this.totalResults = res.data['hydra:totalItems'];
          })
          .catch(() => {
            Notification.addNotification('Error al obtener las incompatibilidades', 'error', 6000);
          })
      this.$store.state.loading = false;
      this.dataSynchronized = true;
    },
    filterHolidayIncompatibilityByName(name) {
      if (name && name.length > 0) {
        name = name.toLowerCase();
        this.params = {
          ...this.params,
          name: name,
        };
      } else {
        delete this.params.name;
      }
      this.getHolidayIncompatibilities();
    },
    filterHolidayIncompatibilityByEmployeeName(name) {
      if (name && name.length > 0) {
        name = name.toLowerCase();
        this.params = {
          ...this.params,
          orSearch_fullName: name,
        };
      } else {
        delete this.params.orSearch_fullName;
      }
      this.getHolidayIncompatibilities();

    },
    deleteDialog(holidayIncompatibility, employee) {
      this.selectedHolidayIncompatibility = holidayIncompatibility;
      this.selectedEmployee = employee;
      this.deleteDialogShown = true;
      this.selectedHolidayIncompatibilityText = this.selectedHolidayIncompatibility.name + ' ' + this.selectedEmployee.name + ' ' + this.selectedEmployee.lastName;
      // this.deleteHolidayIncompatibility(holidayIncompatibility, employee);
    },
    deleteSelectedHolidayIncompatibility(holidayIncompatibility, employee) {
      if (this.selectedHolidayIncompatibility.employees.length === 1) {
        this.deleteHolidayIncompatibility(this.selectedHolidayIncompatibility);
      } else {
        let employees = holidayIncompatibility.employees.filter(function (emp) {
          return emp['@id'] !== employee['@id'];
        })

        holidayIncompatibility.employees = employees;
        this.saveHolidayIncompatibility(holidayIncompatibility);
      }
    },
    async deleteHolidayIncompatibility(holidayIncompatibility) {
      this.$store.state.loading = true;
      this.holidayIncompatibilities = []
      await HolidayIncompatibleService.deleteHolidayIncompatibility(holidayIncompatibility.id)
          .then(() => {
            Notification.addNotification('Incompatibilidad eliminada correctamente.', 'success', 6000);
          })
          .catch(() => {
            Notification.addNotification('Error al intentar eliminar la incompatibilidad seleccionado.', 'success', 6000);
          })
      this.$store.state.loading = false;
      this.selectedHolidayIncompatibility = {};
      this.selectedEmployee = null;
      this.deleteDialogShown = false;
      this.getHolidayIncompatibilities();
    },
    async updateHolidayIncompatibility(holidayIncompatibility) {
      this.$store.state.loading = true;
      await HolidayIncompatibleService.updateHolidayIncompatibility(holidayIncompatibility, holidayIncompatibility.id)
          .then(() => {
            Notification.addNotification('Incompatibilidad actualizada correctamente', 'success', 6000);
            this.selectedHolidayIncompatibility = {};
            this.selectedEmployee = {};
            this.deleteDialogShown = false;
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar la incompatibilidad', 'error', 6000);
          });
      this.$store.state.loading = false;
      await this.getHolidayIncompatibilities();

    },
    async createHolidayIncompatible(holidayIncompatibility) {
      this.$store.state.loading = true;
      await HolidayIncompatibleService.createHolidayIncompatibility(holidayIncompatibility)
          .then(() => {
            Notification.addNotification('Incompatibilidad creada correctamente', 'success', 6000);
          })
          .catch(() => {
            Notification.addNotification('Error al crear la incompatibilidad', 'error', 6000);
          });
      this.$store.state.loading = false;
      await this.getHolidayIncompatibilities();
    },
    openModalConfigHolidayIncompatibility(holidayIncompatibility) {
      if (holidayIncompatibility && holidayIncompatibility.id) {
        this.selectedHolidayIncompatibility = holidayIncompatibility;
      } else {
        this.selectedHolidayIncompatibility = {
          name: '',
          minNumberEmployees: 0,
          employees: [],
        }
      }

      this.showModalConfigIncompatibility = true;
    },
    closeModalConfigHolidayIncompatibility() {
      this.selectedHolidayIncompatibility = {};
      this.showModalConfigIncompatibility = false;

      this.newHolidayIncompatibilityRules.name.error = false;
      this.newHolidayIncompatibilityRules.minNumberEmployees.error = false;
      this.newHolidayIncompatibilityRules.employees.error = false;
      this.getHolidayIncompatibilities();
    },
    requestSaveConfigHolidayIncompatibility(holidayIncompatible) {
      this.saveHolidayIncompatibilityWithFormValidation(holidayIncompatible);
    },
    addEmployeeFromConfigModal() {
      this.openModalEmployees();
    },
    deleteEmployeeFromConfigModal(selectedHolidayIncompatibility, employee) {
      this.selectedHolidayIncompatibility.employees = selectedHolidayIncompatibility.employees.filter(function (emp) {
        return emp['@id'] !== employee['@id'];
      });
    },
    async openModalEmployees() {
      await this.getEmployees();
      this.showModalEmployees = true;
    },
    closeModalEmployees() {
      this.showModalEmployees = false;
    },
    isEmployeeSelected(itemSelected) {
      if (this.selectedHolidayIncompatibility.employees) {
        return this.selectedHolidayIncompatibility.employees.some(member => member.id === itemSelected.id);
      }
      return false;
    },
    addEmployeeToConfigIncompatibility(employee) {
      if (this.selectedHolidayIncompatibility != null) {
        if (this.selectedHolidayIncompatibility.employees) {
          let existMember = this.selectedHolidayIncompatibility.employees.findIndex(member => member.id === employee.id);
          if (existMember == -1) {
            this.selectedHolidayIncompatibility.employees.push(employee);
            Notification.addNotification('Empleado añadido', 'success', 6000);
          } else {
            this.selectedHolidayIncompatibility.employees.splice(existMember, 1);
            Notification.addNotification('Empleado eliminado', 'warning', 6000);
          }
        } else {
          this.selectedHolidayIncompatibility.employees.push(employee);
          Notification.addNotification('Empleado añadido', 'success', 6000);
        }
      }
    },
    filterEmployeesByName(name) {
      delete this.params.name;
      if (name && name.length > 0) {
        name = name.toLowerCase();

        this.params = {
          ...this.params,
          orSearch_fullName: name
        };
      } else {
        delete this.params['orSearch_fullName'];
      }

      this.page = 1;
      this.getEmployees();
      delete this.params.name;
    },
    getEmployees() {
      delete this.params.name;
      this.params = {
        ...this.params,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        active: true
      };
      this.$store.state.loading = true;
      EmployeeService.getEmployees(this.params)
          .then((res) => {
            this.$store.state.loading = false;
            this.allEmployeesList = res.data['hydra:member'];
            this.allEmployeesListTotal = res.data['hydra:totalItems'];
          })
          .catch(() => {
            this.$store.state.loading = false;
            Notification.addNotification('Error al obtener los empleados', 'error', 6000);
          });
    },
    saveHolidayIncompatibilityWithFormValidation(holidayIncompatibility) {
      this.$refs["newHolidayIncompatibilityForm"].validate(valid => {
        if (valid) {
          this.saveHolidayIncompatibility(holidayIncompatibility);
        } else {
          return false;
        }
      })
    },
    saveHolidayIncompatibility(holidayIncompatibility) {
      if (holidayIncompatibility.id) {
        let employees = [];
        holidayIncompatibility.employees.forEach(employee => {
          employees.push(employee['@id']);
        });
        holidayIncompatibility.employees = employees;
        this.dataSynchronized = false;
        this.updateHolidayIncompatibility(holidayIncompatibility);
      } else {
        let employees = [];
        holidayIncompatibility.employees.forEach(employee => {
          employees.push(employee['@id']);
        });
        holidayIncompatibility.employees = employees;
        this.createHolidayIncompatible(holidayIncompatibility);
      }
      this.closeModalConfigHolidayIncompatibility();
    },
    search(){
      this.params.name = this.holidayIncompatibilityName
          ? this.holidayIncompatibilityName.toLowerCase() : null;
      this.params.orSearch_fullName = this.employeeName
          ? this.employeeName.toLowerCase() : null;
      // Perform the search with the parameters
      this.getHolidayIncompatibilities();
    }
  }
}
</script>

<style lang="scss" scoped>

.holiday-incompatibilities {
  .label-holiday-incompatibility {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }
}


.table-holiday-incompatibilities {
  padding-top: 40px;
  /*display: inline-block;*/
  table {

    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        background-color: white;
        text-align: left;

        .link-name {
          margin: auto;
          margin-left: 10px;
        }

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}


.modal-config-label {
  font-size: 20px;
  font-weight: bold;
}

.item-modal {
  margin-top: 5px;
}

.form-item {
  margin-bottom: 5px !important;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.modal-add-employee-icon {
  margin-left: 20px;
  color: green;
}

.modal-remove-employee-icon {
  color: red;
}

.modal-table-incompatibilities {
  margin-bottom: 40px;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        background-color: white;
        text-align: left;
      }
    }
  }
}

.modal-table-employees {
  padding-top: 10px;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px;
      padding-left: 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {
      .selected {
        background-color: rgba(0, 0, 0, .06);

        td {
          background-color: rgba(0, 0, 0, .06);
        }
      }

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}

.custom-checkbox {
  text-align: end;
}

.error-validation {
  border: 2px solid #F56C6C;
}
</style>
