<script setup>

import {More} from "@element-plus/icons-vue";
import {computed, defineEmits} from "vue";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import {ElMessageBox} from "element-plus";
import notification from "@/utils/plugins/NotificationService";
import TrackerService from "@/api/services/tracker.service";
import Clock from "@/components/shared/Clock.vue";

const emits = defineEmits(['reloadEntries']);
// eslint-disable-next-line no-undef
const props = defineProps({
  entry: Object
})

// eslint-disable-next-line no-unused-vars
const minutes = computed(() => {
  const hours = Math.floor(props.entry.duration / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((props.entry.duration % 3600) / 60).toString().padStart(2, '0');
  const remainingSeconds = (props.entry.duration % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}:${remainingSeconds}`;
});

const duplicate = async () => {
  await TrackerService.duplicateEntry(props.entry.id).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const split = async () => {
  await TrackerService.splitEntry(props.entry.id).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const stopTimeEntry = async () => {
  let entry = {...props.entry};
  entry.project = entry.project['@id'];
  let tags = [];
  entry.tags.forEach((tag) => {
    tags.push(tag['@id']);
  });
  entry.tags = tags;
  entry.timeEnd = new Date();
  await TrackerService.stopEntry(entry).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const discard = () => {
  ElMessageBox.confirm(
      '¿Estás seguro de descartar esta entrada?',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
  )
      .then(async () => {
        const id = props.entry.id;
        await TrackerService.discardEntry(id);
        emits('reloadEntries');
        notification.addNotification("La entrada se ha eliminado","success",10000)
      })
      .catch(() => {

      })

}
</script>

<template>

  <el-row>
  <el-col :span="18">
        <div class="entry-info">
          <div class="tracker-description">{{ props.entry.description }}</div>
          <div class="tracker-project">
            <tracker-project-info :project="props.entry.project"/>
          </div>
          <div class="tracker-tag" v-for="tag in props.entry.tags" :key="tag.id">{{ tag.name}}</div>
          <div class="tracker-button" style="margin-left: auto;" v-if="props.entry.billable">
            <span
                class="w-10 tracker-button"
                :class="props.entry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
            >
              €
            </span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="entry-info">
          <el-divider direction="vertical"></el-divider>
          <clock />
          <el-divider direction="vertical"></el-divider>
          <el-button
              type="danger"
              class="w-10"
              @click="stopTimeEntry"
          >
            Detener
          </el-button>
          <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon class="el-icon--right" style="height: 30px;">
                        <more  style="transform: rotate(90deg);color:#1D1D1B80;" />
                      </el-icon>
                    </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="split">Dividir</el-dropdown-item>
                <el-dropdown-item @click="duplicate">Duplicar</el-dropdown-item>
                <el-dropdown-item @click="discard" style="color:red;">Descartar</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
  </el-row>
</template>

<style scoped lang="scss">
 .tracker-button {
   font-size: 20px;
 }
</style>