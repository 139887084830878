<template>
  <div class="buttons pointer">
    <font-awesome-layers
      class="fa-2x"
      style="margin-left: 5px"
      :title="title"
      @click="onClick()"
    >
      <font-awesome-icon
        :icon="['fas', 'circle']"
        :style="{color: color, width: '28px'}"
      />
      <font-awesome-icon
        :icon="['fas', icon]"
        class="fa-inverse"
        style="width:20px"
        transform="shrink-6"
      />
    </font-awesome-layers>
  </div>
</template>
<script>
export default {
  name: "SharedButtonPlus",
  props: {
    title: {
      type: String,
      default: "title",
    },
    Object: Object,
    color: {
      type: String,
      default: "#f7d20e",
    },
    icon: {
      type: String,
      default: "plus",
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>
<style scoped>
</style>
