<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import Notification from "@/utils/plugins/NotificationService";
import TicketService from "@/api/services/ticket.service";
import TicketStatusService from "@/api/services/ticket.status.service";
import TicketTypeService from "@/api/services/ticket.type.service";
import Common from "@/api/services/common.service";
import UserTickets from "@/views/tickets/UserTickets.vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import {useStore} from "vuex";
import {computed, h, onMounted, ref, shallowRef} from "vue";
import {useRouter} from "vue-router";

const store = useStore();
const user = computed(() => store.state.user);
const router = useRouter();

// eslint-disable-next-line no-unused-vars
const optionsItemsPerPage = ref([
  {id: 0, name: '10', value: 10},
  {id: 1, name: '25', value: 25},
  {id: 2, name: '50', value: 50},
  {id: 3, name: '100', value: 100},
  {id: 4, name: '10000', value: 1000},
]);
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const itemsPerPage = ref({id: 4, name: '15', value: 15});
const page = ref(1);
const totalResults = ref(0);
const clientName = ref("");
// eslint-disable-next-line no-unused-vars
const customerName = ref("");
const tag = ref("");
const type = ref(null);
const types = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const orderDescription = ref(null);
const orderType = ref(null);
const orderStatus = ref(null);
const tickets = ref([]);
// eslint-disable-next-line no-unused-vars
const totalTickets = ref(0);
const statusTicket = ref(null);
const optionsStatus = ref([])



onMounted(async () => {
  await getTypes();
  await getStates();
  await getTickets();
})


const sumTotalTicket = (total) =>{
  totalResults.value +=total;
}
const getStates = async () => {
  const response = await TicketStatusService.getStates({itemsPerPage: 999999, page: 1});
  optionsStatus.value = response.data['hydra:member'];
}
const getTypes = async() =>{
  const response = await TicketTypeService.getTypes({itemsPerPage:999999,page:1});
  types.value = response.data['hydra:member'];
}
const search = () => {
  getTickets();
}
const gotoEdit = (id) => {
  router.push({name: 'update_ticket', params:{id:id}})
}
const ifIsInArray = (userRoles, role) => {
  // console.log("userRoles", userRoles);
  return Common.ifIsInArray(userRoles, role);
}
const typeColor = (type) => {
  if (type === 'Incidencia')
    return {color: '#F9156F', label: 'INCIDENCIA'};
  else if (type === 'Solicitud de servicio') {
    return {color: '#4AABC3', label: 'SERVICIO'};
  } else if (type === 'Solicitud de soporte (Duda)') {
    return {color: '#919191', label: 'SOPORTE'};
  }
  return 'grey'
}
const getTickets = () => {

  //console.log("Type tickets",type);
  const params = {
    itemsPerPage: itemsPerPage.value.value,
    page: page.value,
    'order[createdAt]': 'DESC',
    internal:false
  }
  if (clientName.value) {
    params['project.client.name'] = clientName.value;
  }
  if (statusTicket.value) {
    params['ticketStatusHistories.status.id'] = statusTicket.value;
    // if status is closed, we need to check if the ticket is still open
    if (statusTicket.value !== 5) {
      params['exists[ticketStatusHistories.dateEnd]'] =  false;
    }
  }
  if (type.value) {
    params['ticketType.id'] = type.value;
  }

  if (tag.value){
    params.tag = tag.value;
  }

  if (selectedFromDate.value && selectedToDate){
    params["createdAt[strictly_before]"] = selectedToDate.value;
    params["createdAt[strictly_after]"] = selectedFromDate.value;
  }

  store.dispatch('setLoading', true);
  TicketService.getTickets(params)
      .then((response) => {
        tickets.value = response.data['hydra:member'];
        tickets.value.forEach(aTicket => {
          let lastStatus = aTicket.ticketStatusHistories.find(item => {
            if (!item.dateEnd) {
              return item.status.name
            }
          })
          if (!lastStatus) {
            lastStatus = aTicket.ticketStatusHistories.find(item => {
              if (item.dateStart && item.dateEnd && item.status.name === "Cerrado") {
                return item.status.name
              }
            })
          }
          aTicket.lastStatus = lastStatus.status.name;
        })
        totalResults.value = response.data["hydra:totalItems"];
        store.dispatch('setLoading', false);
      })
      .catch((error) => {
        console.log(error);
        Notification.addNotification('Error al obtener listado de tickets.', 'error', 6000);
        store.dispatch('setLoading', false);
      });
}


</script>

<template>
  <div
      v-if="user && (ifIsInArray(user.roles, 'ROLE_ADMIN') || ifIsInArray(user.roles, 'ROLE_SYSTEMS'))"
      id="tickets"
  >
    <!--    <div class="absences" v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')">-->
    <header-title title="SISTEMAS" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Tickets
      </div>
    </div>

    <el-row>
      <el-card
          class="card-search w-100 wback"
          body-style="padding:0"
      >
        <div class="row mx-5">
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-4 w-100 label-common">
              <label for="domain-name" class="w-100">Cliente</label>
              <el-input
                  id="title"
                  v-model="clientName"
                  class="search-input"
                  placeholder="Nombre del cliente"
                  clearable
                  @change="search"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-4 w-100 label-common pl-5">
              <label for="ffrom" class="w-100">Fecha desde</label>
              <el-date-picker
                  id="ffrom"
                  v-model="selectedFromDate"
                  name="from-dat'"
                  type="date"
                  placeholder="Fecha desde"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
                  @change="search"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-4 w-100 label-common pl-5">
              <label for="fto" class="w-100">Fecha hasta</label>
              <el-date-picker
                  id="fto"
                  v-model="selectedToDate"
                  name="from-date"
                  type="date"
                  placeholder="Fecha hasta"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
                  @change="search"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-4 w-100 label-common pl-5">
              <label for="id-status" class="w-100">Estado</label>
              <el-select v-model="statusTicket"
                         filterable
                         clearable
                         placeholder="TODOS"
                         @change="search"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-4 w-100 label-common">
              <label for="domain-name" class="w-100">Código</label>
              <el-input
                  id="title"
                  v-model="tag"
                  class="search-input"
                  placeholder="Código"
                  clearable
                  @change="search"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item label-common">
              <label class="w-100 mt-4">Tipo</label>
              <el-select v-model="type"
                         filterable
                         clearable
                         placeholder="TODOS"
                         @change="search"
              >
                <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-4  col-md-6 d-flex flex-column justify-content-end align-items-end">
            <button
                class="btn btn_yellow btn-search"
                @click="search()"
            >
              Buscar
            </button>
          </div>
        </div>
      </el-card>
      <div class="mt-2" v-if="!store.state.loading">
        <span class="text-result">{{ totalResults }} Tickets </span>
        <span class="text-result-blue"> totales</span>
      </div>
    </el-row>
    <el-row>
      <div class="table table-domains">
        <table>
          <thead>
          <tr>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="">TIPO
                  <font-awesome-icon  v-if="orderType === 'ASC'" icon="sort-alpha-up" />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon v-else icon="sort" />
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center pointer">
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt=""> DESCRIPCIÓN  CORTA
                  <font-awesome-icon
                      v-if="orderDescription === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderDescription === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>

            <th>
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/pc.svg"
                      alt="">CLIENTE
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderType()"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/group.svg"
                      alt="estado"
                  >ESTADO
                  <font-awesome-icon
                      v-if="orderType === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderType === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
              >
                <div>
                  <img
                      width="21"
                      height="20"
                      class="mr-2"
                      src="@/assets/icons/user.svg"
                      alt="">ASIGNADO A
                  <font-awesome-icon
                      v-if="orderStatus === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon  v-else-if="orderStatus === 'DESC'"  icon="sort-alpha-down"/>
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>

          </tr>
          </thead>
          <tbody v-if="tickets && tickets.length && !store.state.loading">
          <tr
              v-for="ticket in tickets"
              :key="ticket.id"
          >
            <td>
              <div class="d-flex justify-content-start align-items-center">
                <div
                    class="type-color"
                    :style="{backgroundColor: typeColor(ticket.ticketType?.name).color}"
                /> {{ typeColor(ticket.ticketType?.name).label }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.issue }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ ticket.project?.client?.name }}
              </div>
            </td>
            <td>
              <div class="flex-row justify-content-between">
                {{ ticket.lastStatus }}
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span v-if="ticket.assignedEmployee"> {{ ticket.assignedEmployee?.name }}</span>
                <shared-button-plus
                    @onClick="gotoEdit(ticket.id)"
                    :title="'Editar ticket'"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-row>
    <el-row>
      <SharedPagination
          v-if="tickets && totalResults > itemsPerPage.value"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage.value"
          @change="page = $event;getTickets();"
      />
    </el-row>
    <el-row class="justify-content-end">
      <router-link
          :to="{name: 'new_ticket'}"
          class="btn btn_darkpink search btn-new"
      >
        NUEVO TICKET
      </router-link>
    </el-row>
    <el-row>
      <div class="d-flex flex-row justify-content-start">
        <img src="@/assets/icons/group.svg" width="30" alt="">
        <div class="title-text pl-4">
         Mis Tickets
        </div>
      </div>

    </el-row>
    <el-row>
      <UserTickets @totalTicket="sumTotalTicket" />
    </el-row>

  </div>
</template>

<style scoped lang="scss">

#tickets {
  .buttons {
    margin-left: auto;
  }
  .type-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .title-text {
    text-align: center;
    align-self: center;

    font-size: 3em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: $font-blue-color;
  }

  .card-search {
    border-radius: 15px;
    height: 250px;
    background: url("../../assets/icons/altavoz.png"), white;
    background-repeat: no-repeat;
    background-position-x: 100%;
    overflow: visible !important;
  }

  #icon-search {
    fill: $bg-blue;
    width: 6%;
  }

  .label-common {
    label {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: -2px;
    }
  }
  .btn-new {
    height: 45px;
    width: 300px;
    text-align: center;
    align-items: center;
    font-size: 20px;
  }

  .table-domains {
    table {
      thead {
        tr {
          th {
            padding: 0 !important;
            margin: 0 !important;
          }
        }

        #icon-search {
          fill: $bg-blue;
          width: 2.9em;
        }

        #icon-user {
          fill: $bg-blue;
          width: 1.6em;
        }
      }

      tbody {
        td {
          .link-name {
            margin: auto;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 2.2em;
    fill: #f9156f;
  }

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-header {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;
  }
  .text-result {
    text-align: left;
    font-size: 2em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;

  }
  .text-result-blue {
    text-align: left;
    font-size: 2em;
    font-weight:400;
    letter-spacing: -0.05em;
    color:$bg-blue;

  }
  .wback {
    background-image: url('../../assets/images/ticket-back.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
