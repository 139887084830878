<template>
  <div>
    <el-tabs v-model="activeTab">
      <el-tab-pane
        label="Total"
        name="first"
      >
        <el-row align="middle">
          <el-col v-if="projectsChartData" :span="24">
            <Doughnut :data="projectsChartData"
                      :options="chartOptions"
                      style="width: 100%;max-height: 400px" />
          </el-col>
        </el-row>
        
        <el-row>
          <el-col
              v-if="computedProjects.length"
              :span="24">
            <el-table
                :data="computedProjects"
                border
                :cell-style="cellStyle"
            >
              <el-table-column
                  label="Cliente"
                  prop="clientName"
              />
              <el-table-column
                  label="Proyecto"
                  prop="name"
              />
              <el-table-column label="Tiempo">
                <template #default="scope">
                  {{ toHours(scope.row.totaltime) }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        
      </el-tab-pane>
      <el-tab-pane
        label="Actualmente"
        name="second"
      >
        <el-row>
          <el-col
              v-if="currentProjectsChartData"
              :span="24">
            <Doughnut :data="currentProjectsChartData" 
                      :options="chartOptions"
                      style="width: 100%;max-height: 400px"
            />
          </el-col>
        </el-row>
        
        <el-row>
          <el-col
              v-if="computedCurrentProjects.length"
              :span="24">
            <el-table
                :data="computedCurrentProjects"
                border
                :cell-style="cellStyle"
            >
              <el-table-column
                  label="Cliente"
                  prop="clientName"
              />
              <el-table-column
                  label="Proyecto"
                  prop="name"
              />
              <el-table-column label="Tiempo">
                <template #default="scope">
                  {{ toHours(scope.row.totaltime) }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
  import _ from 'lodash'
  import {onMounted} from "vue";
  import {computed, defineProps, ref} from 'vue';
  import {toHours} from "@/views/production/mixin/common";
  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  ChartJS.register(ArcElement, Tooltip, Legend)

 const props =  defineProps({
    projects: {
      type: Array,
    },
    currentProjects: {
      type: Array,
    }
  })

   const activeTab = ref('first')
  const projectsChartData = ref(null)
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false
  }
  const currentProjectsChartData = ref(null)

  const computedProjects = computed(() => {
    return Object.values(props.projects)
  })
  
  const computedCurrentProjects = computed(() => {
    return Object.values(props.currentProjects)
  })

    onMounted(() => {
      fillData();
    })

  // eslint-disable-next-line no-unused-vars
    const  drawTooltipFunction = (tooltipItem, data) => {
        return data.labels[tooltipItem.index] +  ': ' +toHours(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
      }


    const  cellStyle = ({row, columnIndex}) =>{
        if(columnIndex === 0) {
          return {'border-left': row.color + ' solid 5px'}
        }
        return {}
      }

      const fillData = () => {
        let projects = props.projects;
        projectsChartData.value = {
          labels: _.map(projects, p => p.name+1),

          datasets: [
            {
              backgroundColor: _.map(projects, p => p.color),
              data: _.map(projects, p => p.totaltime)
            }
          ]
        };

        let currentProjects = props.currentProjects
        currentProjectsChartData.value = {
          labels: _.map(currentProjects, p => p.name),

          datasets: [
            {
              backgroundColor: _.map(currentProjects, p => p.color),
              data: _.map(currentProjects, p => p.totaltime)
            }
          ]
        };

  }
</script>

<style lang="scss">

</style>