<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import {computed, onMounted, ref} from "vue";
import ProjectsService from "@/api/services/projects.service";
import TimeEntryTagService from "@/api/services/timeEntryTag.service";
import {useStore} from "vuex";
import {More} from "@element-plus/icons-vue";
import TrackerService from "@/api/services/tracker.service";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import moment from "moment";
import TrackerDay from "@/components/shared/TrackerDay.vue";
import TrackerTotalTime from "@/components/shared/TrackerTotalTime.vue";
import TrackerCurrentEntry from "@/components/shared/TrackerCurrentEntry.vue";

const store = useStore();

const timeEntry = ref({
  description: null,
  project: null,
  tags: [],
  timeStart: new Date(),
  timeEnd: null,
  billable: true,
});
const page = ref(1);
const itemsPerPage = ref(9999);
const archived = ref(false);
const optionProjects = ref([]);
const optionTags = ref([]);
const entries = ref([]);
const currentEntry = ref(null);


onMounted(() => {
  getProjects();
  getTags();
  getEntries();
   groupEntriesByWeek.value.forEach((week) => {
     console.log(week);
   })
})

const totalTimeCurrentWeek = computed(() => {
  let total = 0;
  const currentWeek = moment().week();
  if (entries.value.length === 0) return 0;
  entries.value.forEach((entry) => {
    if (moment(entry.timeStart).week() === currentWeek) {
      if(entry.duration){
        total += entry.duration
      }else{
        total += moment().diff(entry.timeStart, 'seconds')
      }
    }
  });
  return total;
});
const groupEntriesByWeek = computed(() => {
  const groupedByWeek = {};
  if(entries.value.length === 0) return []
  entries.value.forEach(entry => {
    const weekOfYear = moment(entry.timeStart).week();
    if (!groupedByWeek[weekOfYear]) {
      groupedByWeek[weekOfYear] = [];
    }
    entry.number = weekOfYear;
    groupedByWeek[weekOfYear].push(entry);
  });

  return Object.keys(groupedByWeek).map(week => ({
    week: parseInt(week),
    entries: groupedByWeek[week]
  })).sort((a, b) => b.week - a.week);
})

const groupEntriesByDay = computed(() => {
  const groupedByDay = {};
  if (entries.value.length === 0) return [];
  entries.value.forEach(entry => {
    const day = moment(entry.timeStart).format('YYYY-MM-DD');
    if (!groupedByDay[day]) {
      groupedByDay[day] = [];
    }
    entry.day = day; // Optionally add the day to the entry for easy reference
    groupedByDay[day].push(entry);
  });

  // Convert to an array and sort by day if needed
  return Object.keys(groupedByDay).map(day => ({
    day,
    entries: groupedByDay[day]
  })).sort((a, b) => b.day.localeCompare(a.day)); // Sort by day in ascending order
});

const getEntries = () => {
  currentEntry.value = null;
  TrackerService.getEmployeeEntries(store.state.user.id).then((response) => {
     entries.value = response.data['hydra:member'];
     entries.value.map((entry) => {
       if(!entry.timeEnd){
         currentEntry.value = entry;
       }
     })
   })
}

const getProjects = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    archived: archived.value,
    'order[initialDate]': 'DESC'
  }
  store.state.loading = true;
  ProjectsService.getProjects(params)
      .then((response) => {
        optionProjects.value = response.data['hydra:member'];
      })
      .catch((error) => {
        console.log(error);
        Notification.addNotification(
            "Error al cargar los proyectos"
        );
      })
      .finally(() => {
        store.state.loading = false;
      });
}
const getTags = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
  }
  params['order[name]'] = 'ASC';
  store.state.loading = true;
  TimeEntryTagService.getTags(params)
      .then((response) => {
        optionTags.value = response.data['hydra:member'];
      })
      .catch((error) => {
        console.log(error);
        Notification.addNotification(
            "Error al cargar las etiquetas"
        );
      })
      .finally(() => {
        store.state.loading = false;
      });

}
// eslint-disable-next-line no-unused-vars
const clearEntry = () => {
  timeEntry.value = {
    description: '',
    project: null,
    tag: [],
    timeStart: '',
    timeEnd: '',
    billable: false,
  };
}
const saveTimeEntry = () => {
  TrackerService.createEntry({
    description: timeEntry.value.description,
    project: timeEntry.value.project['@id'],
    tags: timeEntry.value.tags.map(tag => tag['@id']),
    timeStart: timeEntry.value.timeStart,
    timeEnd: timeEntry.value.timeEnd,
    billable: timeEntry.value.billable,
    employee: store.state.user['@id']
  }).then((response) => {
    currentEntry.value = response.data;
  })
}
</script>

<template>
  <div class="tracker-net">
    <HeaderTitle title="Talento"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="@/assets/icons/tracker-net-blue.svg"
          alt=""
      >
      <div
          class="title-text ml-4">
        TrackerNet
      </div>
    </div>
    <el-row class="mt-2">
      <el-card v-if="currentEntry" class="card-search w-100" >
          <tracker-current-entry :entry="currentEntry" @reload-entries="getEntries"/>
      </el-card>
      <el-card v-else class="card-search w-100" >
        <div class="row">
          <div class="col-9">
            <el-input
                v-model="timeEntry.description"
                placeholder="En qué estás trabajando?"
                clearable
            ></el-input>
          </div>
          <div class="col-3">
            <el-select
                :value-key="'@id'"
                clearable
                filterable
                placeholder="Proyecto"
                v-model="timeEntry.project"
                class="tracker-select"
            >
              <el-option
                  v-for="project in optionProjects"
                  :key="project['@id']"
                  :label="project.name"
                  :value="project"
              >
                <div>
                   <tracker-project-info :project="project"/>
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-7">
            <el-select
                :value-key="'@id'"
                clearable
                filterable
                multiple
                placeholder="Etiqueta"
                v-model="timeEntry.tags"
                class="tracker-select"
            >
              <el-option
                  v-for="tag in optionTags"
                  :key="tag['@id']"
                  :label="tag.name"
                  :value="tag"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-1 text-center p-2" style="border-left: 1px solid #3333;">
              <el-button
                  class="w-10 tracker-button"
                  :class="timeEntry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
                  @click="timeEntry.billable = !timeEntry.billable"
              >
                €
              </el-button>
          </div>
          <div class="col-4">
            <div class="row">
             <!-- <div class="col-6 tracker-select">
                <el-row :gutter="10">
                   <el-col :span="12">
                     <el-time-picker
                         v-model="timeEntry.timeStart"
                         placeholder=""
                         format="HH:mm"
                         value-format="HH:mm"
                     />
                    </el-col>
                    <el-col :span="12">
                      <el-time-picker
                          v-model="timeEntry.timeEnd"
                          placeholder=""
                          format="HH:mm"
                          value-format="HH:mm"
                      />
                    </el-col>
                </el-row>
              </div> -->
              <div class="col-3 text-center p-2">
                <el-button
                    type="primary"
                    class="w-10"
                    @click="saveTimeEntry"
                >
                  Añadir
                </el-button>
              </div>
              <div class="col-3 text-center p-2">
                <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon class="el-icon--right" style="height: 30px;">
                        <more  style="transform: rotate(90deg);color:#1D1D1B80;" />
                      </el-icon>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>Dividir</el-dropdown-item>
                      <el-dropdown-item style="color: red;">Descartar</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>


        </div>
      </el-card>

      <div class="card-label">
        <span>ESTA SEMANA</span>
        <tracker-total-time title="Total semanal" :totalTime="totalTimeCurrentWeek" />
      </div>
        <tracker-day
            v-for="day in groupEntriesByDay"
            :key="day+Math.random()"
            :day="day"
            @reload-entries="getEntries"

        />

    </el-row>

  </div>
</template>

<style scoped lang="scss">

.tracker-net {
  margin-bottom: 20px;
}

.card-search {
  height: auto;
  border-radius: 15px;
  max-height: 200px;
  background: white;
  background-position-x: 100%;
  overflow: visible !important;
}

.tracker {
  width: 100%;
  //border: 0 !important;
  //border-radius: 0 !important;
}

//.el-select__selection {
//  border: none !important;
//  border-radius: 0 !important;
//}

//input{
//  width: 100%;
//  border: none !important;
//  border-radius: 0 !important;
//}

.el-select {
  border: none !important;
  border-radius: 0 !important;
  .el-select__wrapper {
    border: none !important;
    border-radius: 0 !important;
  }
}

.el-select__input {
  border: none !important;
  border-radius: 0 !important;
}


</style>
