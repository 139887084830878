<script setup>

import {More} from "@element-plus/icons-vue";
import {computed, defineEmits, ref,onMounted} from "vue";
import moment from "moment";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import {ElMessageBox} from "element-plus";
import notification from "@/utils/plugins/NotificationService";
import TrackerService from "@/api/services/tracker.service";

const emits = defineEmits(['reloadEntries']);
// eslint-disable-next-line no-undef
const props = defineProps({
  entry: Array,
  isChild: {
    type: Boolean,
    default: false
  }
})

const changeTime = ref(false);
const timeEntry = ref({
  startTime: '',
  endTime: ''
});
const showEntries = ref(false);

onMounted(() => {
  timeEntry.value.startTime = moment(firstEntry.value.timeStart).format('HH:mm');
  timeEntry.value.endTime = moment(lastEntry.value.timeEnd).format('HH:mm');
})

const firstEntry = computed(() => {
     return props.entry[0]
})
const lastEntry = computed(() => {
  return props.entry[props.entry.length - 1]
})
const entries = computed(() => {
  return props.entry;
})

const minutes = computed(() => {
  let hours = 0;
  let minutes = 0;
  let remainingSeconds = 0;
  props.entry.map((entry) => {
    if(entry.duration){
       hours =  hours + Math.floor(entry.duration / 3600);
       minutes =  minutes + Math.floor((entry.duration % 3600) / 60);
       remainingSeconds = remainingSeconds + (entry.duration % 60);
    }else{
       hours = hours + Math.floor(moment().diff(entry.timeStart.split('T')[0], 'seconds') / 3600);
       minutes = minutes + Math.floor((moment().diff(entry.timeStart.split('T')[0], 'seconds') % 3600) / 60);
       remainingSeconds = remainingSeconds + (moment().diff(entry.timeStart.split('T')[0], 'seconds') % 60);
    }
  })

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;



});

const timeInfo = computed(() => {
  let start = moment(firstEntry.value.timeStart);
  let end = new moment(firstEntry.value.timeEnd);

  return `${start.format('hh:mmA')} - ${ end.format('hh:mmA')}`;
});

const openCalendar = () => {
  alert('open calendar ' +firstEntry.value.id);
}
const startTimer = async () => {
  let entry = { ...firstEntry.value }
  entry.project = firstEntry.value.project['@id'];
  let tags = [];
  entry.tags.forEach((tag) => {
    tags.push(tag['@id']);
  });
  delete entry['@id'];
  delete entry.id;
  delete entry.duration;
  delete entry.timeEnd;
  entry.tags = tags;
  entry.timeStart = new Date();

  await TrackerService.createEntry(entry).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const duplicate = async () => {
  await TrackerService.duplicateEntry(firstEntry.value.id).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const reloadEntries = () => {
  emits('reloadEntries');
}
const split = async () => {
  await TrackerService.splitEntry(firstEntry.value.id).then(() => {
    emits('reloadEntries');
  }).catch(() => {

  })
}
const discard = () => {
  ElMessageBox.confirm(
      '¿Estás seguro de descartar esta entrada?',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
  )
      .then(async () => {
        const id = firstEntry.value.id;
        await TrackerService.discardEntry(id);
        emits('reloadEntries');
        notification.addNotification("La entrada se ha eliminado","success",10000)
      })
      .catch(() => {

      })

}

const toggleEntries = () => {
  showEntries.value = !showEntries.value;
}
</script>

<template>
  <div v-if="firstEntry">
    <el-row>
      <el-col :span="16">
        <div class="entry-info">
          <div class="tracker-amount pointer" v-if="!isChild" @click="toggleEntries">{{ entry.length }}</div>
          <div class="tracker-description">{{ firstEntry.description }}</div>
          <div class="tracker-project">
            <tracker-project-info :project="firstEntry.project"/>
          </div>
          <div class="tracker-tag" v-for="tag in firstEntry.tags" :key="tag.id">{{ tag.name}}</div>
          <div class="tracker-button" style="margin-left: auto;" v-if="firstEntry.billable">
            <span
                class="w-10 tracker-button"
                :class="firstEntry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
            >
              €
            </span>
          </div>

        </div>
      </el-col>
      <el-col :span="8">
        <div class="entry-info">
          <el-divider direction="vertical"></el-divider>
          <div class="tracker-date" :class="changeTime ? 'tracker-date--editing' : ''">
            <span v-if="!changeTime" @click="changeTime = !changeTime">{{timeInfo}}</span>
            <div class="row" v-else>
              <div class="tracker-select">
                <el-row>
                  <el-col :span="12">
                    <el-time-picker
                        v-model="timeEntry.startTime"
                        placeholder=""
                        format="HH:mm"
                        value-format="HH:mm"
                    />
                  </el-col>
                  <el-col :span="12">
                    <el-time-picker
                        v-model="timeEntry.endTime"
                        placeholder=""
                        format="HH:mm"
                        value-format="HH:mm"
                    />
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <img class="tracker-calendar" @click="openCalendar" src="@/assets/icons/calendar-icon.svg" alt="calendar-icon" >
          <div class="tracker-dtime">{{minutes}}</div>
          <el-divider direction="vertical"></el-divider>
          <img @click="startTimer" src="@/assets/icons/play-blue.svg" width="22" height="25" alt="play-icon" >
          <el-divider direction="vertical"></el-divider>
          <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon class="el-icon--right" style="height: 30px;">
                        <more  style="transform: rotate(90deg);color:#1D1D1B80;" />
                      </el-icon>
                    </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="split">Dividir</el-dropdown-item>
                <el-dropdown-item @click="duplicate">Duplicar</el-dropdown-item>
                <el-dropdown-item @click="discard" style="color:red;">Descartar</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
    <transition name="fade">
    <div v-if="showEntries && entry.length >1" class="pl-3">
      <tracker-entry
          v-for="entry in entries"
          :entry="[entry]"
          :key="entry.id+ Math.random()"
          @reload-entries="reloadEntries"
          :is-child="true"
      />
    </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
 .tracker-button {
   font-size: 20px;
 }
 .pl-3 {
    padding-left: 2.5rem;
 }
 .fade-enter-active, .fade-leave-active {
   transition: opacity 0.5s;
 }
 .fade-enter, .fade-leave-to {
   opacity: 0;
 }
 .pointer {
   cursor: pointer;
 }
</style>