<template>
  <div v-if="projectModel">
    <div class="d-flex flex-row justify-content-between my-2 label-common">
      <div class="title-text">
        Proyectos
      </div>
      <div class="form-item date-picker" style="margin-left: 25px;">
        <label
          class="w-100"
        >Fecha de inicio</label>
        <el-date-picker
          v-model="projectModel.initialDate"
          type="date"
          placeholder="Fecha Inicio"
          value-format="YYYY-MM-DD"
          :prefix-icon="'el-icon-date'"
          :picker-options="{ firstDayOfWeek: 1 }"
          :readonly="true"
        />
      </div>
    </div>
    <el-row>
      <el-card
        class="card-search w-100"
        body-style="padding:0"
      >
        <el-card-body>
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Nombre</label>
                <el-input
                  v-model="projectModel.name"
                  class="search-input"
                  :color="'light'"
                  :placeholder="'Ingrese nombre del proyecto'"
                  :readonly="true"
                />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Horas contratadas</label>
                <el-input
                  v-model="contractedTime"
                  class="search-input"
                  color="light"
                  :placeholder="'0.00'"
                  :readonly="true"
                />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <label class="w-100">Cliente</label>
                <el-select
                  v-model="projectModel.client"
                  class="search-input"
                  color="light"
                  placeholder="Todos"
                  :value-key="'@id'"
                  disabled
                >
                  <el-option
                    v-for="item in optionsClient"
                    :key="item.id"
                    :label="item.name || 'Nameless'"
                    :value="item"
                  />
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Tipo</label>
                <el-select
                  v-model="projectModel.projectType"
                  class="w-100"
                  color="light"
                  placeholder="TODOS"
                  disabled
                >
                  <el-option
                    v-for="item in optionsType"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <label class="w-100">Etiqueta</label>
                <el-input
                  v-model="projectModel.tag"
                  class="search-input"
                  :color="'light'"
                  :placeholder="'Ingrese la etiqueta'"
                />
              </div>
            </el-col>
          </el-row>
        </el-card-body>
      </el-card>
    </el-row>

    <!-- Rompiendo de aqui para abajo -->

    <el-row>
      <el-col
        :span="24"
        class="label-common"
      >
        <label>Observaciones</label>
        <textarea
          v-model="projectModel.observations"
          class="w-100 p-3"
          rows="5"
          @change="onChangeObs"
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="14">
        <div class="d-flex flex-column">
          <div
            class="
              d-flex
              flex-row flex-nowrap
              mb-4
              justify-content-start
              align-items-center
              label-common
            "
          >
            <label class="w-50 mr-3">&Uacute;ltima sincronizaci&oacute;n</label>
            <el-date-picker
              v-model="projectModel.lastSynchronization"
              class="w-75"
              :name="'from-date'"
              type="datetime"
              placeholder="Fecha Inicio"
              value-format="yyyy-MM-dd HH:mm:ss"
              :prefix-icon="'el-icon-date'"
              :picker-options="{ firstDayOfWeek: 1 }"
              :readonly="true"
            />
          </div>
          <div
            class="d-flex flex-row flex-nowrap align-items-center label-common"
          >
            <label class="w-75">Horas consumidas</label>
            <el-input
              v-model="consumedTime"
              class_div_col_input="w-100 pr-3"
              :color="'light'"
              :placeholder="'0.00%'"
              :readonly="true"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="d-flex justify-content-end label-common">
          <label class="align-self-end mb-4 pb-3">% Horas consumidas</label>
          <div class="text-porciento ml-4">
            {{ consumedTimePercent }}
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="d-flex justify-content-end mt-5">
      <button
        class="btn btn-block btn_red m-2 btn-action"
        @click="() => $router.push({ name: 'projects' })"
      >
        Volver
      </button>
      <button
        type="primary"
        class="btn btn-block btn_yellow m-2 btn-action"
        @click="onSubmit"
      >
        Guardar
      </button>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import SharedInput from "@/components/shared/SharedInput.vue";
import ProjectsService from "@/api/services/projects.service";
import Notification from "@/utils/plugins/NotificationService";
import {computed, onMounted, ref,defineProps, defineEmits} from "vue";
import {useStore} from "vuex";

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
});
const store = useStore();
const emits = defineEmits(['change']);
const isDisable = ref(false);
const optionsClient = ref([]);
const optionsType = ref([]);

const projectModel = ref(null)

onMounted(() => {
  getTypes();
  getClients();
  projectModel.value = props.project;
})
// eslint-disable-next-line no-unused-vars
   const nrohoras = computed(() => {
      if (!projectModel.value.contractedTime) return 0.0 + " h";
      const hours = (projectModel.value.contractedTime / 3600).toFixed(2);
      return hours + " h";
    })
   const consumedTimePercent = computed(() => {
      if (!projectModel.value.consumedTime) return 0.0 + "%";
      if (!projectModel.value.contractedTime) return 0.0 + "%";
      console.log("Porciento");
      const hcosumend = projectModel.value.consumedTime / 3600;
      const hcontract = projectModel.value.contractedTime / 3600;
      const porc = (hcosumend * 100) / hcontract;
      return porc ? Math.floor(porc) + "%" : 0 + "%";
    })
   const consumedTime = computed(() => {
      if (!projectModel.value.consumedTime || projectModel.value.consumedTime === 0)
        return "0 h";

      const hours = (projectModel.value.consumedTime / 3600).toFixed(2);

      return hours + " h";
    })
    const contractedTime = computed(() => {
      if (!projectModel.value.contractedTime || projectModel.value.contractedTime === 0)
        return "0 h";

      const hours = (projectModel.value.contractedTime / 3600).toFixed(2);

      return hours + " h";
    })
  


// eslint-disable-next-line no-unused-vars
    const tableRowClassName = (param) => {
      if (param.rowIndex % 2 === 0) {
        console.log("warning-row", param.rowIndex);
        return "warning-row";
      } else console.log("success-row", param.rowIndex);
      return "success-row";
    }
    const getTypes = () => {
      ProjectsService.getTypes()
        .then((result) => {
         optionsType.value = result.data["hydra:member"];
        })
        .catch((err) => {
          console.error("Types", err);
          Notification.addNotification(
            "Error al intentar eliminar el empleado seleccionado.",
            "error",
            6000
          );
          store.state.loading = false;
        });
    }
    const getClients = () => {
      ProjectsService.getClients()
        .then((result) => {
          optionsClient.value = result.data["hydra:member"];
        })
        .catch((err) => {
          console.error("Types", err);
          Notification.addNotification(
            "Error al intentar eliminar el empleado seleccionado.",
            "error",
            6000
          );
          store.state.loading = false;
        });
    }
    const onChangeObs = () => {
      isDisable.value = !!(projectModel.value && projectModel.value.observations);
    }
    const onSubmit = () => {
      emits("change", projectModel.value);
    }


</script>
<style lang="scss" scoped>
.text-porciento {
  font-size: 100px;
  font-weight: 900;
  color: #f91570;
}
.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}

.card-search {
  border-radius: 15px;
  height: 300px;
  background: url("../../assets/icons/bombilla.png"), white;
  background-size: 24%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}
.right {
  float: right;
}
.el-input__inner {
  font-weight: bold;
  border-radius: 150px !important ;
}
.btn-action {
  width: 200px;
  height: 40px;
}
.search-input {
  width: 100%;
}
.title-text {
  text-align: left;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}
</style>