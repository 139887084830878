<template>
  <div class="m-3">
    <el-row>
      <el-col>
        <el-card style="width: fit-content; border-radius: 10px">
          <el-button @click="changeZoomLevel('-')">+</el-button>
          <el-button @click="changeZoomLevel('+')">-</el-button>
          <el-button @click="downloadPDF">Descargar PDF</el-button>
          <el-button @click="downloadImage">Descargar Imagen</el-button>
        </el-card>
      </el-col>
    </el-row>

    <el-card>
      <div class="gstc-wrapper" ref="gstcElement"></div>
    </el-card>
  </div>
</template>

<script setup>
/* eslint-disable */
import GSTC from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { Plugin as TimelinePointer } from "gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js";
import { Plugin as Selection } from "gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js";
import { Plugin as CalendarScroll } from "gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js";
import { Plugin as ItemMovement } from "gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js";
import { Plugin as Bookmarks } from "gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js";
import { Plugin as WeekendHighlight } from "gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js";
import { Plugin as ExportImage } from "gantt-schedule-timeline-calendar/dist/plugins/export-image.esm.min.js";
import { Plugin as ExportPDF } from "gantt-schedule-timeline-calendar/dist/plugins/export-pdf.esm.min.js";
import "gantt-schedule-timeline-calendar/dist/style.css";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import moment from "moment";

const props = defineProps({
  fromDate: {
    type: String,
    required: true,
  },
  toDate: {
    type: String,
    required: true,
  },
  vacationPlanning: {
    type: Array,
    required: true,
  },
});

// const height = ref(800);
const innerHeight = ref(800);

const params = ref({});
const vacationPlanningData = ref({});
const config = ref({});

watch(props.vacationPlanning, () => {
  vacationPlanningData.value = props.vacationPlanning;
  init();
});

let gstc, state;
const gstcElement = ref(null);

////////////////MAIN COMPONENT//////////////////

onMounted(() => {
  vacationPlanningData.value = props.vacationPlanning;
  innerHeight.value = (40 * vacationPlanningData.value.length) + 40;
  init();
});

const init = () => {
  const { rows, items } = loadInfo();
  config.value = {
    actions: {
      "chart-timeline-items-row-item": [clickAction],
    },
    innerHeight: innerHeight.value,
    // height: height.value,
    licenseKey:
    "====BEGIN LICENSE KEY====\\ngCe60SRjEOEA62IpnLWzReV7pNmi8gAXtXRwJLCGWFWJb16BbbPmTbbVsMiMY0ZFryMz/liRYJxugZzX6j8ubIYivXXMP+gFwJwNXte13hHZh+FhfKx94ewjMCVO5HEE6zrhPfBT3QtwuW+2Snb8xM/603jso4wb6JZu90SY/inzLI9k+0zP8Egh6ke5jnbKTvhsqb1rM6rLee8+f9nUymzYEo5J1gc5F3tg4ynwJF9cvCeIW2Xgwa8+zLo/goffmQA2QO1kL8ZWDr+nvV+F1cI1T96oTtceieGmKYc43rwi57oWqyuHZ6Mh8YqIq1g0HTUGnUoiAuZDRC/SLf9ckg==||U2FsdGVkX192H9mGRoCs8rQiZJkWZEzg/o8cm8Iu0vmeVITa05BQUo9MtcTlU7ToV0xrsl5IWjBrNg37w17MBERii7lK84dv/ktGbBCHgvM=\\nrtsHtDp2HgiNDdIzCUT/7izwweZvt9tyKJsB4rhGdyAOZ2BYSWv5ETffjB5e9ePsJjYcPGSnUAU2o1owZ+omMh+iwZU9P/blJ/4EAasnO7UGvqxOOCB3mkyR+dxGtkXjbo2YZkGdD7qAjAd+NHbzpR6n0JOmpy0IzzKp35rf/glM8l38YqZN/y8OUgHH06WbsCBMGkAu34T/XU6nNMXc2ONPE3uZwnJxIpVSRVAbs0uNyUMBI5fHYf5UgxFf5MrOi2RGWXZfH6QIo8PG3sUC9uSXglsjKOXorytpg77dcwB2tLskerJxFuXOE5TJPs8Kn8KROc14IiFM+sa56oNbdg==\\n====END LICENSE KEY====",
    plugins: [
      TimelinePointer(),
      Selection(),
      CalendarScroll({
        speed: 1,
        hideScroll: false,
        onChange() {},
      }),
      ItemMovement({
        enabled: false,
      }),
      Bookmarks(),
      WeekendHighlight({
        weekdays: [6, 0], // Saturday, Sunday
      }),
      ExportImage(),
      ExportPDF(),
    ],
    list: {
      columns: {
        data: {
          [GSTC.api.GSTCID("Empleado")]: {
            id: GSTC.api.GSTCID("Empleado"),
            data: "label",
            width: 200,
            header: {
              content: "Empleado",
            },
          },
        },
      },
      rows: rows,
    },
    chart: {
      time: {
        from: new Date(props.fromDate).setHours(0, 0, 0),
        to: new Date(props.toDate).setHours(23, 59, 59),
        zoom: 22,
      },
      items: items,
    },
    locale: {
      name: "es",
      monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
      weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split(
        "_"
      ),
      weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
      weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
      months:
        "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
          "_"
        ),
      weekStart: 1,
      formats: {
        LT: "H:mm",
        LTS: "H:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY H:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY H:mm",
      },
      relativeTime: {
        future: "en %s",
        past: "hace %s",
        s: "unos segundos",
        m: "un minuto",
        mm: "%d minutos",
        h: "una hora",
        hh: "%d horas",
        d: "un día",
        dd: "%d días",
        M: "un mes",
        MM: "%d meses",
        y: "un año",
        yy: "%d años",
      },
      ordinal: (n) => `${n}º`,
    },
  };

  state = GSTC.api.stateFromConfig(config.value);
  globalThis.state = state;
  gstc = GSTC({
    element: gstcElement.value,
    state,
  });
  globalThis.gstc = gstc;
};

const loadInfo = () => {
  const rows = {},
    items = {};

  vacationPlanningData.value.forEach((vP, index) => {
    const rowId = `gstcid-${index}`;
    rows[rowId] = {
      id: rowId,
      label: vP.name,
    };

    let color = '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substring(1, 7);
    // let color = '#f9156f';

    vP.holidays.forEach((holiday, i) => {
      const itemId = GSTC.api.GSTCID(index + "-" + i);
      items[itemId] = {
        id: itemId,
        rowId: rowId,
        label: holiday.observations || "vacaciones",
        time: {
          start: new Date(holiday.fromDate).setHours(0, 0, 0),
          end: new Date(holiday.toDate).setHours(23, 59, 59),
        },
        style: {
          background: color,
        },
      };
    });
  });

  return { rows, items };
};

onBeforeUnmount(() => {
  if (gstc) gstc.destroy();
});

// HELPER FUNCTIONS

const updateFirstRow = () => {
  state.update(`config.list.rows.${GSTC.api.GSTCID("0")}`, (row) => {
    row.label = "Changed dynamically";
    return row;
  });
};

const changeZoomLevel = (op) => {
  const currentZoom = state.data.$data.chart.time.zoom;
  if (op === "+") state.update("config.chart.time.zoom", currentZoom + 1);
  else if (currentZoom > 0)
    state.update("config.chart.time.zoom", currentZoom - 1);
};

const downloadImage = () => {
  gstc.api.plugins.ExportImage.download('timeline.png');
};

const downloadPDF = () => {
  gstc.api.plugins.ExportPDF.download('timeline.pdf');
};

const generateRows = () => {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Rows }
   */
  const rows = {};
  for (let i = 0; i < 100; i++) {
    const id = `gstcid-${i}`;
    rows[id] = {
      id,
      label: `Row ${i}`,
    };
  }
  return rows;
};

const generateItems = () => {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Items }
   */
  const items = {};
  let start = GSTC.api.date().startOf("day").subtract(6, "day");
  for (let i = 0; i < 100; i++) {
    const id = GSTC.api.GSTCID(i.toString());
    const rowId = GSTC.api.GSTCID(Math.floor(Math.random() * 100).toString());
    start = start.add(1, "day");
    items[id] = {
      id,
      label: `Item ${i}`,
      rowId,
      time: {
        start: start.valueOf(),
        end: start.add(1, "day").endOf("day").valueOf(),
      },
    };
  }
  return items;
};

const getDate = (hours) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const timeStamp = new Date(
    `${currentYear}-${currentMonth}-${currentDay} 00:00:00`
  ).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000);
};

const getVacationPlanning = () => {
  delete params.value.page;
  delete params.value.itemsPerPage;
  delete params.value["toDate[after]"];
  delete params.value["toDate[before]"];
  delete params.value["fromDate[before]"];
  delete params.value["fromDate[after]"];

  /* this.params = {
    ...this.params,
    'approved': 1, // vacaciones aprobadas
    'fromDate': this.fromDate,
    'toDate': this.toDate
  }
  */
};

const clickAction = (element, data) => {
  // console.log('click element', element, data)

  function onClick() {
    // data variable will be updated in update method below so it will be always actual
    // console.log(data)
    moment.locale("es");
    let start = moment(data.item.time.start).format("DD/MMM/YYYY");
    let end = moment(data.item.time.end).format("DD/MMM/YYYY");

    alert(
      `Event ${data.row.label} ${data.item.label} ${start} ${end} clicked!`
    );
  }

  element.addEventListener("click", onClick);

  return {
    // update(element, newData) {
    //     data = newData; // data from parent scope updated
    // },

    destroy(element) {
      element.removeEventListener("click", onClick);
    },
  };
};

const onState = (state) => {
  this.state = state;
  subs.value.push(state.subscribe("config.chart.items.1", () => {}));
  subs.value.push(state.subscribe("config.list.rows.1", () => {}));
  subs.value.push(state.subscribe("config.chart.items.4", () => {}));
};
</script>

<style scoped>
.gstc-component {
  margin: 0;
  padding: 0;
}
.toolbox {
  padding: 10px;
}
</style>
