<template>
  <div>
    <header-title title="MisSugerencias" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/speaker-icon.svg" width="50" alt="">
      <div class="title-text ml-4">
        SUGERENCIAS
      </div>
    </div>
    <el-row>
      <el-card
          class="card-search w-100"
          body-style="padding:0"
      >
        <div class="row">
          <div class="col-md-6">
            <div
                class="
                      d-flex
                      flex-nowrap flex-column
                      mt-4
                      w-100
                      pl-5
                      label-common
                    "
            >
              <div class="form-item">
              <label
                  for="id-status"
                  class="w-100"
              >Estado</label>
              <el-select
                  v-model="status"
                  class="search-input"
                  color="light"
                  filterable
                  clearable
                  placeholder="Todos"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex mt-5 pr-5 justify-content-end align-items-center">
            <button
                class="btn btn_red btn-search"
                @click="newSuggestion()"
            >
              NUEVA
            </button>
            <button
                class="btn btn_yellow btn-search ml-4"
                @click="search()"
            >
              Buscar
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-users">
        <table>
          <thead>
          <tr>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-2">
                <img src="@/assets/icons/calendar-icon.svg" width="20" height="20"  alt="calendar-icon" />
                <div class="ml-4">
                  FECHA
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-2">
                <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="calendar-icon" />
                <div>TITULO</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-2">
                <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="calendar-icon" />
                <div>AREA DE MEJORA</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center gap-2">
                <img src="@/assets/icons/speaker-icon.svg" width="20" height="20"  alt="calendar-icon" />
                <div>ESTADO</div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody v-if="suggestions">
          <tr
              v-for="sugg in suggestions"
              :key="sugg.id"
          >
            <td>
              <div class="link-name">
                <router-link :to="{name: 'suggestions-update', params: { id: sugg.id }}">
                  {{ formatDate(sugg.timestamp) }}
                </router-link>
              </div>
            </td>
            <td>
              <div class="link-name">
                <router-link :to="{name: 'suggestions-update', params: { id: sugg.id }}">
                  {{ sugg.title }}
                </router-link>
              </div>
            </td>
            <td>
              <span  v-if="sugg.department && sugg.department.name">{{ sugg.department.name }}</span>
            </td>
            <td>
              <span  v-if="sugg.suggestionStatus && sugg.suggestionStatus.name">{{ sugg.suggestionStatus.name }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-row>
  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import SuggestionServices from "@/api/services/suggestion.service";
import moment from "moment";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const router = useRouter();
const store = useStore();

const optionsStatus = ref([]);
const suggestions = ref([]);
const status = ref('');
const page = ref(1);
const itemsPerPage = ref(9999);
//const totalResults = ref(0);

onMounted(() => {
  getStatus();
  filterSuggestions();
})


const search = () => {
  filterSuggestions();
}
/* const selectSuggestionStatus = (status) => {
     const statussugg = optionsStatus.value.find(v => v['@id'] === status);
     console.log("SSS", statussugg);
     return statussugg && statussugg.name && statussugg.name;
 }*/
const filterSuggestions = () => {
  const user = store.getters.getUserLogged;
  const params = {
    itemsPerPage: itemsPerPage.value,
    page: page.value,
    'order[timestamp]': 'DESC',

  }

  // params.orSearch_fullname = user.name;

  if (status.value) {
    params['suggestionStatus.name'] = status.value;
  }
  SuggestionServices.getSuggestionsAsAuthor(user.id, params)
      .then((resp) => {
        suggestions.value = resp.data['hydra:member'];
      })
}
const getStatus = () => {
  SuggestionServices.getStatus({itemsPerPage: 9999, page: 1})
      .then((resp) => {
        optionsStatus.value = resp.data['hydra:member'];
      })
}
const newSuggestion = () => {
  router.push("/createsuggestion");
}

const formatDate = (value) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  }
  return "";
}

// eslint-disable-next-line no-unused-vars
const formatDateTime = (value)  => {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
  return "";
}



</script>
<style lang="scss" scoped>
.btn-search {
  &.btn_yellow {
    color: $bg-blue;
  }

  &.btn_red {
    color: white;
  }

  width: 20em;
  height: 3em;

  font-weight: bolder;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}

.card-search {
  border-radius: 15px;
  height: 150px;
  background: url("../../assets/icons/altavoz.png"), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}

#icon-search {
  fill: $bg-blue;
  width: 6%;
}

#icon-search-calendar {
  fill: $bg-blue;
  width: 20px;
  height: 20px;
}

.title-text {
  text-align: center;
  align-self: center;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}

.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }

      #icon-search {
        fill: $bg-blue;
        width: 2.9em;
      }

      #icon-user {
        fill: $bg-blue;
        width: 1.6em;
      }
    }

    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
