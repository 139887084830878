import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';
const RESOURCE_NAME = '/api/departments';

export default {
    async getDepartments(params){
        return await Api()
        .get(RESOURCE_NAME,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getDepartmentsByClient(client){
        return await Api()
        .get(`api/clients/${client}/departments`)
        .catch((err) => errorHandler(err, null));
    },
    async get(resource){
        return await Api()
        .get(resource)
        .catch((err) => errorHandler(err, null));
    },
}
