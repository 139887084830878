import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_STATES = '/api/ticket_statuses';

export default {
    async getStates(params){
        return await Api()
        .get(RESOURCE_STATES,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getState(id){
        return Api()
            .get(RESOURCE_STATES+'/'+id)
            .catch((err) => errorHandler(err, null));
    },
}


