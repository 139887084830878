import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_DOMAINS = '/api/domains';

export default {
    async getDomains(params){
        return await Api()
        .get(RESOURCE_DOMAINS,{params})
        .catch((err) => errorHandler(err, null));
    }
}


