<template>
  <div class="d-flex">
    <div v-if="!mediaObject || mediaObject.length==0">
      <PreviewSingleImage />
    </div>
    <div
      v-else
      class="d-flex"
    >
      <PreviewSingleImage
        v-for="media in mediaObject"
        :key="media['hydra:member'] ? media['hydra:member'][0].id : media.id"
        :url="media['hydra:member'] ? media['hydra:member'][0].contentUrl : media.contentUrl"
        :name="media['hydra:member'] ? media['hydra:member'][0].name :media.name"
        @deleteMedia="deleteImage(media['hydra:member'] ? media['hydra:member'][0].id : media.id)"
      />
    </div>
  </div>
</template>
<script>
import PreviewSingleImage from "@/components/shared/PreviewSingleImage.vue";
export default {
components:{
    PreviewSingleImage
},
props:{mediaObject:Array},
methods:{
    deleteImage(id){
        console.log("Emit 2do hijo",id)
        this.$emit('deleteMediaObject',id);
    }
}

}
</script>
