<template>
  <div>
    <svg
        id="id-asterisk"
        xmlns="http://www.w3.org/2000/svg"
        width="724"
        height="1024"
        viewBox="0 0 724 1024"
    >
      <path
          d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
      />
    </svg>
    <svg
        id="id-asterisk-red"
        xmlns="http://www.w3.org/2000/svg"
        width="724"
        height="233"
        viewBox="0 0 724 1024"
    >
      <path
          d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
      />
    </svg>
    <img
        class="close-icon pointer"
        src="@/assets/images/aspa_blue_small_entrada.png"
        alt=""
        @click="hide()"
    >
    <h1 class="title-red">
      ndanitas
    </h1>
    <img
        class="bgtishert"
        src="@/assets/images/camiseta.png"
        alt="aaa"
    >
  </div>
    <div class="row" style="margin-top: 300px;">
      <div
          v-if="store.state.undanita.imagen"
          class="col-md-4 offset-md-1"
      >
        <el-image
            :src="apiUrl + store.state.undanita.imagen"
            fit="scale-down"
            style="width: 320px; height: 320px"
        />

      </div>
      <div class="col-md-7">
        <div>
          <h6 class="time">
            <b>{{ formatDateTime(store.state.undanita.datetime) }}</b>
          </h6>
         <!-- <h5 class="employee">{{ store.state.undanita.employee }}</h5> -->
        </div>
        <div>
          <h2 class="title-new">
            {{ store.state.undanita.title }}
          </h2>
        </div>
      </div>

    </div>
  <div
      v-if="store.state.undanita"
      class="row m-3 px-4">
    <div
        class="modal-body-content"
        v-html="formatContent(store.state.undanita.content)"
    >
    </div>
  </div>

<!--    <div-->
<!--        v-if="store.state.undanita"-->
<!--        class="body-new"-->
<!--    >-->
<!--      <div class="d-flex">-->
<!--        <div class="el-image-container mr-4">-->
<!--          <el-image-->
<!--              :src="apiUrl + store.state.undanita.imagen"-->
<!--              fit="scale-down"-->
<!--          >-->
<!--&lt;!&ndash;            <template v-slot:error>&ndash;&gt;-->
<!--&lt;!&ndash;              class="image-slot"&ndash;&gt;-->
<!--&lt;!&ndash;              ></template>&ndash;&gt;-->
<!--          </el-image>-->
<!--        </div>-->
<!--        <div class="my-4">-->
<!--          <h4 class="time font-weight-bold">-->
<!--            {{ formatDateTime(store.state.undanita.datetime) }}-->
<!--          </h4>-->
<!--          <h5 class="employee">{{ store.state.undanita.employee }}</h5>-->
<!--          <h2 class="title-new">-->
<!--            {{ store.state.undanita.title }}-->
<!--          </h2>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mx-1 my-4">-->
<!--        <div-->
<!--            class="modal-body-content"-->
<!--            v-html="formatContent(store.state.undanita.content)"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->



</template>
<script setup>
import moment from "moment";
import {useStore} from "vuex";
import {ref} from "vue";


const store = useStore();
const apiUrl = ref(process.env.VUE_APP_BASE_URI);
const hide = () => {
  store.dispatch("setShowDialogUndanita", false);
}
const formatDateTime = (value) => {
  if (value) {
    return moment(value).format("DD  MMMM  YYYY");
  }
  return "";
}

const formatContent = (value) => {
  if (value) {
    return value.replace(/\n/g, "<br />");
  }
  return "";
}

</script>
<style lang="scss" scoped>

.el-dialog__body {
  background: #f3f0fb!important;
  min-height: 800px!important;
}
.time {
  font-size: 18px;
  color: black;
}

.text-content {
  color: black;
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.2em;
  margin-right:5%;
  word-break: normal;
}
.title-new {
  color: #f9166f;
  font-weight: bold;
  font-size: 2.5em;
  word-break: normal;

}
.body-new {
  position: relative;
  top: 280px;
  left: 0px;
  height: auto;
  width: 100%;
  padding: 0px 80px;
  max-width: 90%;
}
.title-red {
  position: absolute;
  top: 180px;
  left:190px;
  color: #f9166f;
  font-weight: 900;
  letter-spacing: 0.1%;
  font-size: 5.5em;
  z-index:9;
  word-break: normal;

}
#id-asterisk {
  position: absolute;
  top:-460px;
  left:20px;
  fill: $bg-blue;
  width: 5%;
}
#id-asterisk-red {
  position: absolute;
  top:-90px;
  right:280px;
  fill: #f9166f;
  width: 4%;

}
.bgtishert {
  position: absolute;
  top:10px;
  left:10px;
  max-width: 280px;
  /*
   background: url("../../assets/images/camiseta.png");
   background-repeat: no-repeat;
   background-position-x: 0%;
   height: auto; */

}
.close-icon {
  width: 30px !important;
  height:30px !important;
  position: absolute;
  right:20px;
  top:10px;
}
.el-image-container {
  max-width: 250px;
  max-height: 250px;
  .el-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    .image-slot {
      width:200px;
      object-position: top center;
    }
  }
}
@media (max-width: 992px) {
  .title-new {
    font-size: 2em;
  }
}
@media (max-width: 768px) {
  .title-red {
    font-size: 55px;
    top: 150px;
    left: 155px;
  }
  .bgtishert {
    max-width: 225px;
  }
  .body-new {
    top: 230px;
    padding: 0px 40px;
  }
  .title-new {
    font-size: 1.8em;
  }
}
</style>
