
export default {

  // API URLS APPORTED BY ENVIRONMENT VARIABLES
  BASE_URL: process.env.VUE_APP_BASE_URI,   // http://{{url}}
  API_URL: process.env.VUE_APP_API_URI,    // http://{{url}}/api/
  KEY_API_IP_ADDRESS: '165af198d73983eaf08df70d515b8438b3b29834034c0aeb16099bce',
  CLOCKIFY_API: 'https://eu-central-1.api.clockify.me/workspaces/5b07c4a4b079873d11cf05a3',
  CLOCKIFY_API_ALT: 'https://api.clockify.me/api/v1/workspaces/5b07c4a4b079873d11cf05a3'
}
