import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_TICKETS = '/api/tickets';
const RESOURCE_TICKET_COMMENTS = '/api/ticket_comments';


export default {
    async getAssignedTickets(id, params){
        return await Api()
        .get(`/api/employees/${id}/assigned_tickets`, {params})
        .catch((err) => errorHandler(err, null));
    },
    async getTickets(params){
        return await Api()
        .get(RESOURCE_TICKETS,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getRequestedTickets(id, params){
        return await Api()
            .get(`/api/employees/${id}/tickets_requested`, {params})
            .catch((err) => errorHandler(err, null));
    },
    async getTicket(id){
        return Api()
            .get(RESOURCE_TICKETS+'/'+id)
            .catch((err) => errorHandler(err, null));
    },
    async getTicketDocument(url){
        return Api()
            .get(url)
            .catch((err) => errorHandler(err, null));
    },
    async createComment(data){
        return await Api().post(RESOURCE_TICKET_COMMENTS, data)
            .catch(error => {
            console.log('Error: ' + error.response)
        })
    },
    async createTicket(data){
        return await Api().post(RESOURCE_TICKETS, data)
            .catch(error => {
                console.log('Error: ' + error.response)
            })
    },
    async updateTicket(data, id){
        return await Api().put(RESOURCE_TICKETS +'/'+id, data)
            .catch(error => {
                console.log('Error: ' + error.response)
            })
    },
    async createMediaTicket(file){
        const formData = new FormData();
        formData.append("file", file, file.name);
        return await Api().post("/api/ticket_document_media_objects", formData)
            .catch(error => {
                console.log('Error: ' + error.response)
            })
    },
    async deleteMediaTicket(id){
       return await Api().delete(`/api/ticket_document_media_objects/${id}`)
            .catch(error => {
                console.log('Error: ' + error.response)
            })
    }
}


