<script>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import {mapState} from "vuex";
import Common from "@/api/services/common.service";

import DomainService from "@/api/services/domain.service";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import moment from "moment";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import DnsDataTable from "@/views/domain/DnsDataTable.vue";
import DomainHistoryDataTable from "@/views/domain/DomainHistoryDataTable.vue";
import ServerService from "@/api/services/server.service";
import Notification from "@/utils/plugins/NotificationService";

export default {
  name: "DomainsSearch",
  components: {
    DomainHistoryDataTable,
    DnsDataTable,
    SharedButtonPlus,
    SharedPagination,
    HeaderTitle,
  },
  data() {
    return {
      itemsPerPage: "",
      optionsItemsPerPage: [
        {id: 0, name: '10', value: 10},
        {id: 1, name: '25', value: 25},
        {id: 2, name: '50', value: 50},
        {id: 3, name: '100', value: 100},
        {id: 4, name: '10000', value: 1000},
      ],
      params: {},
      page: 1,
      totalResults: 0,
      domainName: "",
      customerName: "",
      domains: [],
      domainHistory: [],
      domainWeb: [],
      domainDNS: [],
      domainMail: [],
      subdomain: "",
      optionsSubdomains: [
        {id: 0, name: 'No', value: false},
        {id: 1, name: 'Si', value: true},
      ],
      status: "",
      optionsStatus: [
        {id: 0, name: 'Activo', value: 'active'},
        {id: 1, name: 'Desactivado', value: 'disabled'},
        {id: 2, name: 'Suspendido', value: 'suspended'},
        // {value: "false", text: "No"},
      ],
      web: "",
      optionsWeb: [
        {id: 0, name: 'No', value: false},
        {id: 1, name: 'Si', value: true},
      ],
      dns: "",
      optionsDns: [
        {id: 0, name: 'No', value: false},
        {id: 1, name: 'Si', value: true},
      ],
      mail: "",
      optionsMail: [
        {id: 0, name: 'No', value: false},
        {id: 1, name: 'Si', value: true},
      ],
      server: "",
      optionsServers: [],
      orderDomainName: '',
      orderSubdomain: '',
      orderServer: '',
      orderStatus: '',
      orderLastUpdate: '',
      sortType: '',
      showDialogDomainHistory: false,
      showDialogWebDetails: false,
      showDialogDnsDetails: false,
      showDialogMailDetails: false,
    };
  },
  mounted() {
    this.getDomains();
    this.getServers()
  },
  computed: {
    ...mapState({
      user: 'user',
    })
  },
  methods: {
    search() {
      this.getDomains();
    },
    getDomains() {
      const params = {
        // itemsPerPage: this.itemsPerPage.value,
        page: this.page,
        'order[name]': 'ASC',
        'order[dns.type]': 'ASC',
        'exists[dateEnd]': false,
        'itemsPerPage': 1000
      }
      if (this.itemsPerPage !== undefined && this.itemsPerPage) {
        params['itemsPerPage'] = this.itemsPerPage
      }
      if (this.orderDomainName) {
        params['order[name]'] = this.orderDomainName;
      }
      if (this.orderSubdomain) {
        params['order[subdomain]'] = this.orderSubdomain;
      }
      if (this.orderServer) {
        params['order[server]'] = this.orderServer;
      }
      if (this.orderStatus) {
        params['order[status]'] = this.orderStatus;
      }
      if (this.orderLastUpdate) {
        params['order[lastUpdate]'] = this.orderLastUpdate;
      }
      if (this.domainName) {
        params['name'] = this.domainName;
      }
      if (this.subdomain !== undefined && this.subdomain !== "") {
        params['subdomain'] = this.subdomain;
      }
      if (this.status !== undefined && this.status !== "") {
        params['status'] = this.status;
      }
      if (this.server !== undefined && this.server !== "") {
        params['server.name'] = this.server;
      }
      if (this.web !== undefined && this.web !== "") {
        params['ipActive'] = this.web;
      }
      if (this.dns !== undefined && this.dns !== "") {
        params['dnsInServer'] = this.dns;
      }
      if (this.mail !== undefined && this.mail !== "") {
        params['mxActive'] = this.mail;
      }
      this.$store.state.loading = true;
      DomainService.getDomains(params)
          .then((response) => {
            this.domains = response.data['hydra:member'];
            this.totalResults = response.data["hydra:totalItems"];
            this.$store.state.loading = false;
          })
          .catch((error) => {
            console.log(error);

            Notification.addNotification('Error al obtener listado de dominios.', 'error', 6000);
            this.$store.state.loading = false;
          });

    },
    getServers() {
      const params = {
        itemsPerPage: 1000,
        page: 1,
        'order[name]': 'ASC',
      }
      // this.$store.state.loading = true;
      ServerService.getServers(params)
          .then((response) => {
            this.optionsServers = response.data['hydra:member'];
            // console.log("getServers", this.optionsServers);
            // this.$store.state.loading = false;
          })
          .catch(() => {
            // console.log(e);
            Notification.addNotification('Error al obtener listado de servidores.', 'error', 6000);
            // this.$store.state.loading = false;
          });
    },
    ifIsInArray(userRoles, role) {
      // console.log("userRoles", userRoles);
      return Common.ifIsInArray(userRoles, role);
    },
    changeOrderDomainName() {
      if (this.orderDomainName === '')
        this.orderDomainName = 'ASC';
      else if (this.orderDomainName === "ASC") {
        this.orderDomainName = "DESC";
      } else if (this.orderDomainName === "DESC") {
        this.orderDomainName = '';
      }
      this.getDomains();
    },
    changeOrderSubdomain() {
      if (this.orderSubdomain === '')
        this.orderSubdomain = 'ASC';
      else if (this.orderSubdomain === 'ASC') {
        this.orderSubdomain = 'DESC';
      } else if (this.orderSubdomain === 'DESC') {
        this.orderSubdomain = '';
      }
      this.getDomains();
    },
    changeOrderServer() {
      if (this.orderServer === '')
        this.orderServer = 'ASC';
      else if (this.orderServer === 'ASC') {
        this.orderServer = 'DESC';
      } else if (this.orderServer === 'DESC') {
        this.orderServer = '';
      }
      this.getDomains();
    },
    changeOrderStatus() {
      if (this.orderStatus === '')
        this.orderStatus = 'ASC';
      else if (this.orderStatus === 'ASC') {
        this.orderStatus = 'DESC';
      } else if (this.orderStatus === 'DESC') {
        this.orderStatus = '';
      }
      this.getDomains();
    },
    changeOrderLastUpdate() {
      if (this.orderLastUpdate === '')
        this.orderLastUpdate = 'ASC';
      else if (this.orderLastUpdate === 'ASC') {
        this.orderLastUpdate = 'DESC';
      } else if (this.orderLastUpdate === 'DESC') {
        this.orderLastUpdate = '';
      }
      this.getDomains();
    },
    showModalDomainHistory(domain) {
      // console.log("domain", domain.name)
      this.domainHistory = [];
      const params = {
        itemsPerPage: this.itemsPerPage.value,
        page: this.page,
        'order[dateStart]': 'ASC',
        'exact_name': domain.name,
      }
      this.$store.state.loading = true;
      DomainService.getDomains(params)
          .then((response) => {
            this.domainHistory = response.data['hydra:member'];
            // console.log("domainHistory", this.domainHistory);
            this.$store.state.loading = false;
          })
          .catch((e) => {
            console.log(e);
            Notification.addNotification('Error al obtener historial de dominio.', 'error', 6000);
            this.$store.state.loading = false;
          });
      this.showDialogDomainHistory = !this.showDialogDomainHistory
    },
    showModalWebDetails(domain) {
      console.log("showModalWebDetails", domain)
      const web = [];
      domain.dns.forEach(obj => {
        if (obj.type === 'A' || obj.type === 'WWW') {
          web.push(obj);
        }
      });
      web.sort((a, b) => {
        return new Date(a.dateStart) - new Date(b.dateStart);
      });
      this.domainWeb = web;
      console.log("this.domainWeb", this.domainWeb);
      this.showDialogWebDetails = !this.showDialogWebDetails
    },
    showModalDnsDetails(domain) {
      const dns = [];
      domain.dns.forEach(obj => {
        if (obj.type === 'NS') {
          dns.push(obj);
        }
      });
      dns.sort((a, b) => {
        return new Date(a.dateStart) - new Date(b.dateStart);
      });
      this.domainDNS = dns;
      // console.log("this.domainDNS", this.domainDNS);
      this.showDialogDnsDetails = !this.showDialogDnsDetails
    },
    showModalMailDetails(domain) {
      const mail = [];
      domain.dns.forEach(obj => {
        if (obj.type === 'MX' || obj.type === 'TXT') {
          mail.push(obj);
        }
      });
      mail.sort((a, b) => {
        return new Date(a.dateStart) - new Date(b.dateStart);
      });
      this.domainMail = mail;
      // console.log("this.domainMail", this.domainMail);
      this.showDialogMailDetails = !this.showDialogMailDetails
    },
    openLinkInNewTab(link) {
      window.open('https://'+ link + ':8443', '_blank');
    },
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    translateTrueOrFalse(value) {
      if (value === 1 || value === true) {
        return "Si";
      } else {
        return "No";
      }
    },
    translateStatus(value) {
      if (value.toLowerCase() === 'active') {
        return "Activo";
      } else if (value.toLowerCase() === 'disabled') {
        return "Desactivado";
      } else if (value.toLowerCase() === 'suspended') {
        return "Suspendido";
      }
    }
  },
}
;

</script>

<template>
  <div
      v-if="user && (ifIsInArray(user.roles, 'ROLE_ADMIN') || ifIsInArray(user.roles, 'ROLE_SYSTEMS'))"
      id="domain-search"
  >
    <!--    <div class="absences" v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')">-->
    <header-title title="Dominios" />
    <div class="d-flex flex-row justify-content-start">
       <img src="@/assets/icons/speaker-icon.svg" class=" mx-2" width="30"  alt="name-icon" />
      <div class="title-text ml-4">
        DOMINIOS
      </div>
    </div>

    <el-row>
      <el-card
          class="card-search w-100"
          body-style="padding:0"
      >
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="form-item">
              <label for="domain-name" class="w-100">Nombre dominio</label>
              <el-input
                  id="domain-name"
                  v-model="domainName"
                  clearable
                  class="search-input"
                  placeholder="Nombre del dominio"
                  @change="search"
              />
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-item">
              <label
                  for="id-subdomain"
                  class="w-100"
              >Subdominio</label>
              <el-select
                  v-model="subdomain"
                  clearable
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsSubdomains"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-item">
              <label
                  for="id-status"
                  class="w-100"
              >Estado</label>
              <el-select
                  v-model="status"
                  filterable
                  clearable
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-item">
              <label
                  for="id-server"
                  class="w-100"
              >Servidor</label>
              <el-select v-model="server"
                         filterable
                         clearable
                         placeholder="TODOS"
                         @change="search"
              >
                <el-option
                    v-for="item in optionsServers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-item">
              <label
                  for="id-web"
                  class="w-100"
              >Web en el servidor</label>
              <el-select
                  filterable
                  clearable
                  v-model="web"
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsWeb"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-item">
              <label
                  for="id-dns"
                  class="w-100"
              >DNS en el servidor</label>
              <el-select
                  filterable
                  clearable
                  v-model="dns"
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsDns"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-item">
              <label
                  for="id-mail"
                  class="w-100"
              >Correo en el servidor</label>
              <el-select
                  v-model="mail"
                  filterable
                  clearable
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsMail"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-item">
              <label
                  for="id-items-per-page"
                  class="w-100"
              >Resultados por página</label>
              <el-select
                  filterable
                  clearable
                  v-model="itemsPerPage"
                  placeholder="TODOS"
                  @change="search"
              >
                <el-option
                    v-for="item in optionsItemsPerPage"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex flex-column justify-content-end align-items-end">
          <button
              class="btn btn_yellow btn-search"
              @click="search()"
          >
            Buscar
          </button>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-domains">
        <table>
          <thead>
          <tr>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderDomainName()"
              >
                <div>
                  DOMINIO
                  <font-awesome-icon
                      v-if="orderDomainName === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderDomainName === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderSubdomain()"
              >
                <div>
                  SUBDOMINO
                  <font-awesome-icon
                      v-if="orderSubdomain === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderSubdomain === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <div>CLIENTE</div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderStatus"
              >
                <div>
                  ESTADO
                  <font-awesome-icon
                      v-if="orderStatus === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderStatus === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderServer()"
              >
                <div>
                  SERVIDOR (LINK)
                  <font-awesome-icon
                      v-if="orderServer === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderServer === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <div>WEB</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <div>DNS</div>
              </div>
            </th>
            <th>
              <div class="d-flex justify-content-start align-items-center">
                <div>CORREO</div>
              </div>
            </th>
            <th>
              <div
                  class="d-flex justify-content-start align-items-center pointer"
                  @click="changeOrderLastUpdate"
              >
                <div>
                  ACTUALIZADO
                  <font-awesome-icon
                      v-if="orderLastUpdate === 'ASC'"
                      icon="sort-alpha-up"
                  />
                  <font-awesome-icon
                      v-else-if="orderLastUpdate === 'DESC'"
                      icon="sort-alpha-down"
                  />
                  <font-awesome-icon
                      v-else
                      icon="sort"
                  />
                </div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="domain in domains"
              :key="domain.id"
          >
            <td>
              <div class="d-flex justify-content-start align-items-center">
                {{ domain.name }}
                <shared-button-plus
                    @onClick="showModalDomainHistory(domain)"
                    title="Histórico Dominios"
                />
              </div>
            </td>
            <td>
              <font-awesome-icon
                  class="icon-th"
                  v-if="domain.subdomain"
                  style="color: green;"
                  icon="check-circle"
              />
              <font-awesome-icon
                  class="icon-th"
                  v-else
                  style="color: red"
                  icon="times-circle"
              />
            </td>
            <td>
              {{ domain.customerName }}
            </td>
            <td>
              <!--              {{ domain.status | translateStatus }}-->
              <font-awesome-icon
                  v-if="domain.status === 'active'"
                  style="color: green"
                  class="icon-th"
                  icon="check-circle"
              />
              <font-awesome-icon
                  v-else-if="domain.status === 'disabled'"
                  style="color: red"
                  class="icon-th"
                  icon="times-circle"
              />
              <font-awesome-icon
                  v-else-if="domain.status === 'suspended'"
                  style="color: orange"
                  class="icon-th"
                  icon="exclamation-circle"
              />
            </td>
            <td>
              <a
                  :href="'https://' + domain.server.url + ':8443'"
                  @click="openLinkInNewTab(domain.server.url)"
              >
                <!--              <a v-bind:href="'https://' + domain.server.url + ':8443'" target="_blank" rel="noreferrer noopener">-->
                {{ domain.server.name }}
              </a>
            </td>
            <td>
              <!--              {{ domain.ipActive | translateTrueOrFalse }}-->
              <div
                  class="pointer"
                  @click="showModalWebDetails(domain)"
              >
                <font-awesome-icon
                    title="Histórico Web"
                    v-if="domain.ipActive"
                    style="color: green"
                    class="icon-th"
                    icon="check-circle"
                />
                <font-awesome-icon
                    title="Histórico Web"
                    v-else
                    style="color: red"
                    class="icon-th"
                    icon="times-circle"
                />
              </div>
            </td>
            <td>
              <!--              {{ domain.dnsInServer | translateTrueOrFalse }}-->
              <div
                  class="pointer"
                  @click="showModalDnsDetails(domain)"
              >
                <font-awesome-icon
                    title="Histórico DNS"
                    v-if="domain.dnsInServer"
                    style="color: green"
                    class="icon-th"
                    icon="check-circle"
                />
                <font-awesome-icon
                    title="Histórico DNS"
                    v-else
                    style="color: red"
                    class="icon-th"
                    icon="times-circle"
                />
              </div>
            </td>
            <td>
              <div
                  class="pointer"
                  @click="showModalMailDetails(domain)"
              >
                <font-awesome-icon
                    title="Histórico Correo"
                    v-if="domain.mxActive"
                    style="color: green"
                    class="icon-th"
                    icon="check-circle"
                />
                <font-awesome-icon
                    title="Histórico Correo"
                    v-else
                    style="color: red"
                    class="icon-th"
                    icon="times-circle"
                />
              </div>
            </td>
            <td>
              {{ formatDateTime(domain.lastUpdate) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-row>
    <el-row>
      <SharedPagination
          v-if="domains && totalResults > itemsPerPage.value"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage.value"
          @change="page = $event;getDomains();"
      />
    </el-row>
    <el-dialog
        v-model="showDialogDomainHistory"
        :show-close="false"
        width="85%"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Histórico del Dominio</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogDomainHistory=false"
        >
      </div>
      <domain-history-data-table :domain-history="domainHistory" />
      <!--      <employees-with-holidays-new :holidays="holiday"></employees-with-holidays-new>-->
    </el-dialog>
    <el-dialog
        v-model="showDialogWebDetails"
        :show-close="false"
        width="75%"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Servidores WEB</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogWebDetails=false"
        >
      </div>
      <dns-data-table :dns="domainWeb" />
    </el-dialog>
    <el-dialog
        v-model="showDialogDnsDetails"
        :show-close="false"
        width="75%"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Servidores DNS</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogDnsDetails=false"
        >
      </div>
      <dns-data-table :dns="domainDNS" />
    </el-dialog>
    <el-dialog
        v-model="showDialogMailDetails"
        :show-close="false"
        width="75%"
    >
      <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5"> Servidores de Correo</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="showDialogMailDetails=false"
        >
      </div>
      <dns-data-table :dns="domainMail" />
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">

#domain-search {
  .title-text {
    text-align: center;
    align-self: center;

    font-size: 3em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: $font-blue-color;
  }

  .card-search {
    border-radius: 15px;
    height: 250px;
    background: url("../../assets/icons/altavoz.png"), white;
    background-repeat: no-repeat;
    background-position-x: 100%;
    overflow: visible !important;
  }

  #icon-search {
    fill: $bg-blue;
    width: 6%;
  }

  .label-common {
    label {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: -2px;
    }
  }

  .table-domains {
    table {
      thead {
        tr {
          th {
            padding: 0px !important;
            margin: 0px !important;
          }
        }

        #icon-search {
          fill: $bg-blue;
          width: 2.9em;
        }

        #icon-user {
          fill: $bg-blue;
          width: 1.6em;
        }
      }

      tbody {
        td {
          .link-name {
            margin: auto;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 2.2em;
    fill: #f9156f;
  }

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-header {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;
  }
}
</style>
