// Vuex dependencies
import {createStore} from 'vuex'

// Modules imported
import axios from 'axios'

import moment from "moment";

// Plugins
import VuexPersistence from 'vuex-persist';
//import errorHandler from '@/utils/plugins/ErrorHandler'

import EmployeeService from "@/api/services/employee.service";
import PresenceService from "@/api/services/presencia.service";


const vuexLocalStorage = new VuexPersistence({
  key: 'store',
  storage: window.localStorage,
})

export default  createStore({
  state: {
    platform: 'public',
    user: null,
    lang: 'es',
    loading: false,
    sign_button:true,
    hoursWorked:'',
    currentSign:[],
    reportPresence:[],
    reportDateRange:[],
    undanews:{},
    undanita:{},
    showDialogUndanita:false,
    showDialogUndanews:false,
    showDialogBenefit:false,
    benefit:{},
    ip:'',


  },
  mutations: {
    setLoading(state, loading) {
        state.loading = loading
    },
    setIp(state,ip){
      state.ip = ip;
    },
    initialiseStore(state) {
      if(state) {
        state.userLogged = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        state.platform = localStorage.getItem('platform') ? JSON.parse(localStorage.getItem('platform')) : 'public'
      }
		},
    setShowDialogUndanews(state,val){
      state.showDialogUndanews = val;
    },
    setShowDialogUndanita(state,val){
      state.showDialogUndanita = val;
    },
    setShowDialogBenefit(state,val){
      state.showDialogBenefit = val;
    },
    setBenefit(state,obj){
      state.benefit = obj;
    },
    setUndanews(state,obj){
      state.undanews = obj;
    },
    setUndanita(state,obj){
      state.undanita = obj;
    },
    setUserLogged(state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    setStatusSignButton(state){
      // console.log("store-setStatusSignButton", state)
      state.sign_button = !state.sign_button;
    },
    setSignButton(state,value){
      // console.log("store-setSignButton", state, value)
      state.sign_button = value;
    },
    setHoursWorked(state,value){
        state.hoursWorked = value;
    },
    setCurrentSign(state,value){
      state.currentSign = value
    },

    setReportPresence(state,array){
      state.reportPresence = array;
    },
    resetReportPresence(state){
      state.reportPresence = [];
    },
    setDateRangeReport(state,array){
      state.reportDateRange = array;
    }
  },
  actions: {
    async setBenefit({commit},obj){
        commit('setBenefit',obj);
    },
    setShowDialogBenefit({commit},val){
        commit('setShowDialogBenefit',val);
    },
    setUndanews({commit},obj){
        commit('setUndanews',obj);
    },
    setUndanita({commit},obj){
      commit('setUndanita',obj);
    },
    setShowDialogUndanews({commit},val){
      commit('setShowDialogUndanews',val);
    },
    setShowDialogUndanita({commit},val){
      commit('setShowDialogUndanita',val);
    },
    async setLoading({commit}, loading) {
      commit('setLoading', loading)
    },
   async addSigning({state}){

      const user = state.user;

      const sendSign = async () => {
        const formData = new FormData();
        formData.set("email", user.email);

        return PresenceService.signUp(formData).then(() => {
          //const data = JSON.parse(response.data);
          return true;
          //console.log("SING",data);


        });
      };

      return await sendSign();


    },
    calculateWorkedTime({state},now){
      let sum = 0;

      state.currentSign.forEach(v=>{
          // console.log("store",v);
          if(v.total){
            sum+=moment.duration(v.total).asMilliseconds();
          }else{
            // console.log("Now",v.in);
            sum+=moment.duration(now.diff(moment(v.in,"HHmmss"))).asMilliseconds();
          }
       })
        // console.log("SSSS",sum);
       state.hoursWorked = sum;
    },
    currentPresence({commit},signings){

      let isIn  = true;
      let currentSign = [];
      let obj = {};

      if(!signings || signings.length == 0)
      {
        commit('setCurrentSign',[]);
        //commit('setStatusSignButton')
        return;
      }

      signings.forEach(elmt=>{
        if(isIn){
              obj = {in:elmt.time};
              currentSign.push(obj);
              //commit('setSignButton',false)
        }else{
          currentSign[currentSign.length-1] = {
            in: obj.in,
            out:elmt.time,
            total:moment.duration(moment(elmt.time,"HHmmss").diff(moment(obj.in,"HHmmss")))
          }
          //commit('setSignButton',true)
          obj = {}
        }
        isIn = !isIn;
    })
      commit('setSignButton',isIn);
      commit('setCurrentSign',currentSign);

    },
    async beforeCreate() {
      this.commit('initialiseStore')
    },
    setUserLogged(context, user) {
      if(!user) {
        context.commit('setUserLogged', null)
        return
      }
      EmployeeService.getEmployee(user.id).then(response => {
        if (response.data.job && response.data.job.name) {
          user.jobName = response.data.job.name
        } else {
          user.jobName = ''
        }
      })
      context.commit('setUserLogged', user)
    },
    fetchUserLogged(context, userId) {
      axios.get(`/api/employee/${ userId }`).then(response => {
        context.commit('setUserLogged', response.results.user)
      })
    },
  },
  getters: {
    /**
     * Get status of button that begin and finished Presence
     */
    getIp:state=>state.ip,
    getStateButtonSign:(state)=> state.sign_button,
    getUndanita:state => state.undanita,
    getUndanews:state => state.undanews,
    getUserLogged: (state) => {
      const stored = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      return state && state.user ? state.user : stored;
    },
    getUserLoggedFullName: (state) => {
      return state && state.user ? `${state.user.name} ${state.user.lastName}` : null
    },
    getHoursWorked:(state) =>{

       return state.hoursWorked ;
    },
    getReportPresence(state){
      return state.reportPresence;
    },
    getDateRangeReport(state){
      return state.reportDateRange;
    }
  },
  plugins: [vuexLocalStorage.plugin]
})
