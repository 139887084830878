<template>
  <div>
    <img
        class="header-undanews"
        src="@/assets/images/header-dialog-undanews.png"
        alt=""
    >
    <img
        class="close-icon pointer"
        src="@/assets/images/aspa_amarilla_small_entrada.png"
        alt=""
        @click="hide()"
    >
    <div class="d-flex m-3 px-4">
      <div
          v-if="store.state.undanews.imagen"
          class="mr-4"
      >
        <el-image
            :src="apiUrl + store.state.undanews.imagen"
            fit="scale-down"
            style="width: 300px; height: 250px"
        />
      </div>
      <div class="d-flex flex-column justify-content-end">
        <div>
          <h6 class="time font-weight-bold">
            {{ formatDateTime(store.state.undanews.datetime) }}
          </h6>
        </div>
        <div>
          <h2 class="title-new">
            {{ store.state.undanews.title }}
          </h2>
        </div>
      </div>
    </div>
    <div
        v-if="store.state.undanews"
        class="row m-3 px-4"
    >
      <div
          class="modal-body-content"
          v-html="formatContent(store.state.undanews.content)"
      />
    </div>
  </div>
</template>
<script setup>
import moment from "moment";
import {useStore} from "vuex";
import {ref} from "vue";

  const store = useStore();
  const apiUrl = ref(process.env.VUE_APP_BASE_URI);
  const hide = () => {
    store.dispatch("setShowDialogUndanews", false);
  }
  const formatDateTime = (value) => {
      if (value) {
        return moment(value).format("DD  MMMM  YYYY");
      }
      return "";
    }

    const formatContent = (value) => {
      if (value) {
        return value.replace(/\n/g, "<br />");
      }
      return "";
    }

</script>
<style lang="scss" scoped>
.text-content {
  color: black;
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.2em;
  word-break: normal;
}
.title-new {
  color: #f9166f;
  font-weight: bold;
  font-size: 2.5em;
  word-break: normal;
}
.time {
  font-size: 18px;
  color: black;
}
.header-undanews {
  height: auto;
  width: 70%;
}
.close-icon {
  height: 30px !important;
  width: 30px !important;
  position: absolute;
  right: 20px;
  top: 10px;
}
@media (max-width: 992px) {
  .title-new {
    font-size: 2em;
  }
}
@media (max-width: 768px) {
  .title-new {
    font-size: 1.8em;
  }
  .text-content {
    font-size: 1em;
  }
}
</style>