<template>
  <div class="modal animated fadeIn fast">
    <div
      class="modal-overlay"
      :class="{ 'white': theme === 'light', 'dark': theme === 'dark', 'secondary': theme === 'secondary',
                'blue': theme === 'blue', 'lg': size === 'large', 'sm': size === 'small', 'md': size === 'medium' }"
      @click.stop="closeModal()"
    >
      <div
        class="modal-container"
        @click="$event.stopPropagation()"
      >
        <div
          v-if="title"
          class="modal-header"
          :class="{'close-icon': close}"
        >
          <div class="list-header">
            <h1
              v-if="title"
              class="title c_secondary"
            >
              {{ title }}
            </h1>
            <hr class="small c_secondary">
          </div>

          <a
            v-if="closeButtonInHeader"
            class="close-icon pointer"
            @click="closeModal()"
          >
            <font-awesome-icon
              class="d-inline-block"
              icon="times"
            />
          </a>
        </div>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharedModal',
  props: {
    theme: {type: String, default: 'dark'},
    title: {type: String},
    size: {type: String},
    closeButtonInHeader: {type: Boolean, default: false}
  },
  data() {
    return {
      close: false
    }
  },
  mounted() {
    var content = this.$el;
    var body = document.querySelector('body');
    body.appendChild(content);
  },
  methods: {
    closeModal() {
     this.$emit('close', false);
    }
  }
}
</script>


<style lang="scss" scoped>

@import '@/assets/sass/core/_variables.scss';

.modal {
  position: fixed;
  display: block;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1999;

  h1 {
    color: $secondary-color !important;
    text-align: left !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    line-height: 18px;
    margin-bottom: .5rem !important;
  }

  hr.c_secondary {
    &:after {
      color: $secondary-color;
    }
  }

  .modal-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, .1);

    .modal-container {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: auto;
      max-width: 50%;
      height: fit-content;
      max-height: 90%;
      min-height: 50%;
      margin: auto;
      padding: 60px 40px;
      color: $light-bg;
      background: #ffffff;
      border-radius: 2px;
      overflow: auto;

      .buttons {
        padding-left: 20px;
        -ms-flex-item-align: center;
        align-self: center;
        display: -ms-inline-flexbox !important;
        display: -webkit-inline-box !important;
        display: inline-flex !important;

        .pointer {
          margin-right: 4px;
          margin-left: 4px;
        }
      }

      .d-flex {
        display: -ms-flexbox !important;
        display: -webkit-box !important;
        display: flex !important;
        align-items: center;
        margin-bottom: 20px;
      }

      .bg_grey {
        background: #d3d8dc;
        padding: 15px;
      }

      .modal-header {
        position: relative;
        display: block;
        padding: 0px;
        min-height: 40px;
        width: 100%;
        border-bottom: 0px;
        margin-bottom: 10px;

        &.close-icon {
          padding-right: 40px;
        }

        h1 {
          text-align: center;
          font-family: $font-title;
          font-size: 26px;
          font-weight: 600;
          margin-bottom: 0px;
          padding-top: 10px;
        }

        .close-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 24px;
        }
      }
    }

    &.white {
      .modal-container {
        background: $light-bg;
        color: $dark-color;
      }
    }

    &.secondary {
      .modal-container {
        background: $secondary-color;
        color: $dark-color;
      }
    }

    &.dark {
      .modal-container {
        /*background: black;*/
        color: black;
      }
    }

    &.blue {
      .modal-container {
        background: $bg-blue;
        border-radius: 20px;
        color: white;
      }
    }

    &.sm {
      .modal-container {
        max-width: 30%;

      }
    }

    &.md {
      .modal-container {
        max-width: 60%;
      }
    }

    &.lg {
      .modal-container {
        max-width: 90%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .modal .modal-overlay .modal-container,
  .modal .modal-overlay.sm .modal-container,
  .modal .modal-overlay.lg .modal-container {
    max-width: 90%;
  }
}

</style>
