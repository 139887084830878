<template>
  <div>
    <header-title title="Comunicación"/>
    <div class="d-flex flex-row justify-content-start">
      <img
          src="../../assets/icons/undanews.png"
          class="icon-undanews"
          alt=""
          srcset=""
      >
      <div class="title-text">
        Undanews
      </div>
    </div>
    <el-row :gutter="10">
      <el-col :span="18">
        <div class="d-flex flex-column flex-nowrap mt-4 w-100 label-common">
          <label for="title">T&iacute;tulo</label>
          <el-input
              id="title"
              v-model="title"
              placeholder="Ingrese título"
          />
        </div>
        <p
            v-if="v.title.$error"
            class="invalid-feedback mb-0">
          <small>
            {{ v.title.$errors[0].$message }}
          </small>
        </p>
      </el-col>
      <el-col :span="6">
        <div class="mt-4 form-item date-picker label-common">
          <label class="w-100 mb-1">Fecha de alta</label>
          <el-date-picker
              :ref="'fromDate'"
              v-model="dateUp"
              type="date"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              :prefix-icon="customPrefix"
              :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="form-item mt-4 w-100 label-common">
          <label
              for="id-status"
              class="w-100"
          >Estado</label>
          <el-select
              id="id-status"
              v-model="status"
              style="width: 100%"
          >
            <el-option
                v-for="item in optionsStatus"
                :key="item['@id']"
                :label="item.name"
                :value="item['@id']"
            />
          </el-select>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="d-flex flex-nowrap flex-column mt-4 w-100 label-common">
          <label
              for="id-read"
              class="align-self-center"
          >Confirmaci&oacute;n de Lectura</label>
          <input
              v-show="newsRead"
              id="id-news-read"
              type="radio"
              class="align-self-center radio-larg"
              checked
              name="news-read"
              :value="newsRead"
              @click.prevent="newsRead = !newsRead"
          >
          <input
              v-show="!newsRead"
              id="id-news-read"
              type="radio"
              class="align-self-center radio-larg"
              name="news-read"
              :value="newsRead"
              @click.prevent="newsRead = !newsRead"
          >
        </div>
      </el-col>
      <el-col :span="8">
        <div class="d-flex flex-nowrap flex-column mt-4 w-100 label-common">
          <label
              for="id-read"
              class="align-self-end"
          >Ver confirmaci&oacute;n de Lectura</label>

          <button
              class="btn btn-block btn_yellow mt-4 align-self-end"
              style="width: 240px"
              @click="showUsers()"
          >
            VER USUARIOS
          </button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col
          :span="24"
          class="label-common"
      >
        <label>Cuerpo de la noticia</label>
        <QuillEditor
            v-model:content="body"
            contentType="html"
            @text-change="onTextChanged"
            theme="snow"/>
        <p
            v-if="v.newsBody.$error"
            class="invalid-feedback mb-0">
          <small>
            {{ v.newsBody.$errors[0].$message }}
          </small>
        </p>
      </el-col>
    </el-row>
    <el-row class="label-common mt-5">
      <el-col :span="8">
        <div class="d-flex flex-nowrap flex-column mt-4 pt-5 w-100 label-common">
          <label>Imagen (300*300)</label>
          <!--  <el-image

                :src="urlImage"
                fit="cover">
                <div slot="error" class="image-slot"  style="width: 300px; height: 300px; background:#f9166f;">

                </div>
            </el-image> -->
          <PreviewMediaObjectImage
              :media-object="undanew.undanewsMediaObjects"
              @deleteMediaObject="(id) => deleteMedia(id)"
          />

          <button
              :disabled="disableSelect"
              class="btn btn-block btn_yellow mt-4"
              style="width: 300px"
              @click="selectFile()"
          >
            SELECCIONAR
          </button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col
          :span="10"
          :offset="15"
      >
        <div class="d-flex justify-content-end mt-5">
          <button
              v-if="showDelete"
              :disabled="disableSave"
              class="btn btn-block btn_red m-2 btn-action"
              @click="deleteUndanews(route.params.id)"
          >
            ELIMINAR
          </button>
          <button
              :disabled="disableSave"
              class="btn btn-block btn_red m-2 btn-action"
              @click="() => goToList()"
          >
            Volver
          </button>
          <button
              type="primary"
              :disabled="disableSave"
              class="btn btn-block btn_yellow m-2 btn-action"
              @click="save()"
          >
            GUARDAR
          </button>
        </div>
      </el-col>
    </el-row>
    <el-dialog
        v-model="openPopup"
        width="50%"
        center>
      <PopupReaderConfirm
          type="Undanews"
          :emp-in="empIn"
          :emp-out="empOut"
          @accept="closePopup()"
      />
    </el-dialog>

  </div>
</template>
<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import EmployeeServices from "@/api/services/employee.service.js";
import PreviewMediaObjectImage from "@/components/shared/PreviewMediaObjectImage.vue";
import Notification from "@/utils/plugins/NotificationService";
import PopupReaderConfirm from "@/components/shared/PopupReaderConfirm.vue";
import Undanew from "@/api/services/undanews.service.js";
import {helpers, required} from "@vuelidate/validators";
import {computed, h, onMounted, ref, shallowRef, watch} from "vue";
import {useStore} from "vuex";
import {useRouter, useRoute} from "vue-router";
import {useVuelidate} from "@vuelidate/core";


const showDelete = ref(false);
const fileImg = ref("");
const title = ref("");
// eslint-disable-next-line no-unused-vars
const optionsEmployee = ref([]);
const undanew = ref({});
const employee = ref("");
const employees = ref([]);
const optionsStatus = ref([]);
const status = ref({});
const newsBody = ref(null);
const body = ref(null);
// eslint-disable-next-line no-unused-vars
const urlImage = ref("");
const dateUp = ref(new Date());
const undanewsMediaObjects = ref([]);
const disableSave = ref(false);
const disableSelect = ref(false);
const newsRead = ref(false);
const openPopup = ref(false);
const empIn = ref([]);
const empOut = ref([]);
const undanewReader = ref([]);
// eslint-disable-next-line no-unused-vars
const customToolbar = ref([
  ["bold", "italic", "underline"],
  [{list: "ordered"}, {list: "bullet"}],
  [{color: []}, {background: []}, "link"],
]);

const store = useStore();
const route = useRoute();
const router = useRouter();

watch(undanewsMediaObjects, (newVal) => {
  if (newVal.length === 3) {
    disableSelect.value = true;
  }
  if (newVal.length >= 0 && newVal.length < 3) {
    disableSelect.value = false;
  }
});

onMounted(() => {
  if (route.params.id) {
    Promise.all([
      getEmployee(),
      getStatus(),
      getAllEmployees(),
    ])
        .then(() => {
          loadField(route.params.id);
        });

    /*   setTimeout(()=>{
        loadField(route.params.id)
      },500) */
  } else {
    Promise.all([
      getEmployee(),
      getStatus(),
      getAllEmployees()
    ])

  }
})
const rules = computed(() => {
  return {
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    newsBody: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});
const form = ref({
  title: null,
  newsBody: null,
});
const v$ = useVuelidate(rules, form);
const v = v$.value;
const onTextChanged = ({html}) => {
  newsBody.value = html;
}
const closePopup = () => {
  openPopup.value = false;
}
const showUsers = () => {
  openPopup.value = !openPopup.value;
}
const cleanMedia = () => {
  const prom = [];
  if (undanewsMediaObjects.value.length > 0) {
    const haveUndanew =
        undanew.value && undanew.value.undanewsMediaObjects ? true : false;
    undanewsMediaObjects.value.forEach((v) => {
      if (haveUndanew) {
        const pos = undanew.value.undanewsMediaObjects.findIndex(
            (r) => r === v["@id"]
        );
        if (pos === -1) prom.push(deleteMedia(v.id));
      } else prom.push(deleteMedia(v.id));
    });

    if (prom.length > 0) {
      Promise.all(prom).then(() => {
        router.push({name: "undanews"});
      });
    } else {
      router.push({name: "undanews"});
    }
  } else {
    router.push({name: "undanews"});
  }
}
const goToList = async () => {
  await cleanMedia();
  //console.log("MEdias",undanewsMediaObjects);
}
const deleteUndanews = (id) => {
  Undanew.delete(id).then(() => {
    router.push({name: "undanews"});
  });
}
const deleteMedia = (id) => {
  //console.log("AAAAAA",id);
  Undanew.deleteMediaObject(id).then(() => {
    //console.log("Delete Media",resp);
    undanew.value.undanewsMediaObjects = undanew.value.undanewsMediaObjects.filter(
        (v) => v.id != id
    );
  });
}
// eslint-disable-next-line no-unused-vars
const getObjectsMedia = (ids) => {
  const aux = [];
  //console.log("IDDDDSSSS",ids);
  ids.forEach((v) => {
    aux.push(getMediaObjects(v));
  });
  return Promise.all(aux);
}
const getMediaObjects = (id) => {
  return Undanew.getMediaObject(id);
}
const selectFile = () => {

  let fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.addEventListener("change", function (e) {
    //console.log("File",e.target.files);
    //var extn = e.target.files[0].type.split("/")[1];
    var size = e.target.files[0].size;
    var maxSize = 2097152; // 2097152 byte
    if (size < maxSize) {
      disableSave.value = true;
      Undanew.createMediaObject(e.target.files[0]).then((resp) => {
        //console.log("Add media",resp);
        undanew.value.undanewsMediaObjects.push(resp.data);
        disableSave.value = false;
      });
    } else {
      Notification.addNotification(
          "No se puede cargar el Archivo porque excede el límite permitido.",
          "error",
          6000
      );
    }
    /*  _urlImage =URL.createObjectURL(e.target.files[0]); */
  });
  fileSelector.click();
  return false;
}
const loadField = (id) => {
  Undanew.getOne(id).then((resp) => {
    undanew.value = resp.data;
    title.value = resp.data.title;
    dateUp.value = resp.data.timestamp;
    status.value = resp.data.newsStatus ? resp.data.newsStatus['@id'] : null
    //("Status",status);
    body.value = resp.data.newsBody;

    showDelete.value = true;
    newsRead.value = resp.data.readConfirmation;
    getDataUndanewsConfirmReader();
  });

  //getMediaObjects();
}
const employeeConfirmReaders = async () => {
  return await Undanew.getReader(undanew.value.id, {itemsPerPage: 9999, page: 1})
      .then((resp) => {
        return resp.data["hydra:member"];
      });
}
const getDataUndanewsConfirmReader = async () => {

  if (!undanew.value.readConfirmation) {
    return
  }

  if (undanew.value.undanewsReaders && undanew.value.undanewsReaders.length > 0) {
    employeeConfirmReaders()
        .then(async (resp) => {
          undanewReader.value = resp;
          // const {empIn, empOut} = await getEmployeesComfirmReader(undanewReader.value)
          // empIn.value = empIn;
          // empOut.value = empOut;
          // console.log("empIn", empIn.value);
          // console.log("empOut", empOut.value);
          getEmployeesComfirmReader(undanewReader.value)
        });
  } else {
    // const {empIn, empOut} = await getEmployeesComfirmReader([]);
    // empIn.value = empIn;
    // empOut.value = empOut;
    getEmployeesComfirmReader([]);
  }

}
const getEmployeesComfirmReader = (employeesHaveRead) => {
  // console.log("employeesHaveRead", employeesHaveRead)
  // return await EmployeeServices.getEmployees({active: 1, itemsPerPage: 9999, page: 1}).then((resp) => {
  //   const empIn = [];
  //   const empOut = [];
  //
  //   // console.log("All employees", resp.data['hydra:member'])
  //   resp.data["hydra:member"].forEach((aEmployee) => {
  //     // console.log("aEmployee", aEmployee["@id"])
  //     const index = employeesHaveRead.findIndex((employeeHasRead) => employeeHasRead.employee === aEmployee["@id"]);
  //     // const index = employeesHaveRead.findIndex((employeeHasRead) => aEmployee["@id"] === employeeHasRead.employee);
  //     if (index === -1) {
  //       // empleado que no ha leido
  //       // console.log("No ha leido", aEmployee)
  //       empOut.push(aEmployee);
  //     } else {
  //       // empleado que ha leido
  //       // const aux = employeesHaveRead[index];
  //       // aux.employee = aEmployee;
  //       // console.log("Ha leido", aEmployee)
  //       empIn.push(aEmployee);
  //     }
  //   });
  //   return {empIn, empOut};
  // });
  console.log("employeesHaveRead", employeesHaveRead)
  console.log('All Employees', employees.value)
  // const empIn = [];
  // const empOut = [];
  employees.value.forEach((employee) => {
    const index = employeesHaveRead.findIndex((employeeHasRead) => employeeHasRead.employee === employee["@id"]);
    if (index === -1) {
      empOut.value.push(employee);
    } else {
      const aux = employeesHaveRead[index];
      aux.employee = employee;
      empIn.value.push(aux);
    }
  });
  // empIn.value = empIn;
  // empOut.value = empOut;
}
const getEmployee = async () => {
  const user = store.getters.getUserLogged;
  return await EmployeeServices.getEmployees({
    // itemsPerPage: 9999,
    // page: 1,
    email: user.email,
  }).then((resp) => {
    console.log("Select emp", resp.data['hydra:member'])
    employee.value = resp.data["hydra:member"];
    return true;
  });
}

const getAllEmployees = async () => {
  return await EmployeeServices.getEmployees({active: 1, itemsPerPage: 9999, page: 1}).then((resp) => {
    employees.value = resp.data["hydra:member"];
    return true;
  });
}
const save = () => {
  form.value.title = title.value;
  form.value.newsBody = body.value;
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (route.params.id) {
      updateUndanews()
    } else {
      createUndanew();
    }
  }
}
const updateUndanews = () => {

  if (fileImg.value) {
    undanewsMediaObjects.value.push(fileImg.value["@id"]);
  }
  const params = {
    title: title.value,
    newsBody: body.value,
    timestamp: dateUp.value,
    employee: employee.value[0]["@id"],
    newsStatus: status.value,
    readConfirmation: newsRead.value,
  };
  if (undanewsMediaObjects.value.length > 0) {
    params.undanewsMediaObjects = []
    undanewsMediaObjects.value.map((v) => {
          params.undanewsMediaObjects.push(v["@id"])
        }
    );
  }
  if (undanew.value.undanewsMediaObjects.length > 0) {
    params.undanewsMediaObjects = []
    undanew.value.undanewsMediaObjects.map((v) => {
          params.undanewsMediaObjects.push(v["@id"])
        }
    );
  }

  disableSave.value = true
  Undanew.update(route.params.id, params).then(() => {
    disableSave.value = false
    router.push({name: "undanews"});
  });
}
const createUndanew = async () => {
  //console.log("Titulo",status);

  console.log("create status", status.value)
  const params = {
    title: title.value,
    newsBody: body.value,
    timestamp: dateUp.value,
    undanewsMediaObjects: [],
    employee: employee.value[0]["@id"],
    newsStatus: status.value,
    readConfirmation: newsRead.value,
  };
  if (undanewsMediaObjects.value.length > 0) {
    undanewsMediaObjects.value.map((v) => {
          params.undanewsMediaObjects.push(v["@id"])
        }
    );
  }

  disableSave.value = true
  Undanew.create(params).then(() => {
    disableSave.value = false
    router.push({name: "undanews"});
  }).catch(() => {
    Notification.addNotification(
        "Error al guardar la noticia",
        "error",
        6000
    );
  });
}
const getStatus = () => {
  Undanew.getStatus({itemsPerPage: 9999, page: 1})
      .then((resp) => {
        optionsStatus.value = resp.data["hydra:member"];
        if (!route.params.id) {
          status.value = optionsStatus.value[0]['@id']
        }
      });
}
// eslint-disable-next-line no-unused-vars
const setDisableSelect = (val) => {
  disableSelect.value = val;
}
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
</script>

<style
    lang="scss"
    scoped>
.radio-larg {
  width: 25px;
  height: 25px;
}

.image-slot {
  width: 20em;
  height: 20em;
  background: $red-color;
}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;
  }
}

.icon-undanews {
  fill: $font-blue-color;
  width: 6.5%;
}

.title-text {
  text-align: center;
  align-self: center;

  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;
}
</style>
