<template>

    <el-row class="mt-4">
      <el-col :span="24">
        <div class="table table-users pt-0 px-4 w-100">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex justify-content-start">
                    <img src="@/assets/icons/user.svg" alt="" width="20"/>
                    <span class="ml-1">NOMBRES Y APELLIDOS</span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                     <img src="@/assets/icons/calendar-icon.svg" alt="" width="20"/>
                    <span class="mt-1 ml-1">FECHA</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="happy in happyVerde"
                :key="happy.id+happy.name"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <CellEmployee
                      :employee="happy"
                      :without-link="true"
                    />
                  </div>
                </td>
                <td>
                  {{ formatDate(happy.birthday) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
</template>

<script setup>
    import CellEmployee from "@/components/shared/CellEmployee.vue";
    import moment from 'moment';
    import {onMounted, defineProps} from "vue";

    const props = defineProps({
      happyVerde:{
            type:Array,
            required:true
        }
    })

     onMounted(() => {
       console.log("HAPPY", props.happyVerde);
     })
       

     const  formatDate = (date) =>{
            if(!date)return '';
            return moment(date,'YYYY-MM-DD').format('dddd, DD')+' de '+moment(date,'YYYY-MM-DD').format('MMMM');
     }

</script>

<style scoped lang="scss">
 #islands
    {
      fill: $bg-blue;
      width: 25px;
    }
#calendar-icon {
      fill: $bg-blue;
      height: 25px;
      width: 25px;
    }
 .card-title {
        letter-spacing: -0.05em;
      
        font-weight: bolder;
        margin-right: 10px;
        letter-spacing: -0.05em;
        font-size: 1.5em;
        color: $font-blue-color;
      }
 .table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }
    tbody {
      td {
          letter-spacing: -0.05em;
      
        font-weight: bolder;
      
        letter-spacing: -0.05em;
        font-size: 0.9em;
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>