<script setup>


import {ref, defineProps} from "vue";

defineProps({
  employee: {
    type: Object,
    required: true
  }
});
const  baseUrl = ref(process.env.VUE_APP_BASE_URI);
const showModalEmployeeImage = ref(false);
</script>

<template>
  <div
      v-if="employee.photo && employee.photo.contentUrl"
      class="media pointer"
  >
    <img
        class="rounded-circle img-thumbnail"
        :src="baseUrl + employee.photo.contentUrl"
        :alt="'Employee image'"
        @click="showModalEmployeeImage = true"
        :title="'Click para ampliar'"
    >
  </div>
  <div
      v-else
      class="media"
  >
    <img
        class="rounded-circle img-thumbnail"
        src="~@/assets/images/user.png"
        :alt="'Default Image'"
    >
  </div>
  <el-dialog
      width="30%"
      height="400px"
      v-model="showModalEmployeeImage"
      :title="employee.name + ' ' + employee.lastName"
      theme="secondary"
      size="small"
  >
    <div class="d-flex justify-content-center mx-auto">
      <img
          class="img-modal mx-auto"
          :src="baseUrl + employee.photo.contentUrl"
          :alt="'Employee image {{employee.name}} {{employee.lastName}}'"
      >
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
.media {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 56px;
    height: 56px;
  }
}
.img-thumbnail {
  height: 50px;
  width: 50px;
}
.img-modal {
  width: 325px;
}
.modal-image {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

</style>
