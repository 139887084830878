// Router module dependencies
import {createRouter, createWebHashHistory} from 'vue-router'
//import store from '@/app/store/store'
// Authentication
import auth from '@/api/auth/auth'

// Components
import Home from '@/views/Home.vue'

//import common from '@/api/services/common.service';
import Auth from '@/views/auth/Auth.vue'
import Login from '@/views/auth/Login.vue'
import Employee from "@/views/employee/Employee.vue";
import EmployeeItem from "@/views/employee/EmployeeItem.vue";
import Holidays from "@/views/holidays/Holidays.vue";
import HolidayItem from "@/views/holidays/HolidayItem.vue";
import EmployeeCalendar from "@/views/employee/EmployeeCalendar.vue";
/* import PresenceHome from "@/views/presence/PresenceHome.vue" */
import ShowCurrentPresence from "@/views/presence/ShowCurrentPresence.vue";
import ShowMyPresence from "@/views/presence/ShowMyPresence.vue";
/* import PresenceReport from "@/views/presence/PresenceReport.vue" */
import PresenceReportNew from "@/views/presence/PresenceReportNew.vue"
import Job from "@/views/masters/job/Job.vue";
import JobItem from "@/views/masters/job/JobItem.vue";
import Department from "@/views/masters/department/Department.vue";
import Workplace from "@/views/masters/workplace/Workplace.vue";
import WorkplaceItem from "@/views/masters/workplace/WorkplaceItem.vue";
import LeaveType from "@/views/masters/leavetype/LeaveType.vue";
import AbsenceType from "@/views/masters/absencetype/AbsenceType.vue";
import Absence from "@/views/absence/Absence.vue";
import AbsenceItem from "@/views/absence/AbsenceItem.vue";
import HolidayIncompatibilities from "@/views/masters/holiday/HolidayIncompatibilities.vue";
import HolidayCalendar from "@/views/masters/holidayCalendar/HolidayCalendar.vue";
import ProductionDashboard from "@/views/production/ProductionDashboard.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import RecoverPassword from "@/views/auth/RecoverPassword.vue";
import SearchProjects from "@/views/projects/SearchProjects.vue";
import ShowProject from "@/views/projects/ShowProject.vue";
import SuggestionsSearch from "@/views/suggestions/SuggestionsSearch.vue";
import SuggestionsUpdate from "@/views/suggestions/SuggestionsUpdate.vue";
import MySuggestions from "@/views/suggestions/MySuggestions.vue";
import FormSuggestion from "@/views/suggestions/FormSuggestion.vue";
import ListUnadews from "@/views/communication/listUndanews.vue";
import ListUndanitas from "@/views/communication/listUndanitas.vue";
import FormUndanews from "@/views/communication/FormUndanews.vue";
import FormUndanitas from "@/views/communication/FormUndanitas.vue";
import SearchBenefits from "@/views/benefits/SearchBenefits.vue";
import MyBenefit from "@/views/benefits/MyBenefit.vue";
import FormBenefit from "@/views/benefits/FormBenefit.vue";
import UpdateBenefit from "@/views/benefits/UpdateBenefit.vue";
import DomainsSearch from "@/views/domain/DomainsSearch.vue";
import Tickets from "@/views/tickets/Tickets.vue";
import ListClients from "@/views/clients/ListClients.vue";
import EditClient from "@/views/clients/EditClient.vue";
import TicketNew from "@/views/tickets/TicketNew.vue";
import TicketUpdate from "@/views/tickets/TicketUpdate.vue";
import InternalTicketNew from "@/views/internal-tickets/InternalTicketNew.vue";
import InternalTickets from "@/views/internal-tickets/InternalTickets.vue";
import InternalTicketUpdate from "@/views/internal-tickets/InternalTicketUpdate.vue";
import TrackerNet from "@/views/tracker-net/TrackerNet.vue";
import TrackerNetReport from "@/views/tracker-net/TrackerNetReport.vue";

// Methods
const redirectTo = () => {
    return '/home';
}


// Routes
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/employees',
            name: 'employees',
            component: Employee,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/employees/:id',
            name: 'employee',
            component: EmployeeItem,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/absences',
            name: 'absences',
            component: Absence,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/absences/:id',
            name: 'absence',
            component: AbsenceItem,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/holidays',
            name: 'holidays',
            component: Holidays,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/holidays/:id',
            name: 'holiday',
            component: HolidayItem,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/calendar',
            name: 'calendar',
            component: EmployeeCalendar,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/presence',
            name: 'presence',
            component: ShowCurrentPresence,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/mypresence/:id',
            name: 'my-presence',
            component: ShowMyPresence,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/presence-report',
            name: 'presence-report',
            component: PresenceReportNew,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/jobs',
            name: 'jobs',
            component: Job,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}

        },
        {
            path: '/jobs/:id',
            name: 'job',
            component: JobItem,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/departments',
            name: 'departments',
            component: Department,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/workplaces',
            name: 'workplaces',
            component: Workplace,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/workplaces/:id',
            name: 'workplace',
            component: WorkplaceItem,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/leave_types',
            name: 'leave-types',
            component: LeaveType,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/absence_types',
            name: 'absence-types',
            component: AbsenceType,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/holiday-incompatibilities',
            name: 'holiday-incompatibilities',
            component: HolidayIncompatibilities,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/holiday-calendar',
            name: 'holiday-calendar',
            component: HolidayCalendar,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/production',
            name: 'production',
            component: ProductionDashboard,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/projects',
            name: 'projects',
            component: SearchProjects,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/clients',
            name: 'clients',
            component: ListClients,
            meta: {requiresAuth: true, roles: ['ROLE_SYSTEMS', 'ROLE_ADMIN']}
        },{
            path: '/clients/:id',
            name: 'show-client',
            component: EditClient,
            meta: {requiresAuth: true, roles: ['ROLE_SYSTEMS', 'ROLE_ADMIN']}
        },
        {
            path: '/show-projects/:id',
            name: 'show-projects',
            component: ShowProject,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/benefits',
            name: 'benefits-search',
            component: SearchBenefits,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        }, {
            path: '/benefits',
            name: 'create-benefits',
            component: FormBenefit,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/benefits/:id',
            name: 'update-benefit',
            component: UpdateBenefit,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/mybenefits',
            name: 'my-benefits',
            component: MyBenefit,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN', 'ROLE_USER']}
        },
        {
            path: '/suggestions',
            name: 'suggestions-search',
            component: SuggestionsSearch,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/suggestions/:id',
            name: 'suggestions-update',
            component: SuggestionsUpdate,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/mysuggestions/:id',
            name: 'my-suggestions',
            component: MySuggestions,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/createsuggestion',
            name: 'createsuggestion',
            component: FormSuggestion,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/tracker',
            name: 'tracker',
            component: TrackerNet,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/tracker-report',
            name: 'tracker-report',
            component: TrackerNetReport,
            meta: {requiresAuth: true, roles: ['ROLE_ADMIN']}
        },
        {
            path: '/change-password',
            name: "change-password",
            component: ChangePassword,
            meta: {
                title: "Restablecer contraseña",
                requiresAuth: true,
            }
        },
        {
            path: '/undanews',
            name: 'undanews',
            component: ListUnadews,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/new_undanews',
            name: 'new_undanews',
            component: FormUndanews,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/new_undanews/:id',
            name: 'update_undanews',
            component: FormUndanews,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/undanitas',
            name: 'undanitas',
            component: ListUndanitas,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/new_undanitas',
            name: 'new_undanitas',
            component: FormUndanitas,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/undanitas/:id',
            name: 'update_undanitas',
            component: FormUndanitas,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/systems/domains',
            name: 'domains-search',
            component: DomainsSearch,
            meta: {requiresAuth: true, roles: ['ROLE_SYSTEMS', 'ROLE_ADMIN']}
        },
        {
            path: '/systems/tickets',
            name: 'tickets',
            component: Tickets,
            meta: {requiresAuth: true, roles: ['ROLE_SYSTEMS', 'ROLE_ADMIN']}
        },
        {
            path: '/systems/tickets/new',
            name: 'new_ticket',
            component: TicketNew,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/systems/tickets/:id',
            name: 'update_ticket',
            component: TicketUpdate,
            meta: {requiresAuth: true, roles: ['ROLE_USER', 'ROLE_ADMIN']}
        },
        {
            path: '/internal-tickets',
            name: 'internal_tickets',
            component: InternalTickets,
            meta: {requiresAuth: true, roles: ['ROLE_USER']}
        },
        {
            path: '/internal-tickets/new',
            name: 'new_internal_ticket',
            component: InternalTicketNew,
            meta: {requiresAuth: true, roles: ['ROLE_USER']}
        },
        {
            path: '/internal-tickets/:id',
            name: 'update_internal_ticket',
            component: InternalTicketUpdate,
            meta: {requiresAuth: true, roles: ['ROLE_USER']}
        },
        {
            path: '/auth',
            name: 'auth',
            component: Auth,
            redirect: 'login',
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: Login
                },
                {
                    path: '/recover-password',
                    name: 'recover-password',
                    component: RecoverPassword
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: redirectTo()
        }
    ]
})

router.beforeEach((to, from, next) => {
    // let requiresAuth = to.matched.some(record => record.meta.requiresAuth );
    // console.log('requires auth=' + requiresAuth);
    //const roles = store.getters.getUserLogged ? store.getters.getUserLogged.roles : null;
    //const allowedRoles = to.matched.reduce((acc, route) => {
        //return route.meta.roles && !acc.length ? route.meta.roles : acc
    //}, [])

    // if(to.matched.some(record => record.meta.requiresAuth)) {
    //   // console.log('getUserLogger', store.getters.getUserLogged);
    //   if (auth.checkAuth()) {
    //     // console.log('estoy autenticado')
    //     // if (store.getters.getUserLogged) {
    //     if (to.matched.some(record => record.meta.roles)) {
    //       if (common.ifArrayIsInArray(allowedRoles, roles)) {
    //         next()
    //       } else {
    //         next(redirectTo);
    //       }
    //     } else {
    //       // console.log('no se donde voy 2')
    //       next()
    //     }
    //   } else {
    //     // console.log('no estoy autenticado me voy al login');
    //     next({name: 'login'});
    //   }
    // } else {
    //   // console.log('al login')
    //   next();
    // }


    if (to.matched.some(record => record.meta.requiresAuth) && !auth.checkAuth()) {
        next({name: 'login'});
    } else if (to.name === 'login' && auth.checkAuth()) {
        next({name: 'home'});
    } else {
        next();
    }

});

// router.beforeEach((to, from, next) => {
//   var requiresAuth = to.matched.some( record => record.meta.requiresAuth );
//   var currentUser = false;
//   // when route requires auth and there's no current user, reidrect to '/login'
//   if(requiresAuth && !currentUser){
//     next('/login');
//   // when we go to login route and are already logged in, we can skip this page
//   // so we redirect to the homepage
//   } else if (to.path == '/login' && currentUser){
//     next('/');
//   // if none of the above matches, we have a normal navigation that should just go through
//   // so we call `next()`
//   } else {
//     next(); // you called `next('/')` which redirected to the homepage over and over again.
//   }
// });

export default router;
