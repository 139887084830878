import Api from "@/api/Api";
import errorHandler from "@/utils/plugins/ErrorHandler";

const RESOURCE_NAME_CLIENT = '/api/clients';
const RESOURCE_NAME_CLIENT_EXTRANET = '/api/extranet_users';
export default {
    async searchClients(params){
        return Api()
            .get(RESOURCE_NAME_CLIENT,{params})
            .catch((err) => errorHandler(err, null));
    },
    async getExtranetUsers(id){
        return Api()
            .get(RESOURCE_NAME_CLIENT+'/'+id+'/extranet_users')
            .catch((err) => errorHandler(err, null));
    },
    async getClient(id){
        return Api()
            .get(RESOURCE_NAME_CLIENT+'/'+id)
            .catch((err) => errorHandler(err, null));
    },
    async editClient(id,client){
        return Api()
            .put(RESOURCE_NAME_CLIENT+'/'+id,client)
            .catch((err) => errorHandler(err, null));
    },
    async createUserExtranet(client){
        return Api()
            .post(RESOURCE_NAME_CLIENT_EXTRANET,client)
            .catch((err) => errorHandler(err, null));
    },
    async updateUserExtranet(id,client){
        return Api()
            .put(RESOURCE_NAME_CLIENT_EXTRANET+'/'+id,client)
            .catch((err) => errorHandler(err, null));
    },
}
