<template>
  <div>
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Tipos de Baja
      </div>
    </div>
    <el-card
             class="card-search w-100 wback"
             body-style="padding:0"
             style="height: auto !important;"
    >
      <div class="row mx-5">
    <div class="container-leave-type">
      <div class="title-leave-type">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class=" form-item  date-picker  label-common">
                  <label class=" w-100 mb-2">Tipo de baja</label>
                  <el-input
                      v-model="searchFilterLeaveTypeName"
                      placeholder="Tipo de Baja"
                      prefix-icon="el-icon-search"
                      clearable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
          <button
              style="width: 280px;height: 40px;"
              class="btn btn_yellow btn-search"
              @click="search()"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="leaveTypes && leaveTypes.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="leaveType in leaveTypes"
              :key="leaveType.id"
            >
              <td>
                {{ leaveType.name }}
              </td>
              <td>{{ leaveType.observations }}</td>
              <td>
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editLeaveType(leaveType)"
                    />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedLeaveType = leaveType;"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado tipos de baja laboral</h5>
      </div>

      <SharedPagination
        v-if="leaveTypes && leaveTypes.length > 4"
        class="pagination"
        :page="page"
        :total-results="totalResults"
        :page-size="itemsPerPage"
        style="padding: 0px 0px 20px 0px;"
        @change="page = $event; getLeaveTypes()"
      />

      <div class="row text-right">
        <div class="col-md-4 offset-md-8">
          <button @click="createOrUpdateLeaveType = true" class="btn btn-primary">NUEVO TIPO DE BAJA</button>
        </div>
      </div>
      
    </div>

    <el-dialog
        v-model="createOrUpdateLeaveType"
        class="dense-dialog"
        @closed="closeCreateOrUpdateDialog()">
      <div>
        <form @submit.prevent="">
          <div class="row">
            <div class="col-md-6">
              <label for="leaveTypeName" class="w-100">Tipo de Baja</label>
              <el-input
                  v-model="leaveTypeName"
                  :placeholder="'Tipo de Baja'"
              />
            </div>
            <div class="col-md-6" />
          </div>
          <div class="col-md-6 mt-3">
            <div class="form-item">
              <label class="w-100">Motivo de la baja</label>
              <textarea
                  rows="8"
                  class="w-100 p-2"
                  v-model="leaveTypeObservations"
                  :placeholder="'Motivo de la baja'"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 offset-md-8 d-flex flex-column justify-content-end align-items-end">
              <button
                  class="btn btn-primary"
                  @click="selectedLeaveType ? handleUpdateDepartment() : createLeaveType(leaveType)"
              >
                {{ selectedLeaveType ? 'Actualizar' : 'Nuevo'}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
      v-model="deleteDialogShown"
      class="dense-dialog"
      :title="'¿Desea eliminar permanentemente este tipo de baja?'"
      @closed="selectedLeaveType = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedLeaveType = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteLeaveType(selectedLeaveType)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';


export default {
  name: "LeaveTypes",
  components: {
    SharedPagination,
    HeaderTitle,
  },
  data() {
    return {
      leaveTypes: [],
      leaveType: {},
      submitted: false,
      selectedLeaveType: null,
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      leaveTypeName: '',
      leaveTypeObservations: '',
      deleteDialogShown: false,
      createOrUpdateLeaveType: false,
      searchFilterLeaveTypeName: ''

    }
  },
  mounted() {
    this.getLeaveTypes();
  },
  methods: {

    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    editLeaveType(leaveType) {
      this.selectedLeaveType = leaveType;
      this.leaveTypeName = leaveType.name;
      this.leaveTypeObservations = leaveType.observations;
      this.createOrUpdateLeaveType = true
    },
    handleUpdateDepartment(){
      this.selectedLeaveType.name = this.leaveTypeName;
      this.updateLeaveType(this.selectedLeaveType)
    },
    getLeaveTypes(searchQuery = null) {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getLeaveTypes(params)
          .then((res) => {
            this.leaveTypes = res.data['hydra:member'];
          })
    },
    createLeaveType(leaveType) {
      leaveType.name = this.leaveTypeName;
      leaveType.observations = this.leaveTypeObservations;
      this.$store.state.loading = true;
      ResourceService.createLeaveType(leaveType)
          .then(() => {
            Notification.addNotification('Tipo de baja creada correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al crear tipo de baja', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    updateLeaveType(selectedLeaveType) {
      if (this.leaveTypeName) {
        selectedLeaveType.name = this.leaveTypeName;
      }

      selectedLeaveType.observations = this.leaveTypeObservations;

      this.$store.state.loading = true;
      ResourceService.updateLeaveType(selectedLeaveType, selectedLeaveType.id)
          .then(() => {
            Notification.addNotification('Tipo de baja actualizado correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el tipo de baja', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    deleteLeaveType(leaveType) {
      ResourceService.deleteLeaveType(leaveType.id)
          .then(() => {
            Notification.addNotification('Tipo de baja eliminado correctamente.', 'success', 6000);
            this.deleteDialogShown = false;
            this.cancel();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el tipo de baja seleccionado', 'error', 6000);
        this.$store.state.loading = false;
      })
    },
    reset(){
      this.leaveType = {};
      this.leaveTypeName = '';
      this.leaveTypeObservations = '';
      this.selectedLeaveType = null;
    },
    cancel() {
      this.reset()
      this.getLeaveTypes();
    },
    closeCreateOrUpdateDialog(){
      this.createOrUpdateLeaveType = false
      this.reset()
    },
    search(){
      this.getLeaveTypes(this.searchFilterLeaveTypeName)
    }
  }


}
</script>

<style lang="scss" scoped>

.leave-type-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-leave-types {
  table {
    //margin-left: auto;
    //margin-right: auto;
    //width: auto;
  }
}

</style>
