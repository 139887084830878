import axios from 'axios'
import CONSTS from '@const/constants'


export default (apiKey) => {
  return axios.create({
    baseURL: CONSTS.CLOCKIFY_API,
    withCredentials: false,
    headers: {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      'x-api-key':  apiKey
    },
  })
}
