<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import Notification from "@/utils/plugins/NotificationService";
import TicketService from "@/api/services/ticket.service";
import TicketStatusService from "@/api/services/ticket.status.service";
import TicketTypeService from "@/api/services/ticket.type.service";
import Common from "@/api/services/common.service";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import {useStore} from "vuex";
import {h, onMounted, ref, shallowRef} from "vue";
import {useRouter} from "vue-router";
import UserTickets from "@/views/internal-tickets/UserTickets.vue";
import DepartmentTickets from "@/views/internal-tickets/DepartmentTickets.vue";
import stateColors from "@const/stateColors";
import moment from "moment";
const store = useStore();
const router = useRouter();

// eslint-disable-next-line no-unused-vars
const optionsItemsPerPage = ref([
  {id: 0, name: '10', value: 10},
  {id: 1, name: '25', value: 25},
  {id: 2, name: '50', value: 50},
  {id: 3, name: '100', value: 100},
  {id: 4, name: '10000', value: 1000},
]);

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})
const itemsPerPage = ref({id: 4, name: '15', value: 15});
const page = ref(1);
const totalResults = ref(0);
const refreshData = ref(false);
// eslint-disable-next-line no-unused-vars
const customerName = ref("");
const tag = ref("");
const type = ref(null);
const types = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const orderDescription = ref(null);
const orderType = ref(null);
const orderStatus = ref(null);
const tickets = ref([]);
// eslint-disable-next-line no-unused-vars
const totalTickets = ref(0);
const statusTicket = ref(null);
const optionsStatus = ref([])



 onMounted(async () => {
    await getTypes();
    await getStates();
    await getTickets();
  })
    const search = async () => {
      refreshData.value = !refreshData.value;
      await getTickets();
    }
    const getStates = async () => {
      const response = await TicketStatusService.getStates({itemsPerPage: 999999, page: 1});
      optionsStatus.value = response.data['hydra:member'];
    }
    const getTypes = async() =>{
      const response = await TicketTypeService.getTypes({itemsPerPage:999999,page:1});
      types.value = response.data['hydra:member'];
    }

    const sumTotalTicket = (total) =>{
      totalResults.value +=total;
    }
    const gotoEdit = (id) => {
      router.push({name: 'update_internal_ticket', params:{id:id}})
    }
// eslint-disable-next-line no-unused-vars
    const ifIsInArray = (userRoles, role) => {
      // console.log("userRoles", userRoles);
      return Common.ifIsInArray(userRoles, role);
    }
    const typeColor = (type) => {
      if (type === 'Incidencia')
        return {color: '#F9156F', label: 'INCIDENCIA'};
      else if (type === 'Solicitud de servicio') {
        return {color: '#4AABC3', label: 'SERVICIO'};
      } else if (type === 'Solicitud de soporte (Duda)') {
        return {color: '#919191', label: 'SOPORTE'};
      }
      return 'grey'
    }
    const getTickets = () => {

      //console.log("Type tickets",type);
      const params = {
        itemsPerPage: itemsPerPage.value.value,
        page: page.value,
        'order[createdAt]': 'DESC',
        internal: true
      }

      if (statusTicket.value) {
        params['ticketStatusHistories.status.id'] = statusTicket.value;
        // if status is closed, we need to check if the ticket is still open
        if (statusTicket.value !== 5) {
          params['exists[ticketStatusHistories.dateEnd]'] =  false;
        }
      }
      if (type.value) {
        params['ticketType.id'] = type.value;
      }

      if (tag.value){
        params.tag = tag.value;
      }

      if (selectedFromDate.value && selectedToDate){
        params["createdAt[strictly_before]"] = selectedToDate.value;
        params["createdAt[strictly_after]"] = selectedFromDate.value;
      }

      store.dispatch('setLoading', true);
        TicketService.getAssignedTickets(store.getters.getUserLogged.id, params)
            .then((response) => {
              tickets.value = response.data['hydra:member'];
              tickets.value.forEach(aTicket => {
                if(typeof aTicket.ticketStatusHistories !== 'undefined' && aTicket.ticketStatusHistories.length > 0){
                  let lastStatus = aTicket.ticketStatusHistories.find(item => {
                    if (!item.dateEnd) {
                      return item.status?.name ? item.status.name : null;
                    }
                    aTicket.lastStatus = lastStatus.status.name;

                  })
                  if (!lastStatus) {
                    lastStatus = aTicket.ticketStatusHistories.find(item => {
                      if (item.dateStart && item.dateEnd && item.status && item.status.name === "Cerrado") {
                        return item.status?.name ? item.status.name : null;
                      }
                    })
                  }
                  aTicket.lastStatus = lastStatus?.status?.name ? lastStatus.status.name : null;
                }

              })
              totalResults.value = tickets.value.length;
            })
            .catch((error) => {
              console.log(error);
              Notification.addNotification('Error al obtener listado de tickets.', 'error', 6000);
            });
      store.dispatch('setLoading', false);
    }

const formatDateLocale = (value, loc = 'es') => {
  if (loc) {
    loc = 'es';
  }
  moment.updateLocale('es', {
    monthsShort: [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ]
  });
  if (value) {
    return moment(value).local(loc).format('DD/MM/YYYY HH:mm:ss');
  } else {
    return null;
  }
}

</script>

<template>

  <div id="tickets">
    <header-title title="Tickets Internos" />
    <el-row>
      <el-card
          class="card-search w-100 wback"
          body-style="padding:0"
      >
        <div class="row mx-5">
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-4 w-100 label-common pl-5">
              <label for="ffrom" class="w-100">Fecha desde</label>
              <el-date-picker
                  id="ffrom"
                  v-model="selectedFromDate"
                  name="from-dat'"
                  type="date"
                  placeholder="Fecha desde"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-4 w-100 label-common pl-5">
              <label for="fto" class="w-100">Fecha hasta</label>
              <el-date-picker
                  id="fto"
                  v-model="selectedToDate"
                  name="from-date"
                  type="date"
                  placeholder="Fecha hasta"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-4 w-100 label-common pl-5">
              <label for="id-status" class="w-100">Estado</label>
              <el-select v-model="statusTicket"
                         filterable
                         clearable
                         placeholder="TODOS"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-4 w-100 label-common">
              <label for="domain-name" class="w-100">Código</label>
              <el-input
                  id="title"
                  v-model="tag"
                  class="search-input"
                  placeholder="Código"
                  clearable
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item label-common">
              <label class="w-100 mt-4">Tipo</label>
              <el-select v-model="type"
                         filterable
                         clearable
                         placeholder="TODOS"
              >
                <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-9 col-md-6 col-lg-8 d-flex flex-column justify-content-end align-items-end" style="padding-right: 0;">
            <button
                class="btn btn_yellow btn-search"
                @click="search()"
            >
              Buscar
            </button>
          </div>
        </div>
      </el-card>
      <div class="mt-2" v-if="!store.state.loading">
        <span class="text-result">{{ totalResults }} Tickets </span>
        <span class="text-result-blue"> totales</span>
      </div>
    </el-row>
    <nav class="mt-3" v-if="!store.state.loading">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="requested-tab" data-bs-toggle="tab" data-bs-target="#requested" type="button" role="tab" aria-controls="requested" aria-selected="false">
          <div class="d-flex">
            <img src="@/assets/icons/group.svg" width="30" alt="">
            <div class="title-text pl-4">
              Tickets solicitados
            </div>
          </div>
        </button>
        <button class="nav-link" id="assigned-tab" data-bs-toggle="tab" data-bs-target="#assigned" type="button" role="tab" aria-controls="assigned" aria-selected="true">
          <div class="d-flex">
            <img src="@/assets/icons/group.svg" width="30" alt="">
            <div class="title-text pl-4">
              Tickets asignados
            </div>
          </div>
        </button>
        <button v-if="store.getters.getUserLogged && store.getters.getUserLogged.departmentManager"
                class="nav-link"
                id="department-tab"
                data-bs-toggle="tab"
                data-bs-target="#department"
                type="button"
                role="tab"
                aria-controls="department"
                aria-selected="false">
          <div class="d-flex">
            <img src="@/assets/icons/group.svg" width="30" alt="">
            <div class="title-text pl-4">
              Tickets Departamento
            </div>
          </div>
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="requested" role="tabpanel" aria-labelledby="requested-tab">
        <el-row>
          <UserTickets @totalTicket="sumTotalTicket" :refreshData="refreshData" />
        </el-row>
        <el-row class="text-right">
          <el-col>
            <router-link
                :to="{name: 'new_internal_ticket'}"
                class="btn btn_darkpink search btn-new"
            >
              NUEVO TICKET
            </router-link>
          </el-col>

        </el-row>
      </div>
      <div class="tab-pane fade" id="assigned" role="tabpanel" aria-labelledby="assigned-tab">
        <el-row>
          <div class="table table-domains pt-0" v-if="tickets.length && !store.state.loading">
            <table>
              <thead>
              <tr>
                <th>
                  <div
                      class="d-flex justify-content-start align-items-center pointer"
                  >
                    <div>
                      <img
                          width="21"
                          height="20"
                          class="mr-2"
                          src="@/assets/icons/group.svg"
                          alt="">CÓDIGO
                      <font-awesome-icon  v-if="orderType === 'ASC'" icon="sort-alpha-up" />
                      <font-awesome-icon
                          v-else-if="orderType === 'DESC'"
                          icon="sort-alpha-down"
                      />
                      <font-awesome-icon v-else icon="sort" />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                      class="d-flex justify-content-start align-items-center pointer"
                  >
                    <div>
                      <img
                          width="21"
                          height="20"
                          class="mr-2"
                          src="@/assets/icons/group.svg"
                          alt="">TIPO
                      <font-awesome-icon  v-if="orderType === 'ASC'" icon="sort-alpha-up" />
                      <font-awesome-icon
                          v-else-if="orderType === 'DESC'"
                          icon="sort-alpha-down"
                      />
                      <font-awesome-icon v-else icon="sort" />
                    </div>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start align-items-center pointer">
                    <div>
                      <img
                          width="21"
                          height="20"
                          src="@/assets/icons/group.svg"
                          alt=""> DESCRIPCIÓN
                      <font-awesome-icon
                          v-if="orderDescription === 'ASC'"
                          icon="sort-alpha-up"
                      />
                      <font-awesome-icon
                          v-else-if="orderDescription === 'DESC'"
                          icon="sort-alpha-down"
                      />
                      <font-awesome-icon
                          v-else
                          icon="sort"
                      />
                    </div>
                  </div>
                </th>
                <th style="width: 230px;">
                  <div
                      class="d-flex justify-content-start align-items-center pointer"
                      @click="changeOrderType()"
                  >
                    <div>
                      <img
                          width="21"
                          height="20"
                          class="mr-2"
                          src="@/assets/icons/calendar-blue.svg"
                          alt="fecha inicio"
                      >FECHA INICIO
                      <font-awesome-icon
                          v-if="typeOrder === 'ASC'"
                          icon="sort-alpha-up"
                      />
                      <font-awesome-icon
                          v-else-if="typeOrder === 'DESC'"
                          icon="sort-alpha-down"
                      />
                      <font-awesome-icon
                          v-else
                          icon="sort"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                      class="d-flex justify-content-start align-items-center pointer"
                      @click="changeOrderType()"
                  >
                    <div>
                      <img
                          width="21"
                          height="20"
                          class="mr-2"
                          src="@/assets/icons/group.svg"
                          alt="estado"
                      >ESTADO
                      <font-awesome-icon
                          v-if="orderType === 'ASC'"
                          icon="sort-alpha-up"
                      />
                      <font-awesome-icon
                          v-else-if="orderType === 'DESC'"
                          icon="sort-alpha-down"
                      />
                      <font-awesome-icon
                          v-else
                          icon="sort"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                      class="d-flex justify-content-start align-items-center pointer"
                  >
                    <div>
                      <img
                          width="21"
                          height="20"
                          class="mr-2"
                          src="@/assets/icons/user.svg"
                          alt="">ASIGNADO A
                      <font-awesome-icon
                          v-if="orderStatus === 'ASC'"
                          icon="sort-alpha-up"
                      />
                      <font-awesome-icon  v-else-if="orderStatus === 'DESC'"  icon="sort-alpha-down"/>
                      <font-awesome-icon
                          v-else
                          icon="sort"
                      />
                    </div>
                  </div>
                </th>

              </tr>
              </thead>
              <tbody v-if="tickets && tickets.length && !store.state.loading">
              <tr
                  v-for="ticket in tickets"
                  :key="ticket.id"
              >
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    [{{ ticket.tag }}] - {{ ticket.id }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    <div
                        class="type-color"
                        :style="{backgroundColor: typeColor(ticket.ticketType?.name).color}"
                    /> {{ typeColor(ticket.ticketType?.name).label }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    {{ ticket.issue }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-start align-items-center">
                    {{ formatDateLocale(ticket.createdAt) }}
                  </div>
                </td>
                <td>
                <div class="flex-row justify-content-between" :style="{color: stateColors.getColor(ticket.status)}">
                  {{ ticket.status }}
                </div>
                </td>
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <span> {{ store.getters.getUserLogged.name }} {{ store.getters.getUserLogged.lastName }}</span>
                    <shared-button-plus
                        @onClick="gotoEdit(ticket.id)"
                        :title="'Editar ticket'"
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="alert alert-info mt-3" v-if="!store.state.loading">
              No tiene tickets asignados actualmente
            </p>
          </div>
        </el-row>
        <el-row>
          <SharedPagination
              v-if="tickets && totalResults > itemsPerPage.value"
              class="pagination"
              :page="page"
              :total-results="totalResults"
              :page-size="itemsPerPage.value"
              @change="page = $event;getTickets();"
          />
        </el-row>
      </div>

      <div v-if="store.getters.getUserLogged && store.getters.getUserLogged.departmentManager"
           class="tab-pane fade"
           id="department"
           role="tabpanel"
           aria-labelledby="department-tab">
        <DepartmentTickets @totalTicket="sumTotalTicket" :refreshData="refreshData" />
      </div>
    </div>





  </div>
</template>

<style scoped lang="scss">

#tickets {
  .buttons {
    margin-left: auto;
  }
  .type-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .title-text {
    text-align: center;
    align-self: center;

    font-size: 3em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: $font-blue-color;
  }

  .card-search {
    border-radius: 15px;
    height: 250px;
    background: url("../../assets/icons/altavoz.png"), white;
    background-repeat: no-repeat;
    background-position-x: 100%;
    overflow: visible !important;
  }

  #icon-search {
    fill: $bg-blue;
    width: 6%;
  }

  .label-common {
    label {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: -2px;
    }
  }
  .btn-new {
    height: 45px;
    width: 300px;
    text-align: center;
    align-items: center;
    font-size: 20px;
  }

  .table-domains {
    table {
      thead {
        tr {
          th {
            padding: 0 !important;
            margin: 0 !important;
          }
        }

        #icon-search {
          fill: $bg-blue;
          width: 2.9em;
        }

        #icon-user {
          fill: $bg-blue;
          width: 1.6em;
        }
      }

      tbody {
        td {
          .link-name {
            margin: auto;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 2.2em;
    fill: #f9156f;
  }

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-header {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;
  }
  .text-result {
    text-align: left;
    font-size: 2em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;

  }
  .text-result-blue {
    text-align: left;
    font-size: 2em;
    font-weight:400;
    letter-spacing: -0.05em;
    color:$bg-blue;

  }
  .wback {
    background-image: url('../../assets/images/ticket-back.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
