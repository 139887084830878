<template>
  <div v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')">
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Motivos de baja
      </div>
    </div>
    <el-card
        class="card-search w-100 wback"
        body-style="padding:0"
        style="height: auto !important;"
    >
      <div class="row mx-5">
        <div class="container-absence-type">
          <div class="title-absence-type">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class=" w-100 mb-2">Motivo</label>
                      <el-input
                          v-model="searchFilterAbsenceTypeName"
                          placeholder="Motivo de baja"
                          prefix-icon="el-icon-search"
                          clearable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
              <button
                  style="width: 280px;height: 40px;"
                  class="btn btn_yellow btn-search"
                  @click="search()"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="absenceTypes && absenceTypes.length > 0">
        <table>
          <thead>
          <tr>
            <th>Motivo de baja</th>
            <th>Observaciones</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="absenceType in absenceTypes"
              :key="absenceType.id"
          >
            <td>
              {{ absenceType.name }}
            </td>
            <td>{{ absenceType.observations }}</td>
            <td>
              <div class="buttons d-inline-flex">
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editAbsenceType(absenceType)"
                  />
                </div>
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedAbsenceType = absenceType;"
                  />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado motivos de baja laboral o ausencias</h5>
      </div>

      <SharedPagination
          v-if="absenceTypes && absenceTypes.length > 4"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getAbsenceTypes()"
      />

      <div class="row text-right">
        <div class="col-md-4 offset-md-8">
          <button @click="createOrUpdateAbsenceType = true" class="btn btn-primary">NUEVO MOTIVO DE BAJA</button>
        </div>
      </div>
      
    </div>

    <el-dialog
        v-model="createOrUpdateAbsenceType"
        class="dense-dialog"
        @closed="closeCreateOrUpdateDialog()">
      <div>
        <form @submit.prevent="">
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label for="absenceTypeName" class="w-100">Motivo de Baja</label>
                <el-input
                    v-model="absenceTypeName"
                    :label="'Motivo de Baja'"
                    :placeholder="'Motivo de Baja'"
                />
              </div>
            </div>
          </div>
          <div class="row item mt-3">
            <div class="col-md-6">
              <div class="form-item">
                <label class="w-100">Observaciones</label>
                <textarea
                    class="w-100 p-2"
                    v-model="absenceTypeObservations"
                    :placeholder="'Observaciones del motivo de la baja'"
                    rows="5"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-8 d-flex flex-column justify-content-end align-items-end">
              <button
                  class="btn btn-primary"
                  @click="selectedAbsenceType ? handleUpdateAbsenceType() : createAbsenceType(absenceType)"
              >
                {{ selectedAbsenceType ? 'Actualizar' : 'Nuevo'}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
        v-model="deleteDialogShown"
        class="dense-dialog"
        :title="'¿Desea eliminar permanentemente este motivo de baja (ausencia)?'"
        @closed="selectedAbsenceType = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedAbsenceType = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteAbsenceType(selectedAbsenceType)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
//import SharedDropdown from "@/components/shared/SharedDropdown.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';
import Common from '@/api/services/common.service';
import {mapState} from "vuex";


export default {
  name: "AbsenceType",
  data() {
    return {
      absenceTypes: [],
      absenceType: {},
      submitted: false,
      selectedAbsenceType: null,
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      absenceTypeName: '',
      absenceTypeObservations: '',
      deleteDialogShown: false,
      createOrUpdateAbsenceType: false,
      searchFilterAbsenceTypeName: ''
    }
  },
  computed: {
    ...mapState({
      user: 'user',
    })
  },
  mounted() {
    if (this.ifIsInArray(this.user.roles, 'ROLE_ADMIN')) {
      this.getAbsenceTypes();
    }
  },
  methods: {
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    editAbsenceType(absenceType) {
      this.selectedAbsenceType = absenceType;
      this.absenceTypeName = absenceType.name;
      this.absenceTypeObservations = absenceType.observations;
      this.createOrUpdateAbsenceType = true
    },
    handleUpdateAbsenceType(){
      this.selectedAbsenceType.name = this.absenceTypeName;
      this.updateAbsenceType(this.selectedAbsenceType)
    },
    getAbsenceTypes(searchQuery = '') {
      const params = {query: {name: searchQuery}};
      ResourceService.getAbsenceTypes(params)
          .then((res) => {
            this.absenceTypes = res.data['hydra:member'];
          })
    },
    createAbsenceType(absenceType) {
      absenceType.name = this.absenceTypeName;
      absenceType.observations = this.absenceTypeObservations;
      this.$store.state.loading = true;
      ResourceService.createAbsenceType(absenceType)
          .then(() => {
            Notification.addNotification('Motivo de baja creada correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al crear motivo de baja', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    updateAbsenceType(selectedAbsenceType) {
      if (this.absenceTypeName) {
        selectedAbsenceType.name = this.absenceTypeName;
      }

      selectedAbsenceType.observations = this.absenceTypeObservations;

      this.$store.state.loading = true;
      ResourceService.updateAbsenceType(selectedAbsenceType, selectedAbsenceType.id)
          .then(() => {
            Notification.addNotification('Motivo de baja actualizado correctamente', 'success', 6000);
            this.$store.state.loading = false;
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el motivo de baja', 'error', 6000);
            this.$store.state.loading = false;
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    deleteAbsenceType(absenceType) {
      ResourceService.deleteAbsenceType(absenceType.id)
          .then(() => {
            Notification.addNotification('Motivo de baja eliminado correctamente.', 'success', 6000);
            this.deleteDialogShown = false;
            this.cancel();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el motivo de baja seleccionado', 'error', 6000);
        this.$store.state.loading = false;
      })
    },
    reset(){
      this.absenceType = {};
      this.absenceTypeName = '';
      this.absenceTypeObservations = '';
      this.selectedAbsenceType = null;
    },
    cancel() {
      this.reset()
      this.getAbsenceTypes();
    },
    closeCreateOrUpdateDialog(){
      this.createOrUpdateAbsenceType = false
      this.reset()
    },
    search(){
      this.getAbsenceTypes(this.searchFilterAbsenceTypeName)
    },
    ifIsInArray(userRoles, role) {
      return Common.ifIsInArray(userRoles, role);
    },
  },
  components: {
    SharedPagination,
    HeaderTitle,
  },
}
</script>

<style lang="scss" scoped>

.absence-type-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

//.table-absence-types {
//  padding: 40px;
//
//  table {
//    width: auto;
//    color: #858796;
//
//    th {
//      border: 1px solid #e3e6f0;
//      padding: 12px;
//      padding-left: 20px;
//      font-size: 20px;
//      text-align: left;
//    }
//
//    tbody {
//      tr:nth-child(odd) {
//        td {
//          background-color: rgba(0, 0, 0, .05);
//        }
//      }
//
//      td {
//        border: 1px solid #e3e6f0;
//        padding: 12px;
//        padding-left: 20px;
//        background-color: white;
//        text-align: left;
//
//        a {
//          font-size: 18px;
//          color: #4e73df;
//        }
//      }
//    }
//  }
//}

</style>