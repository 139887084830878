<script>

import moment from "moment/moment";

export default {
  name: "DnsDataTable",
  // props: ['dns'],
  props: {
    dns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortType: '',
      dnsModel: null
    }
  },
  mounted() {
    // console.log('mounted DNSDataTable', this.dns);
  },
  methods: {
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    sortBy(column) {
      if (this.sortType === '') {
        this.sortType = 'ASC';
      } else if (this.sortType === 'ASC') {
        this.sortType = 'DESC';
      } else if (this.sortType === 'DESC') {
        this.sortType = '';
      }
      this.sortTableData(column);
    },
    sortTableData(column) {
      this.dnsModel.sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];

        if (this.sortType === 'ASC') {
          return valueA.localeCompare(valueB);
        } else if (this.sortType === 'DESC') {
          return valueB.localeCompare(valueA);
        }
        return 0;
      });
    }
  }
};
</script>

<template>
  <div>
    <el-row
      v-if="dns.length>0"
      class="px-4"
    >
      <el-col
        v-if="dns.length>0"
        :span="24"
      >
        <div class="table table-dns pt-0 px-4">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex justify-content-start">
                    <!--                <div class="d-flex justify-content-start" @click="sortBy('type')">-->
                    <span class="mt-1">TIPO
                      <!--                    <font-awesome-icon v-if="sortType === 'ASC'" icon="sort-alpha-up"></font-awesome-icon>-->
                      <!--                    <font-awesome-icon v-else-if="sortType === 'DESC'" icon="sort-alpha-down"></font-awesome-icon>-->
                      <!--                    <font-awesome-icon v-else icon="sort"></font-awesome-icon>-->
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      VALOR
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      IP
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      TTL
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      FECHA INICIO
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      FECHA FIN
                    </span>
                  </div>
                </th>
                <th>
                  <div class="d-flex justify-content-start">
                    <span class="mt-1">
                      ACTUALIZADO
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="aDNS in dns"
                :key="aDNS.id"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ aDNS.type }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ aDNS.value }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ aDNS.ip }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ aDNS.ttl }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(aDNS.dateStart) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(aDNS.dateEnd) }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="ml-2">{{ formatDateTime(aDNS.lastUpdate) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :span="24">
        <div class="d-flex justify-content-center">
          <span
            class="mt-2"
            style="font-weight: bolder; font-size: 0.9em"
          >NO HAY DATOS</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">

.table-dns {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    tbody {
      td {
        letter-spacing: -0.05em;

        font-weight: bolder;

        letter-spacing: -0.05em;
        font-size: 0.9em;

        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
