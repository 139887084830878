<template>
  <div class="mx-2 ">
    <el-image
      style="width: 300px; height: 300px;"
      :src="getUrl()"
      fit="cover"
    >
      <template
        v-slot:error
      >
        <div class="image-slot"
             style="width: 300px; height: 300px; background:#f9166f;"></div>
      </template>
    </el-image>
    <div v-if="name">
      <span class="text-truncate">{{ name }} <font-awesome-icon
        icon="trash"
        class="pointer"
        @click="deleteImage()"
      /></span>
    </div>
  </div>
</template>
<script setup>
import  {defineProps, defineEmits} from 'vue';
const emit = defineEmits(['deleteMedia'])
const props = defineProps({url:String,id:String,name:String})

   const getUrl = () => {
        if(!props.url) return '';
        const url = process.env.VUE_APP_BASE_URI+props.url;

        
        return url;
    }
    const deleteImage = ()=> {
        console.log("Emit 1er hijo")
        emit('deleteMedia');
    }

</script>
