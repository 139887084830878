<template>
  <div class="container m-0 p-0">
    <div class="d-flex flex-nowrap align-items-center">
      <svg
          id="undanews-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 595.3 841.9"
          style="enable-background: new 0 0 595.3 841.9"
          xml:space="preserve"
      >
        <path
            transform="matrix(1,0,0,1,-2,-0.2)"
            class="st0"
            d="M 265.6 725.7 c -27.1 0 -52.7 -5.5 -75.8 -16.2 c -23.3 -10.7 -45 -26.7 -64.3 -47.5 c -19.1 -20.5 -33.8 -44.1 -43.5 -69.6 c -9.7 -25.6 -14.5 -53.4 -14.5 -82.9 V 208.5 h 154.4 v 301.1 c 0.4 13.9 4.6 25.1 12.8 34 c 8.4 8.9 18.5 13.5 30.9 13.7 c 12.8 -0.5 23.1 -4.8 31.5 -13.9 c 8.4 -8.9 12.6 -20.1 12.8 -34 l 0 -210.7 l 154.4 -96.8 v 307.7 c 0 29.4 -4.8 57.3 -14.5 82.9 c -9.7 25.6 -24.2 49.1 -43.5 69.6 c -19.1 20.8 -40.8 36.7 -64.3 47.5 C 318.7 720.2 292.9 725.4 265.6 725.7 z M 84.3 226.7 v 282.8 c 0 27.2 4.4 52.7 13.2 76 c 8.8 23.3 22.1 44.5 39.7 63.5 c 17.9 19.2 37.8 33.8 59 43.6 c 21.2 9.8 44.5 14.6 69.3 14.6 c 25.2 0 48.7 -5 70.2 -14.6 c 21.4 -9.8 41.2 -24.4 58.8 -43.6 c 17.6 -18.9 30.9 -40.2 39.7 -63.5 c 8.8 -23.3 13.2 -48.8 13.2 -76 v -276.2 l -120.8 75.8 v 200.4 c -0.4 18.9 -6.5 34.5 -17.9 46.8 c -11.6 12.3 -26 18.7 -43.1 18.9 l -0.2 0 l -0.2 0 c -16.8 -0.5 -31.1 -6.8 -42.6 -18.9 c -11.3 -12.3 -17.4 -27.8 -17.9 -46.6 l 0 -0.2 V 226.7 H 84.3 z"
        />
      </svg>

      <h1 class="title mt-4">
        {{ title }}
      </h1>
    </div>
    <div
        v-if="show"
        class="row"
    >
      <div
          v-for="card in undanews"
          :key="card.id"
          class="col-12 col-lg-6 px-lg-0 px-xl-auto"
      >
        <card-undanews
            :title="card.title"
            :content="card.newsBody"
            :datetime="card.timestamp"
            :imagen="getUrlMedia(card.media)"
            :idundanews="card.id"
            :iriundanews="card['@id']"
            :confirm-reader="card.readConfirmation"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CardUndanews from './CardUndanews.vue';
import Undanews from "@/api/services/undanews.service.js";
import {ref, defineProps, onMounted} from "vue";


 defineProps({
  title: String,
  icon: String,
})
const undanews = ref([]);
const show = ref(false);

onMounted(()=>{
  filterUndanews();
})

const filterUndanews = async () => {
  const params = {
    itemsPerPage: 2,
    page: 1,


  }
  params['order[timestamp]'] = 'desc';
  params['newsStatus.name'] = 'Publicado';
  await Undanews.get(params).then(async resp => {

    undanews.value = resp.data['hydra:member'];

    for (const element of undanews.value) {
      element.media = await getImageUndanewsMediaObject(element.id);
    }
    show.value = true;
    //console.log("Undanew",this.undanews);
    /* this.undanews.forEach(async element => {

    });  */

  });
}
// eslint-disable-next-line no-unused-vars
const getImageUndanewsMediaObject = async(id) => {
  return await  Undanews.getMediaObject(id)

}
const getUrlMedia = (media) =>{

  if(media && media.data && media.data['hydra:member'] && media.data['hydra:member'].length>0)
  {
    return media.data['hydra:member'][0].contentUrl;
  }
  return '';
}


</script>
<style lang="scss" scoped>
@import "../../assets/sass/styles.scss";
#undanews-icon {
  fill: $bg-blue;
  width: 50px;
}

.title {
  color: $font-blue-color;
  letter-spacing: -0.05em;
  font-size: 2.99em;
  font-weight: bolder;
  line-height: 30px !important;
}
</style>
