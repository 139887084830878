<template>
    <svg
      id="id-asterisk"
      xmlns="http://www.w3.org/2000/svg"
      width="724"
      height="1024"
      viewBox="0 0 724 1024"
    >
      <path
        d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
      />
    </svg>
    <img
      id="id-asterisk-red"
      src="@/assets/images/aspa_roja_small_entrada.png"
      alt=""
    >
    <img
      id="id-asterisk-red1"
      src="@/assets/images/aspa_roja_small_entrada.png"
      alt=""
    >
   <svg
      xmlns="http://www.w3.org/2000/svg"
      id="id-asterisk-red"
      width="724"
      height="233"
      viewBox="0 0 724 1024"
    >
      <path
        d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
      />
    </svg>
    <img
      class="bgtishert"
      src="@/assets/images/titulo-benefit.png"
      alt=""
    >
    <div
      v-if="$store.state.benefit"
      class="body-new"
    >
      <div class="d-flex">
        <div>
          <el-image
            lazy
            style="max-width:30em; max-height:20em;"
            class="image-thumbaild"
            :src="baseUrl + ($store.state.benefit && $store.state.benefit.benefitMediaObjects && $store.state.benefit.benefitMediaObjects.length >0 ?  $store.state.benefit.benefitMediaObjects[0].contentUrl : null)"
            fit="cover"
          >
            <template v-slot:error>
              <div style="max-width:30em; max-height:20em;">
                <img
                    class="card-img-top"
                    src="@/assets/images/picture-loading-failed.png"
                    alt=""
                    srcset=""
                >
              </div>

            </template>
          </el-image>
        </div>
        <div class="ml-5 ">
          <h1 class="active-value font-weight-bold">
            {{ store.state.benefit.active ? 'Activo': 'No activo' }}
          </h1>
          <h4 class="time font-weight-bold mt-5">
            Del {{ formatDateTime(store.state.benefit.fromDate) }} Hasta {{ formatDateTime(store.state.benefit.toDate) }}
          </h4>
          <h1 class="title font-weight-bold mt-4">
            {{ store.state.benefit.title }}
          </h1>
          <h2 class="subtitle font-weight-bold">
            {{ store.state.benefit.contactName }} {{ store.state.benefit.contactPhone }}
          </h2>
          <h3 class="triplex ">
            XXX
          </h3>
        </div>
      </div>
      <div class="row mx-1 my-4">
        <div
          class=""
          v-html="formatDescription(store.state.benefit.description)"
        />
      </div>
    </div>
</template>
<script setup>
import moment from "moment";
import {useStore} from "vuex";



const baseUrl = process.env.VUE_APP_BASE_URI;
const store = useStore();
    const formatDateTime =  (value)=> {
      if (value) {
        return moment(value).format("DD/MM/YYYY");
      }
      return "";
    }

    const formatDescription = (value) => {
      if (value) {
        return value.replace(/\n/g, "<br />");
      }
      return "";
    }

</script>
<style lang="scss" scoped>
.triplex{
  color: #f9166f;
  letter-spacing:4px;
  font-size: 16px;
  font-weight:800;
}
.subtitle{
  color:#f7d20e;
}
.title{
  color: #f9166f;
}
.active-value{
  color: #f7d20e;
}
.time {
  font-size: 18px;
  color: black;
}

.title-new {
  color: #f9166f;
  font-weight: bold;
  font-size: 2.5em;
  word-break: normal;
  
}
.body-new {
  position: relative;
  top: 230px;
  left: 50px;
  right: 50px;
  height: auto;
  width: auto;
  max-width: 90%;
  .body-content {
    color: black;
    text-align: justify;
    text-justify: inter-word;
    word-break: normal;
  }
}
.title-red {
  position: absolute;
  top: 180px;
  left:190px;
  color: #f9166f;
  font-weight: 900;
  letter-spacing: 0.1%;
  font-size: 5.5em;
  z-index:9;
  word-break: normal;
  
}
#id-asterisk {
  position: absolute;
  top:-460px;
  left:20px;
  fill: #f7d20e;
  width: 4%;
}
#id-asterisk-red {
  position: absolute;
  top:10px;
  right:280px;
  width: 3%;
  
}
#id-asterisk-red1 {
  position: absolute;
  top:270px;
  right:80px;
  width:4%;

}
.bgtishert {
  position: absolute;
  top:95px;
  left:50px;
  max-width:40em;
  max-height:40em;
 /*  
  background: url("../../assets/images/camiseta.png");
  background-repeat: no-repeat;
  background-position-x: 0%;
  height: auto; */
 
}
.close-icon {
  /* height: 52px !important;
  width: 52px !important; */
  width: 30px !important;
  height:30px !important;
  position: absolute;
  right:20px;
  top:10px;

}
.el-dialog__close svg {
  width: 52px !important;
  height:52px !important;
  position: absolute;
  right:20px;
  top:10px;
}
</style>