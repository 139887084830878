import Api from '@/api/Api';
import errorHandler from '@/utils/plugins/ErrorHandler';

const RESOURCE_PRIORITIES = '/api/ticket_priorities';

export default {
    async getPriorities(params){
        return await Api()
        .get(RESOURCE_PRIORITIES,{params})
        .catch((err) => errorHandler(err, null));
    },
    async getPriority(id){
        return Api()
            .get(RESOURCE_PRIORITIES+'/'+id)
            .catch((err) => errorHandler(err, null));
    },
}


