<template>
  <presence-home :email="email" />
</template>
<script setup>
import PresenceHome from "./PresenceHome.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
const store = useStore();
const email = ref("");
const getUserLogged = computed(() => {
  return store.getters.getUserLogged;
})
onBeforeMount(()=>{
  const user = getUserLogged.value;
  console.log('email',user.email)
  email.value= user.email;
})
</script>