<template>
  <div>
    <div v-if="show">
      <button
        :disabled="isFirstPage"
        class="btn btn-rounded page"
        style="margin-right: 0.2rem"
        @click="changePage(1)"
      >
        <font-awesome-icon icon="angle-double-left" />
      </button>
      <button
        :disabled="isFirstPage"
        class="btn btn-rounded page"
        style="margin-right: 0.2rem"
        @click="changePage(page - 1)"
      >
        <font-awesome-icon icon="angle-left" />
      </button>
      <button
        v-for="page in filteredPages"
        :key="page.id"
        class="btn btn-rounded page"
        style="margin-right: 0.2rem; margin-left: 0.2rem"
        :class="{ 'btn-secondary': isSelected(page) }"
        :disabled="isDisabled(page)"
        @click="changePage(page.id)"
      >
        {{ page.content }}
      </button>
      <button
        :disabled="isLastPage"
        class="btn btn-rounded page"
        style="margin-left: 0.2rem; margin-right: 0.2rem"
        @click="changePage(page + 1)"
      >
        <font-awesome-icon icon="angle-right" />
      </button>
      <button
        :disabled="isLastPage"
        class="btn btn-rounded page"
        style="margin-left: 0.2rem; margin-right: 0.2rem"
        @click="changePage(maxPages)"
      >
        <font-awesome-icon icon="angle-double-right" />
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SharedPagination',
    props: {
      page: Number,
      totalResults: Number,
      pageSize: Number,
      limit: {type: Number, default: 5},
      showAlways: {type: Boolean, default: false}
    },
    computed: {
      pages(){
        if(this.totalResults && this.pageSize){
          var pages = []
          for(var i = 1; i <= Math.ceil(this.totalResults / this.pageSize); i++){
              pages.push({ id: i, content: i })
          }
          return pages
        }
        return null
      },
      filteredPages(){
        if(this.pages){
          if(this.maxPages > this.limit){
            if(this.limit + this.page > this.maxPages){
              return this.pages.slice(this.maxPages - this.limit - 1, this.maxPages)
            } else {
              if(this.page == 1){
                let result = this.pages.slice(this.page - 1, this.limit + this.page - 1)
                result.push({ id: this.limit + this.page, content: '...' })
                return result
              } else {
                let result = this.pages.slice(this.page - 2, this.limit + this.page - 1)
                result.push({ id: this.limit + this.page, content: '...' })
                return result
              }
            }
          }
          return this.pages
        }
        return null
      },
      maxPages(){
        if(this.totalResults && this.pageSize){
          return Math.ceil(this.totalResults / this.pageSize)
        }
        return null
      },
      show(){
        if(this.page || this.showAlways){
          return true
        } else {
          return false
        }
      },
      isFirstPage(){
        if(this.page == 1){
          return true
        } else {
          return false
        }
      },
      isLastPage(){
        if(this.page == this.maxPages){
          return true
        } else {
          return false
        }
      }
    },
    mounted() {

    },
    methods: {
      changePage(page) {
        if(page != this.page){
          this.$emit('change', page)
        }
      },
      isSelected(page){
        if(this.page == page.id){
          return true
        } else {
          return false
        }
      },
      isDisabled(page){
        if(page.content === '...'){
          return true
        } else {
          return false
        }
      }
    },

  }
</script>

<style lang="scss" scoped>
.page {
  background-color: $light-grey-bg;
  color: $primary-color;
}
</style>
