<template>
  <el-dialog
      v-model="dialogVisible"
      title="Tips"
      width="30%"
  >
    <span>This is a message</span>
  </el-dialog>
  <!-- <modal
    name="popupSingModal"
    :adaptive="true"
    :width="600"
    :height="500"
    styles="border-radius:10px;
         overflow-y:auto;
         backgroud-color:yeelow;  
         "
  >
    <div class="d-flex backgroud" />
    <div class="button-goup mr-5 px-4">
      <button
        class="btn btn-block btn_red mr-4"
        style="height:40px"

        type="button"
        @click="hide()"
      >
        Cancelar
      </button>
      <button
        class="btn btn_blue "
        style="height:40px"
        type="button"
        @click="Acept()"
      >
        Aceptar
      </button>
    </div>
  </modal> -->
</template>
<script>
export default {
  name: "MyPopupSing",
  props: { isShow: Boolean },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    isShow(nval) {
      if (nval) {
        this.show();
      } else this.hide();
    },
  },
  methods: {
    show() {
      this.$modal.show("popupSingModal");
    },
    hide() {
      this.$modal.hide("popupSingModal");
      
    },
    Acept(){
      this.$emit("accept");
      this.hide();
    }
  },
}
</script>
<style lang="scss" scoped>
.backgroud{
    background: url("../../assets/images/popup-sing.png");
    background-position-x: -120px;
    background-position-y: -70px;
    width: 100%;
    height:100%;
    margin: 0;
    
 
}
.button-goup{
  
  width: 100%;
  position: absolute;
  bottom:4%;
  left: 0%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
</style>