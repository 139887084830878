<template>
  <div class="form-item">
    <div class="row">
      <div
        v-if="label"
        :class="class_div_col_label"
        class="title"
      >
        <label
          v-if="label"
          :class="colorLabel"
          :for="param"
        >{{ label }}</label>
      </div>
      <div
        :class="class_div_col_input"
        class="class-input"
      >
        <!--      <div class="class-input" :class="{'col-md-8': label, 'col-md-12': !label}">-->
        <input
          :id="id ? id : param"
          :ref="param"
          :type="type"
          :name="param"
          :value="value"
          :readonly="readonly"
          :class="[colorInput, validate && errors.has(param) ? 'is-invalid' : '', errorValidation ? 'error-validation' : '']"
          :data-vv-as="label ? label : ''"
          :autocomplete="'off'"
          :placeholder="placeholder ? placeholder : ''"
          :disabled="disabled"
          @input="updateSelf($event.target.value)"
          @focus="focus"
          @blur="blur"
        >
      </div>

      <font-awesome-icon
        v-if="icon"
        class="icon"
        :icon="icon"
      />
      <div
        v-if="param && validate && errors.has(param)"
        class="animated fadeIn invalid-feedback"
      >
        <font-awesome-icon icon="info-circle" />
        {{ errors.first(param) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharedInput',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    callUpdateAtInit: {type: Boolean, default: true},
    callUpdateAtWatch: {type: Boolean, default: false},
    value: null,
    type: {type: String, default: 'text'},
    placeholder: String,
    param: {type: String, default: 'input'},
    id: {type: String},
    label: String,
    rules: {type: Object, default: null},
    validate: Boolean,
    autofill: {type: Boolean, default: true},
    icon: String,
    readonly: Boolean,
    colorLabel: {
      type: String,
      default: 'blue-grey-bg'
      // default: 'shared-input-label-black'
    },
    colorInput: {
      type: String,
      // default: 'blue-grey-bg'
      default: 'black'
    },
    disabled: {type: Boolean, default: false},
    class_div_col_label: {
      type: String,
      default: 'col-md-4'
    },
    class_div_col_input: {
      type: String,
      default: 'col-md-8'
    },
    errorValidation: {type: Boolean, default: false}
  },
  watch: {
    value(value) {
      if (this.callUpdateAtWatch)
        this.updateSelf(value)
    }
  },
  mounted() {
    if (this.callUpdateAtInit)
      this.updateSelf(this.value);
  },
  methods: {
    updateSelf(value) {
      if (this.type === 'number') {
        value = parseInt(value);
      }
      this.$emit('change', value);
      setTimeout(() => {
       // this.$validator.validate();
      }, 500)
    },
    focus() {
      this.$emit('focus')
    },
    blur() {
      this.$emit('blur')
    },
    change() {
      this.$emit('change')
    }
  },
}
</script>

<style lang="scss" scoped>

.form-item {
  input {
    border: solid 1px black;
    border-radius: 20px;
    margin-left: 0px;
    padding-left: 15px;

  }

  input[disabled] {
    opacity: 0.6;
    pointer-events: none;

  }

  .class-input {
    .shared-input {
      color: black;
      /*width: 225px;*/
      background: #c9cfd6;

    }
  }

  .title {
    display: inline-flex;
    align-self: center;
    text-align: left;

    .shared-input-color {
      color: black;
      float: left;
      margin: 0;
    }

    .shared-input-report {
      color: black;
      float: right;
      font-size: 21px;
    }

    .shared-input-black {
      color: black;
      float: right;
      font-size: 14px;
    }
  }

  .error-validation {
    border: 2px solid #F56C6C;
  }
}
</style>
