<template>
  <div>
    <el-row>
      <el-col :span="20">
        <h2>Empleados de vacaciones en los próximos 7 días</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="holidays.holidaysOn7Days"
            border
        >
          <el-table-column>
            <template #default="scope">
              <el-avatar :src="photo(scope.row)">
                {{ employeeInitials(scope.row) }}
              </el-avatar>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="Id" prop="id"></el-table-column>-->
          <el-table-column
              label="Nombre"
              prop="name"
          />
          <el-table-column
              label="Apellidos"
              prop="lastname"
          />
          <el-table-column
              label="Desde"
              prop="fromDate"
          />
          <el-table-column
              label="Hasta"
              prop="toDate"
          />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <h2>Empleados de vacaciones</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="holidays.holidaysToday"
            border
        >
          <el-table-column>
            <template #default="scope">
              <el-avatar :src="photo(scope.row)">
                {{ employeeInitials(scope.row) }}
              </el-avatar>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="Id" prop="id"></el-table-column>-->
          <el-table-column
              label="Nombre"
              prop="name"
          />
          <el-table-column
              label="Apellidos"
              prop="lastname"
          />
          <el-table-column
              label="Desde"
              prop="fromDate"
          />
          <el-table-column
              label="Hasta"
              prop="toDate"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import {computed, defineProps, onMounted} from 'vue';
import _ from 'lodash';
import {employeeInitials, photo} from "@/views/production/mixin/common";

const props = defineProps({
  holidays: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  fillData();
});

// eslint-disable-next-line no-unused-vars
const computedHolidays = computed(() => {
  return _.orderBy(_.toArray(props.holidays), ['lastname']);
});



const  fillData = () => {
  return _.orderBy(_.toArray(props.holidays), ['lastname']);
}
</script>

<style scoped lang="scss">

</style>