<template>
  <div>
    <!--        <h1>Puestos de Trabajo</h1>-->
    <HeaderTitle title="AJUSTES" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Puestos de Trabajo
      </div>
    </div>
    <el-card
        class="card-search w-100 wback"
        body-style="padding:0"
    >
      <div class="row mx-5">
        <div class="container-jobs">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class=" form-item  date-picker  label-common">
                    <label class=" w-100 mb-2">Nombre</label>
                    <el-input
                        v-model="searchFilterJobName"
                        placeholder="Nombre del Puesto de trabajo"
                        prefix-icon="el-icon-search"
                        clearable />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex flex-column justify-content-end align-items-end pull-right">
            <button
                style="width: 280px;height: 40px;"
                class="btn btn_yellow btn-search"
                @click="search()"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="jobs && jobs.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Productivo</th>
              <th>Departamento</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="job in jobs" :key="job.id">
              <td>
                {{ job.name }}
              </td>
              <td> {{ isProductive(job.productive) }}</td>
              <td v-if="job.department && job.department.name">
                {{ job.department.name }}
              </td>
              <td v-else />
              <td>
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editJob(job)"
                    />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedJob = job;"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado tipos de puestos de trabajo</h5>
      </div>
      <SharedPagination
        v-if="jobs && jobs.length > 4"
        class="pagination"
        :page="page"
        :total-results="totalResults"
        :page-size="itemsPerPage"
        style="padding: 0px 0px 20px 0px;"
        @change="page = $event; getJobs()"
      />

    </div>
    <div class="row text-right">
      <div class="col-md-4 offset-md-8">
        <button @click="createOrUpdateJobDialog = true" class="btn btn_darkpink">NUEVO PUESTO DE TRABAJO</button>
      </div>

    </div>
    
    <el-dialog 
        class="dense-dialog"
        v-model="createOrUpdateJobDialog"
        @closed="closeCreateOrUpdateDialog()">
      <div>
        <form @submit.prevent="">
          <div class="row">
            <div class="col-md-4">
              <div class="form-item">
                <label class="w-100">Puesto de trabajo</label>
                <el-form-item prop="username" :error="$v.jobName.$errors[0]?.$message">
                <el-input
                    v-model="$v.jobName.$model"
                    placeholder="Nombre del Puesto de trabajo"
                    prefix-icon="el-icon-search"
                    clearable />
                </el-form-item>
                
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-item">
                <label class="w-100">Productividad</label>
                <el-select
                    v-model="productive"
                    placeholder="Productividad"
                    filterable
                    clearable
                    value-key="name"
                >
                  <el-option
                      v-for="item in productiveArray"
                      :key="item.name"
                      :label="item.name"
                      :value="item"
                  />
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-item">
                <label class="w-100">Departamento</label>
                <el-select
                    v-model="department"
                    placeholder="Departamento"
                    filterable
                    clearable
                    value-key="@id"
                >
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-8 d-flex flex-column justify-content-end align-items-end">
              <button
                  class="btn btn-primary"
                  :disabled="$v.$invalid"
                  @click="isUpdateJob ? updateJob(job) : createJob(job)"
              >
                {{ isUpdateJob ? 'Actualizar' : 'Nuevo'}}
              </button>
            </div>
          </div>


          <!--                    <div class="col-md-3 text-right"  v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol) && title.length > 0">-->
        </form>
      </div>
    </el-dialog>

    <el-dialog
      v-model="deleteDialogShown"
      :title="'¿Desea eliminar permanentemente este tipo de trabajo?'"
      class="dense-dialog"
      @closed="selectedJob = null;deleteDialogShown = false"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedJob = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteJob(selectedJob)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
/* eslint-disable */
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useVuelidate} from "@vuelidate/core";
import { helpers, minLength, required,} from '@vuelidate/validators'
import message from "@const/message";

const searchFilterJobName = ref('')
const createOrUpdateJobDialog = ref(false)
const isUpdateJob = ref(false)
const jobs = ref([]);
const job = ref({});
const department = ref({});
const productiveArray = ref([
  {'name': 'Productivo', 'productive': true},
  {'name': 'No productivo', 'productive': false}
    ]);
const productive = ref({});
const departments = ref([]);
const deleteDialogShown = ref(false);
const selectedJob = ref([]);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(0);
const jobName = ref('');

const rules = computed(()=>{
  return  {
    jobName:{
      required:helpers.withMessage(message.required,required),
      minLength:helpers.withMessage("La longitud mínima del campo es de 3", minLength(3))
    }
  }
})
const $v = useVuelidate(rules, {jobName});

const store = useStore();
  onMounted(()=> {
    getJobs();
    getDepartments();
  })

   const search = () => {
      getJobs(searchFilterJobName.value);
   }
    const notEmptyObject = (someObject) => {
      return Object.keys(someObject).length;
    }
    const editJob = (job) => {
      selectedJob.value = job;
      jobName.value = job.name;
      productive.value = {
        productive : job.productive,
        name : job.productive ? 'Productivo' : 'No productivo'
      };
      department.value = (job.department && notEmptyObject(job.department)) ? job.department : {};
      isUpdateJob.value = true
      createOrUpdateJobDialog.value = true
    }
    const getJobs = (searchQuery = null) => {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getJobs(params)
          .then((res) => {
            jobs.value = res.data['hydra:member'];
          })
    }
    const getDepartments = (searchQuery = null) => {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getDepartments(params)
          .then((res) => {
            departments.value = res.data['hydra:member'];
          })
    }
    const deleteJob = (job) => {
      ResourceService.deleteJob(job.id)
          .then(() => {
            Notification.addNotification('Tipo de trabajo eliminado correctamente.', 'success', 6000);
            deleteDialogShown.value = false;
            cancel();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el tipo de trabajo seleccionado', 'error', 6000);
        store.state.loading = false;
      })
    }
    const createJob = (job) => {
      job.name = jobName.value;
      job.productive = productive.value.productive;
      if (notEmptyObject(department.value)) {
        job.department = department.value['@id'];
      }
      store.state.loading = true;
      ResourceService.createJob(job)
          .then(() => {
            Notification.addNotification('Tipo de trabajo creado correctamente', 'success', 6000);
            store.state.loading = false;
            getJobs();
          })
          .catch(() => {
            Notification.addNotification('Error al crear el tipo de trabajo', 'error', 6000);
            store.state.loading = false;
          })
          .finally(()=>{
            closeCreateOrUpdateDialog()
          })

    }
// eslint-disable-next-line no-unused-vars
    const updateJob = async(job) => {
      if (jobName.value) {
        job.name = jobName;
      }

      job.productive = productive.value.productive;

      if (notEmptyObject(department.value)) {
        job.department = department.value['@id'];
      }

      store.state.loading = true;
      await ResourceService.updateJob(job, selectedJob.value.id)
          .then(() => {
            Notification.addNotification('Tipo de trabajo actualizado correctamente', 'success', 6000);
            store.state.loading = false;
            getJobs();
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el tipo de trabajo', 'error', 6000);
            store.state.loading = false;
          })
          .finally(()=>{
            closeCreateOrUpdateDialog()
          })
    }
    
    const reset = () =>{
      job.value = {};
      jobName.value = '';
      selectedJob.value = null;
      department.value = {};
      productive.value = {};
      isUpdateJob.value = false
    }
    const cancel = () => {
      reset()
      getJobs();
    }
    
    const closeCreateOrUpdateDialog = ()=>{
      createOrUpdateJobDialog.value = false
      reset()
    }



// eslint-disable-next-line no-unused-vars
   const isProductive = (isProductive) => {
      return isProductive ? 'Si' : 'No';
    }


</script>

<style lang="scss" scoped>
.card-search {
  height: 200px;
}
.job-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-jobs {
  padding: 40px;

  table {
    border-collapse: separate;
    border-spacing: 0 25px;
    color: #858796;
    text-align-all: left;
    width: 100%;

    th {
      //border: 1px solid #e3e6f0;
      color: $font-blue-color;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: center;
    }

    tbody {
      //tr:nth-child(odd) {
      //  td {
      //    background-color: rgba(0, 0, 0, .05);
      //  }
      //}

      td {

        background-color: white;
        border: 1px solid #e3e6f0;
        padding: 12px 12px 12px 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }

      tr:nth-child(n) {
        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          -moz-border-radius-topleft: 10px;
          -moz-border-radius-bottomleft: 10px;
          -webkit-border-top-left-radius: 10px;
          -webkit-border-bottom-left-radius: 10px;

          -webkit-box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
        }
        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          -moz-border-radius-topright: 10px;
          -moz-border-radius-bottomright: 10px;
          -webkit-border-top-right-radius: 10px;
          -webkit-border-bottom-right-radius: 10px;

          -webkit-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
        }
        td:nth-child(n) {
          -webkit-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
        }
      }
    }
  }
}
</style>
