<script setup>

import TrackerTotalTime from "@/components/shared/TrackerTotalTime.vue";
import TrackerEntry from "@/components/shared/TrackerEntry.vue";
import {computed, defineProps, defineEmits} from "vue";
import moment from "moment/moment";
import 'moment/locale/es';

const emits = defineEmits(['reload entries'])
const props = defineProps({
  day: Object,

})

const groupedEntries = computed(() => {
  const groupedEntries = {};

  props.day.entries.forEach((entry) => {
    // Assuming tags is an array and needs to be converted to a string
    const tagsString = entry.tags.sort().join('|');
    const key = `${entry.project}-${entry.description}-${tagsString}`;

    if (!groupedEntries[key]) {
      groupedEntries[key] = [];
    }

    groupedEntries[key].push(entry);
  });
  return groupedEntries;
})



const totalTime = computed(() => {
  let total = 0
  if(props.day.entries.length === 0) return 0
  props.day.entries.forEach((entry) => {
    if(entry.duration){
      total += entry.duration
    }else{
      total += moment().diff(entry.timeStart, 'seconds')
    }
  })
  return total;
});
const reloadEntries = () => {
  emits('reload entries')
}

// eslint-disable-next-line no-unused-vars
const currentWeek = computed(() => {
  return moment().week();
})
// eslint-disable-next-line no-unused-vars
const currentDay = computed(() => {
  return moment().day();
})
const dayLabel = computed(() => {
  moment.locale('es');
  const formattedDate = moment(props.day.entries[0]?.timeStart).format('dddd D [de] MMMM');
  return capitalizeFirstLetterExceptPrepositions(formattedDate);
})
const capitalizeFirstLetterExceptPrepositions = (string) => {
  return string
      .toLowerCase()
      .replace(/\b\w/g, (char, index, original) => {
        // Capitaliza solo si no es una preposición o es el inicio de la cadena
        if (original[index - 1] === " " && char === 'd' && original.substring(index, index + 3) === 'de') {
          return char;
        }
        return char.toUpperCase();
      });
};
</script>

<template>
  <el-card class="card-tracker w-100 mt-4">
    <template #header>
      <div class="card-tracker-header"><span>{{dayLabel}}</span> <tracker-total-time :totalTime="totalTime" /></div>
    </template>
    <template #default>
      <tracker-entry
          v-for="entry in groupedEntries"
          :entry="entry"
          :key="entry[0].id"
          @reload-entries="reloadEntries"
      />
    </template>
  </el-card>
</template>

<style scoped lang="scss">

</style>